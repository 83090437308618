import React from "react";
import { Icon, Label, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatToBritishFormat } from "../utils";

function RecentProjects({ projects }) {
  if (!projects || projects.length === 0) {
    return <div>No projects available</div>;
  }

  return (
      <>
        <Table basic="very" size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Project</Table.HeaderCell>
              <Table.HeaderCell>Client</Table.HeaderCell>
              <Table.HeaderCell>Project Date</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projects.map((project, index) => (
                <Table.Row key={index}>
                  <Table.Cell><Link to={'/dashboard/projects/view/' + project.id}>{project.projectName}</Link></Table.Cell>
                  <Table.Cell>{project.clientName}</Table.Cell>
                  <Table.Cell>
                    {formatToBritishFormat(project.projectStartDate || project.projectMilestones[0].startDate)}
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                        as="a"
                        color={getStatusColor(project.projectStatus)}
                        style={{ borderRadius: "99px" }}
                    >
                      {getStatusLabel(project.projectStatus)}
                    </Label>
                  </Table.Cell>
                </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="4">
                <Link to={"/dashboard/projects"}>
                  <Button icon color="primary" labelPosition="right">
                    View All Projects
                    <Icon name="right arrow" />
                  </Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
  );
}

export default RecentProjects;

function getStatusColor(status) {
  switch (status) {
    case "draft":
      return "orange";
    case "completed":
      return "green";
    case "cancelled":
      return "grey";
    default:
      return "blue";
  }
}

function getStatusLabel(status) {
  switch (status) {
    case "draft":
      return "Draft";
    case "on-going":
      return "On-going";
    case "completed":
      return "Completed";
    case "cancelled":
      return "Cancelled";
    default:
      return "Unknown";
  }
}
