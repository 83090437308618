import React, { useState } from "react";
import { Card, Grid, Button, List, Modal, Form } from "semantic-ui-react";
import { updateSchematics, uploadFiles } from "../apiCalls";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { extractFileNameAndExtension } from "../utils";
import { toast } from "react-toastify";
import { Document, Page } from "react-pdf";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSwipeable } from "react-swipeable";
import AuthService from '../auth'

const ProjectSchematics = ({ id, projectSchematics, onUploadComplete }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [AddmodalIsOpen, setaddModalIsOpen] = useState(false);

  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const user = AuthService.getCurrentUser();

  const user_role = user.role;

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentFileIndex(currentFileIndex + 1),
    onSwipedRight: () => setCurrentFileIndex(currentFileIndex - 1),
  });

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const openAddModal = () => setaddModalIsOpen(true);
  const closeAddModal = () => setaddModalIsOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const openDeleteModal = () => setDeleteModalOpen(true);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const isPdf = (file) => {
    if (file && typeof file === "string") {
      const fileName = file.split("/").pop();
      return fileName.endsWith(".pdf");
    }
    return false;
  };

  const updateFiles = (incomingFiles) => {
    // Remove existing files with the same name and size from incomingFiles to avoid duplicates
    const filteredFiles = incomingFiles.filter((incomingFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === incomingFile.name &&
          existingFile.size === incomingFile.size
      );
    });

    // Set the new files (including the filtered incoming files)
    setFiles([...files, ...filteredFiles]);
  };

  const removeFile = (fileId) => {
    // Remove a file from the state
    const updatedFiles = files.filter((file) => file.id !== fileId);
    setFiles(updatedFiles);
  };

  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];

  const isImage = (file) => {
    if (file && typeof file === "string") {
      const extension = file.split(".").pop();
      return imageExtensions.includes(`.${extension}`);
    }
    return false;
  };

  const handleDelete = async () => {
    // Check if deleteIndex is valid
    if (
      deleteIndex !== null &&
      deleteIndex >= 0 &&
      deleteIndex < projectSchematics.length
    ) {
      const updatedSchematics = [...projectSchematics];
      updatedSchematics.splice(deleteIndex, 1); // Remove the schematic at deleteIndex
      // Update the state or call a function to update the parent component's state
      // For example: updateSchematics(id, updatedSchematics);

      await updateSchematics(id, updatedSchematics);

      toast("Schematic Deleted");
    }
    closeDeleteModal();
  };

  const handleUpload = async () => {
    try {
      if (files.length > 0) {
        setLoading(true);
        // Upload files and set the photoUri
        const uploadData = await uploadFiles(files);

        const schematicUris = [];

        for (const item of uploadData.data) {
          schematicUris.push(item.urls.path);
        }

        const updatedSchematics = projectSchematics
          ? [...projectSchematics, ...schematicUris]
          : schematicUris;

        const upS = await updateSchematics(id, updatedSchematics);

        toast("Upload completed");

        // Clear the uploaded files
        setFiles([]);

        if (upS) {
          // Close the modal
          closeModal();
          setLoading(false);
        }
      } else {
        toast("Kindly choose a file");
      }
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Error uploading files:", error);
    }
  };

  return (
    <Card.Content>
      <Grid>
        <Grid.Column floated="left" width={9}>
          <Card.Header>
            <h4>Project Schematics</h4>
          </Card.Header>
        </Grid.Column>
      </Grid>
      <Card.Description>
        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
          <List>
            {projectSchematics == null && (
              <List.Item>
                <List.Content>
                  <span class={"primary-color"}>No schematics yet</span>
                </List.Content>
              </List.Item>
            )}
            {projectSchematics &&
              projectSchematics.map((schematic, index) => (
                <List.Item key={index} {...handlers}>
                  <List.Content>
                    <a
                      style={{
                        color: "#044f66",
                        fontSize: 15,
                        wordWrap: "break-word",
                      }}
                      target="_blank"
                      href={schematic}
                    >
                      {extractFileNameAndExtension(schematic)}
                    </a>
                    &nbsp;
                    <a href={schematic} download>
                      <List.Icon style={{ color: "#044f66" }} name="download" />
                    </a>
                    <a
                      href="#"
                      onClick={() => {
                        setDeleteIndex(index);
                        openDeleteModal();
                      }}
                    >
                      <List.Icon style={{ color: "red" }} name="trash" />
                    </a>{" "}
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </div>
      </Card.Description>
      <Modal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        size="mini"
        dimmer={"blurring"}
        basic={true}
      >
        <Modal.Header>Delete Schematic</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this schematic?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteModalOpen(false)} color="black">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="red">
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal open={modalIsOpen} onClose={closeModal}>
        <Modal.Header>File Preview</Modal.Header>
        <Modal.Content>
          {projectSchematics &&
            projectSchematics[currentFileIndex] &&
            isPdf(projectSchematics[currentFileIndex]) ? (
            <iframe
              src={projectSchematics[currentFileIndex]}
              width="100%"
              height="500px"
            ></iframe>
          ) : (
            <ImageGallery
              items={
                projectSchematics
                  ? projectSchematics.filter(isImage).map((schematic) => ({
                    original: schematic,
                    thumbnail: schematic,
                  }))
                  : []
              }
              startIndex={currentFileIndex}
              showPlayButton={false}
            />
          )}
        </Modal.Content>
      </Modal>
      {(user_role == 1 || user_role == 2) && (
        <>
          <Button
            style={{ marginTop: 20 }}
            color="primary"
            content="Add A New Schematic"
            icon="plus"
            labelPosition="right"
            size="mini"
            onClick={openAddModal}
          />
        </>
      )}

      <Modal open={AddmodalIsOpen} onClose={closeAddModal} size="small">
        <Modal.Header>Upload Schematic</Modal.Header>
        <Form loading={loading}>
          <Modal.Content>
            <Dropzone
              onChange={updateFiles}
              value={files}
              maxFileSize={8 * 1024 * 1024}
            >
              {files.map((file) => (
                <FileMosaic
                  key={file.id}
                  {...file}
                  onDelete={removeFile}
                  info
                  preview={true}
                  progress={true}
                />
              ))}
            </Dropzone>
          </Modal.Content>
        </Form>

        <Modal.Actions>
          <Button onClick={closeAddModal} color="black">
            Close
          </Button>

          {loading === false && (
            <>
              <Button onClick={handleUpload} color="primary">
                Upload
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
    </Card.Content>
  );
};

export default ProjectSchematics;
