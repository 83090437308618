import React, { useCallback, useEffect, useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Input, List } from "semantic-ui-react";
import "../chat.css";
import Avatar from "react-avatar";
import { FaCircle } from "react-icons/fa";
import ChatWidgetMessenger from "../components/ChatWidgetMessenger";
import { messages as initialMessages, formatRelativeTime, extractFileNameAndExtension } from "../utils";
import sendApiRequest from "../api";
import { UserAvatar } from "../components/UserAvatar";

function Messenger() {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedContactIndex, setSelectedContactIndex] = useState(0); // Initialize with the first contact

    const [messages, setMessages] = useState(initialMessages);

    // Rest of your code

    const handleNewMessage = (newMessage) => {
        //alert(selectedContact.id)

        if (selectedContact) {
            // Update only the messages for the selected contact
            setSelectedContact({
                ...selectedContact,
                messages: [...selectedContact.messages, newMessage],
            });
        }
    };

    const fetchAndUpdateMessages = useCallback(() => {
      sendApiRequest("get", "/messenger").then((r) => {
        const cd = { contacts: r.data };
        //console.log(fcd);
          setData(cd);
        // setMessages(cd.messages)
        // setSelectedContact(data.contacts[0])
      });
    }, [1]);

    useEffect(() => {
      // Fetch and update messages initially
      fetchAndUpdateMessages();

      // Set up an interval to fetch and update messages every 5 seconds
      const intervalId = setInterval(fetchAndUpdateMessages, 1000);

      // Clean up the interval when the component unmounts
      return () => {
          clearInterval(intervalId);
      };
    }, [fetchAndUpdateMessages]);

    const [data, setData] = useState({});

    useEffect(() => {
        sendApiRequest("get", "/messenger").then((r) => {
            const cd = { contacts: r.data };
            console.log(cd);
            setData(cd);
            // setMessages(cd.messages)
            // setSelectedContact(data.contacts[0])
        });
    }, [1]);

    // JSON data

    const filteredContacts =
        data.contacts &&
        data.contacts.filter((contact) =>
            contact.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSelectContact = (id) => {
        setSelectedContact(data.contacts.find((r) => r.id === id));
        setSelectedContactIndex(id);
    };

    // Load group chat messages when the component mounts
    useEffect(() => {
        if (
            filteredContacts &&
            filteredContacts.length > 0 &&
            selectedContact === null
        ) {
            handleSelectContact(data.contacts[0].id); // Select the first contact (Group Chat) by default
        }
    }, [filteredContacts, selectedContact]);

    return (
        <AdminDashLayout>
            <div className="messenger-container">
                <div>
                    <div fluid className="messenger-search-card">
                        <div className="messenger-search-input" style={{ paddingLeft: 20 }}>
                            <Input
                                icon="search"
                                iconPosition="left"
                                placeholder="Search messages"
                                className=""
                                size="mini"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="messenger-contact-card-container">
                            <ul className="messenger-contact-card-list">
                                {filteredContacts &&
                                    filteredContacts.map((contact, index) => (
                                        <li
                                            key={index}
                                            className={`messenger-contact-card-list-item ${
                                                contact.id === selectedContactIndex
                                                    ? "messenger-contact-card-list-item-active"
                                                    : ""
                                            }`}
                                            onClick={() => handleSelectContact(contact.id)}
                                        >
                                            {/* TODO: Add a check to see whether this is a Group or User */}
                                            {contact.is_group == 0 && (
                                                <div className="messenger-contact-card-list-item-avatar">
                                                    <Avatar name={contact.name} size="40" round={true} />
                                                </div>
                                            )}

                                            {contact.is_group == 1 && (
                                                <div className="messenger-contact-card-list-item-avatar">
                                                    <UserAvatar
                                                        worker={contact.participantsData.find((participant) => participant.firstname === contact.name)}
                                                    />
                                                </div>
                                            )}

                                            <div className="messenger-contact-card-list-item-name-flex">
                                                <div className="messenger-contact-card-list-item-username">
                                                    <b>{contact.name}</b>
                                                    <br />
                                                    <small className="messenger-contact-card-list-item-summary-text">
                                                        {contact.summary}
                                                    </small>
                                                </div>
                                                {contact.is_group == 1 && (
                                                    <div className="messenger-contact-card-list-item-time">
                                                        <small className="">
                                                            {formatRelativeTime(contact.time)}
                                                        </small>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="messenger-chats-card">
                        <div className="messenger-chats-name">
              <span>
                {selectedContact ? selectedContact.name : "Group Chat"}{" "}
                  <FaCircle
                      style={{
                          color: "green",
                          fontSize: "10px",
                          verticalAlign: "middle",
                      }}
                  />
              </span>
                        </div>
                        <hr className={"messenger-chats-divider"} />
                        <div>
                            <ChatWidgetMessenger
                                id={selectedContact && selectedContact.id}
                                messages={selectedContact ? selectedContact.messages : []}
                                onNewMessage={handleNewMessage}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="messenger-chat-info">
                        <div style={{ textAlign: "center", paddingTop: 60 }}>
                            {selectedContact && selectedContact.is_group == 0 && (
                                <Avatar
                                    name={selectedContact ? selectedContact.name : "Group"}
                                    size="70"
                                    round={true}
                                />
                            )}

                            {selectedContact && selectedContact.is_group == 1 && (
                                <UserAvatar
                                    worker={selectedContact.participantsData.find((participant) => participant.firstname === selectedContact.name)}
                                    size="70"
                                    round={true}
                                />
                            )}
                        </div>
                        <div style={{ textAlign: "center", paddingTop: 15 }}>
              <span>
                <big>
                  {selectedContact ? selectedContact.name : "Group Chat"}
                </big>
              </span>
                            <br />
                            <span>
                <small style={{ fontSize: 13 }}>
                  {selectedContact && selectedContact.is_group == 1
                      ? selectedContact.phone_number
                      : null}
                </small>
              </span>
                            <br />
                            {selectedContact && selectedContact.is_group == 0 && (
                                <span>
                  <small>
                    {selectedContact.participants.length} participants
                  </small>
                </span>
                            )}
                        </div>
                        <div style={{ textAlign: "center", paddingTop: 20 }}>
                            <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                                <big>Attachments</big>
                                <List>
                                    {selectedContact
                                        ? selectedContact.attachments &&
                                        selectedContact.attachments.map((attachment, index) => (
                                            <List.Item key={index}>
                                                <List.Content>
                                                    <a
                                                        style={{ color: "#044f66", fontSize: 15 }}
                                                        href={attachment}
                                                        target="_blank"
                                                    >
                                                        {extractFileNameAndExtension(attachment)}
                                                    </a>
                                                    &nbsp;{" "}
                                                    <List.Icon
                                                        style={{ color: "#044f66" }}
                                                        name="download"
                                                    />
                                                </List.Content>
                                            </List.Item>
                                        ))
                                        : null}
                                </List>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
            </div>
        </AdminDashLayout>
    );
}

export default Messenger;