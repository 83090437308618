import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  Modal,
  Header,
  Icon,
  List,
  Form,
} from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { FaCheckCircle, FaMapMarker } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import Lightbox from "../components/Lightbox";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../auth";
import sendApiRequest from "../api";
import { formatToBritishFormat } from "../utils";
import { toast } from "react-toastify";
import { extractFileNameAndExtension } from "../utils";
import { updateGrpImages } from "../apiCalls";
import LightboxInner from "../components/LightboxInner";

function flattenArray(dataArray) {
  if (Array.isArray(dataArray)) {
    return dataArray.flatMap((r) => {
      if (Array.isArray(r)) {
        return r.map((e) => e.link);
      } else {
        return [r.link];
      }
    });
  }
  return [];
}

function ViewGrp() {
  const user = AuthService.getCurrentUser();
  const user_role = user.role;
  const { id } = useParams();
  const [data, setData] = useState({});
  const [meta, setMeta] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [serialNoType, setSerialNoType] = useState("");

  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const [editQuantity, setEditQuantity] = useState(0); // Initialize state for editQuantity
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for modal visibility
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null); // State to track selected material index

  const [deleteIndex, setDeleteIndex] = useState(null);

  const [deleteModalPOpen, setDeletePModalOpen] = useState(false);

  const openDeleteModal = () => setDeletePModalOpen(true);

  const closeDeleteModal = () => {
    setDeletePModalOpen(false);
  };

  const openEditModal = (index) => {
    setSelectedMaterialIndex(index);
    setEditQuantity(data.materials[index].QTY); // Set the current quantity in the modal
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
      setIsEditModalOpen(false);
      setEditQuantity(0); // Reset quantity when closing
      setSelectedMaterialIndex(null); // Reset selected index
  };

  const handleSaveEdit = async () => {
    if (selectedMaterialIndex !== null) {
        // Update the materials array with the new quantity
        const updatedMaterials = [...data.materials];
        updatedMaterials[selectedMaterialIndex].QUANTITY = editQuantity;

        // Update the state (if you have a state for materials)
        // setData((prevData) => ({ ...prevData, materials: updatedMaterials }));

        // Send the updated materials to the backend
        try {
            await sendApiRequest("post", "/grp/edit/" + data.id, { materials: JSON.stringify(updatedMaterials) });
            toast("Material quantity updated successfully");
        } catch (error) {
            console.error("Error updating materials:", error);
        }
    }

    closeEditModal(); // Close the modal after saving
  };

  useEffect(() => {
    const lastClickTime = localStorage.getItem("lastGRPEmailSent");
    if(lastClickTime) {
        const timePassed = Date.now() - parseInt(lastClickTime);
        const cooldownPeriod = 5 * 60 * 1000;
        if(timePassed < cooldownPeriod) {
            setIsDisabled(true);
            setTimeLeft(Math.ceil((cooldownPeriod - timePassed) / 1000));
        }
    }
  }, [isDisabled, timeLeft]);

  useEffect(() => {
      let timer;
      let timePassed;
      let cooldownPeriod;

      const lastClickTime = localStorage.getItem("lastGRPEmailSent");

      if(lastClickTime) {
          timePassed = Date.now() - parseInt(lastClickTime);
          cooldownPeriod = 5 * 60 * 1000;
      }

      if (isDisabled && timeLeft > 0 && (timePassed < cooldownPeriod)) {
        timer = setInterval(() => {
          console.log(timeLeft);
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
      } else if (timeLeft === 0) {
        setIsDisabled(false);
      }
      return () => clearInterval(timer);
  }, [isDisabled, timeLeft]);

  const handleEmailMerchant = async () => {
      try {
          console.log(data);
          console.log(data.id);
          // await sendApiRequest("post", "/grp/mail_merchant/" + data.id, {});
          // toast("Merchant mailed successfully");

          // Set the cooldown
          // localStorage.setItem('lastGRPEmailSent', Date.now().toString());
          // setIsDisabled(true);
          // setTimeLeft(5 * 60); 
      } catch (error) {
          console.error("Error updating materials:", error);
      }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sendApiRequest("get", "/grp/fetch/" + id);
        setData(response.data);
        setMeta(response.data.meta);
        setSerialNoType(response.data.meta.serialNoType || "GLC");
      } catch (error) {
        // Handle the error gracefully
        console.error("Error fetching data:", error);
        // Optionally, you can set an error state or perform other actions
      }
    };

    fetchData(); // Call the fetchData function
  }, [id]);

  let pressureTestCerts = flattenArray(data?.meta?.pressureTestCerts);
  let housingPhotos = flattenArray(data?.meta?.photos);
  let electricianCert = flattenArray(data?.meta?.electricianCert);
  let electricianPhotos = flattenArray(data?.meta?.electricianPhotos);

  const handleDeletePressureTestCerts = async () => {
    // Check if deleteIndex is valid
    if (
      deleteIndex !== null &&
      deleteIndex >= 0 &&
      deleteIndex < pressureTestCerts.length
    ) {
      const updatedpressureTestCerts = [...pressureTestCerts];
      updatedpressureTestCerts.splice(deleteIndex, 1); // Remove the schematic at deleteIndex
      // Update the state or call a function to update the parent component's state
      // For example: updateSchematics(id, updatedSchematics);

    }
    closeDeleteModal();
  };

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
  }

  function handleDeleteModalOpen() {
    setDeleteModalOpen(true);
  }

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 40, paddingRight: 10 }}>
          <Grid>
            <Grid.Column floated="left" width={7}>
              <h3 className={"primary-color"}>
                GRP HOUSING, DELIVERY & COLLECTION SYSTEM
              </h3>
              <Breadcrumb>
                <Link to={"/dashboard"}>
                  <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                </Link>
                <Breadcrumb.Divider icon="right chevron" />
                <Link to={"/dashboard/grp"}>
                  <Breadcrumb.Section link>GRP System</Breadcrumb.Section>
                </Link>
                <Breadcrumb.Divider icon="right arrow" />
                <Breadcrumb.Section>
                  Viewing: {data.destination}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column floated="right" width={6}>
              {user_role == "1" && (
                <>
                  <div style={{ marginRight: 1, float: "right" }}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <Link to={"/dashboard/grp/edit/" + id}>
                          <Button
                            color="primary"
                            content="Edit Record"
                            icon="pencil"
                            labelPosition="right"
                          />
                        </Link>

                        <Modal
                          dimmer={"blurring"}
                          basic={true}
                          onClose={handleDeleteModalClose}
                          onOpen={handleDeleteModalOpen}
                          open={deleteModalOpen} // Use separate state for each modal
                          size="small"
                          trigger={
                            <Button
                              color="red"
                              content="Delete"
                              icon="trash"
                              labelPosition="right"
                            />
                          }
                        >
                          <Header icon>
                            <Icon name="archive" />
                            Delete Record: {data.destination}?
                          </Header>
                          <Modal.Content>
                            <p style={{ textAlign: "center" }}>Are you sure?</p>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                              inverted={true}
                              color="red"
                              onClick={() => handleDeleteModalClose()}
                            >
                              <Icon name="remove" /> No
                            </Button>
                            <Button
                              inverted={true}
                              color="green"
                              onClick={async () => {
                                toast("Record Successfully Deleted", {
                                  type: "success",
                                });

                                await sendApiRequest(
                                  "post",
                                  "/grp/delete/" + id
                                );

                                handleDeleteModalClose();

                                return navigate("/dashboard/grp");

                                // await deleteProject(project.id, localStorage.getItem('token'));
                              }}
                            >
                              <Icon name="checkmark" /> Yes
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {(user_role == "4" || user_role == "2") && (
                <>
                  <div style={{ marginRight: 1, float: "right" }}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <Link to={"/dashboard/grp/edit/" + id}>
                          <Button
                            color="primary"
                            content="Edit Record"
                            icon="pencil"
                            labelPosition="right"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Grid.Column>
          </Grid>
          <br />
          <div style={{ fontSize: 15 }}>
            <span className={"primary-color"}>Destination</span>
            <br />
            <span>
              {data.destination} <FaMapMarker />
            </span>
            <br /> <br />
            <span className={"primary-color"}>Order Number</span>
            <br />
            <span>{data.orderNo}</span>
            <br /> <br />
            <span className={"primary-color"}>Adveco Order Number</span>
            <br />
            <span>{data.meta?.controlQualityCheck?.advecoNo}</span>
            <br /> <br />
            <span className={"primary-color"}>GRP Housing Arriving</span>
            <br />
            <span>{formatToBritishFormat(data.arrivingDate)}</span>
            <br /> <br />
            <span className={"primary-color"}>Collection Date (Leave Us)</span>
            <br />
            <span>{formatToBritishFormat(meta.collectionDate)}</span>
            <br /> <br />
            {serialNoType === "GLC" && (
              <>
                <span className={"primary-color"}>GLE Serial Number</span>
                <br />
                <span>{meta.gleSerialNo}</span>
                <br /> <br />
                <span className={"primary-color"}>GLC Serial Number</span>
                <br />
                <span>{meta.glcSerialNo}</span>
                <br /> <br />
              </>
            )}
            {serialNoType === "ASTI" && (
              <>
                <span className={"primary-color"}>ASTI 210 Serial No</span>
                <br />
                <span>{meta.astiTwoTenSerialNo}</span>
                <br /> <br />
                <span className={"primary-color"}>ASTI 210 Serial No</span>
                <br />
                <span>{meta.astiTwoTenSerialNoTwo}</span>
                <br /> <br />
              </>
            )}
            <span className={"primary-color"}>9kW Immersion</span>
            <br />
            <span>{meta["NineKWImmersion"]}</span>
            <br /> <br />
            <span className={"primary-color"}>6kW Immersion</span>
            <br />
            <span>{meta["SixKWImmersion"]}</span>
            <br /> <br />
            <span className={"primary-color"}>Buffer Serial Number</span>
            <br />
            <span>{meta["bufferSerialNo"]}</span>
            <br /> <br />
            <span className={"primary-color"}>Air Source Serial Number</span>
            <br />
            <span>{meta["airSourceSerialNo"]}</span>
            <br /> <br />
            <span className={"primary-color"}>Electric Boiler</span>
            <br />
            <span>{meta["electricBoiler"]}</span>
            <br /> <br />
            <span className={"primary-color"}>
              Pressure Test Certificate(s)
            </span>
            <br /><br />
            <br />
            <span>
              {pressureTestCerts == null && (
                  <List.Item>
                    <List.Content>
                      <span class={"primary-color"}>
                        No pressure Test Certs cert yet
                      </span>
                    </List.Content>
                  </List.Item>
                )}
                {pressureTestCerts &&
                  pressureTestCerts.map((file, index) => (
                    <List.Item key={index}>
                      <List.Content>
                        <a
                          style={{ color: "#044f66", fontSize: 15 }}
                          target="_blank"
                          href={file}
                        >
                          {extractFileNameAndExtension(file)}
                        </a>
                        &nbsp;
                        <List.Icon
                          style={{ color: "#044f66" }}
                          name="download"
                        />
                        <a
                      href="#"
                      onClick={() => {
                        setDeleteIndex(index);
                        openDeleteModal();
                      }}
                    >
                      <List.Icon style={{ color: "red" }} name="trash" />
                    </a>{" "}
                      </List.Content>
                    </List.Item>
                  ))}
            </span>
            <br />
            <br />
            <span className={"primary-color"}>
              Electrician Test Certificate(s)
            </span>
            <br />
            <br />
            <span>
              <List>
                {electricianCert == null && (
                  <List.Item>
                    <List.Content>
                      <span class={"primary-color"}>
                        No electrician cert yet
                      </span>
                    </List.Content>
                  </List.Item>
                )}
                {electricianCert &&
                  electricianCert.map((file, index) => (
                    <List.Item key={index}>
                      <List.Content>
                        <a
                          style={{ color: "#044f66", fontSize: 15 }}
                          target="_blank"
                          href={file}
                        >
                          {extractFileNameAndExtension(file)}
                        </a>
                        &nbsp;
                        <List.Icon
                          style={{ color: "#044f66" }}
                          name="download"
                        />
                      </List.Content>
                    </List.Item>
                  ))}
              </List>
            </span>
            <br />
            <span className={"primary-color"}>Photos of Electrical Works</span>
            <br />
            <br />
            <span>
              <LightboxInner images={electricianPhotos}  />
            </span>
            <br />
            <span className={"primary-color"}>Photos of this housing</span>
            <br />
            <br />
            <span>
              <LightboxInner images={housingPhotos}  />
            </span>
            <br />
            <br />
            <span className={"primary-color"}>
              <u>GRP HOUSING CONTROL QUALITY CHECKLIST</u>
            </span>
            <br />
            <br />
            <span className={"primary-color"}>Adveco Order Number</span>
            <br />
            <span>{data.meta?.controlQualityCheck?.advecoNo}</span>
            <br /> <br />
            <span className={"primary-color"}>
              <u>Mechanical Information</u>
            </span>
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <div style={{ width: "30%" }}>
                <span className={"primary-color"}>Installation Carried By</span>
                <br />
                <span>
                  {
                    data.meta?.controlQualityCheck?.mechanicalInfo
                      ?.installationCarriedOutBy
                  }
                </span>
              </div>
              <div>
                <span className={"primary-color"}>Date</span>
                <br />
                <span>
                  {formatToBritishFormat(
                    data.meta?.controlQualityCheck?.mechanicalInfo
                      ?.installationCarriedOutDate
                  )}
                </span>
              </div>
            </div>
            <br /> <br />
            <span className={"primary-color"}>
              <u>Pressure Test Report</u>
            </span>
            <br />
            <br />
            <span>Domestic Hot Water System</span>
            <br />
            {data.meta?.controlQualityCheck?.mechanicalInfo?.pressureTestReport
              ?.domesticHotWaterSystem == "Pass" && (
              <>
                <FaCheckCircle color={"green"} style={{ marginTop: 10 }} /> Pass
              </>
            )}
            {data.meta?.controlQualityCheck?.mechanicalInfo?.pressureTestReport
              ?.domesticHotWaterSystem == "Fail" && (
              <>
                <FaCircleXmark color={"red"} style={{ marginTop: 10 }} /> Fail
              </>
            )}
            <br />
            <br />
            <span>ASHP Primary System</span>
            <br />
            {data.meta?.controlQualityCheck?.mechanicalInfo?.pressureTestReport
              ?.ashpPrimarySystem == "Pass" && (
              <>
                <FaCheckCircle color={"green"} style={{ marginTop: 10 }} /> Pass
              </>
            )}
            {data.meta?.controlQualityCheck?.mechanicalInfo?.pressureTestReport
              ?.ashpPrimarySystem == "Fail" && (
              <>
                <FaCircleXmark color={"red"} style={{ marginTop: 10 }} /> Fail
              </>
            )}
            <br />
            <br />
            <span className={"primary-color"}>
              <u>Further Checks</u>
            </span>
            <br />
            <br />
            <div style={{ display: "flex", width: "70%" }}>
              <div className={"grp-view-page-further-checks-card"}>
                <span>GLC Tank Data Plate</span>
                <br />
                {data.meta?.controlQualityCheck?.mechanicalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled?.glcTankDataPlate
                  ?.report == "Pass" && (
                  <>
                    <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />{" "}
                    Pass
                  </>
                )}

                {data.meta?.controlQualityCheck?.mechanicalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled?.glcTankDataPlate
                  ?.report == "Fail" && (
                  <>
                    <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />{" "}
                    Fail
                  </>
                )}
                <br />
                <br />
                <span className={"primary-color"}>Serial No</span>
                <br />
                {
                  data.meta?.controlQualityCheck?.mechanicalInfo
                    ?.confirmAllUnitsAreCorrectlyLabeled?.glcTankDataPlate
                    ?.serialNo
                }
              </div>
              <div className={"grp-view-page-further-checks-card"}>
                <span>GLE Tank Data Plate</span>
                <br />
                {data.meta?.controlQualityCheck?.mechanicalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled?.gleTankDataPlate
                  ?.report == "Pass" && (
                  <>
                    <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />{" "}
                    Pass
                  </>
                )}

                {data.meta?.controlQualityCheck?.mechanicalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled?.gleTankDataPlate
                  ?.report == "Fail" && (
                  <>
                    <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />{" "}
                    Fail
                  </>
                )}
                <br />
                <br />
                <span className={"primary-color"}>Serial No</span>
                <br />
                {
                  data.meta?.controlQualityCheck?.mechanicalInfo
                    ?.confirmAllUnitsAreCorrectlyLabeled?.gleTankDataPlate
                    ?.serialNo
                }
              </div>
              <div className={"grp-view-page-further-checks-card"}>
                <span>FS1000 Tank Data Plate</span>
                <br />
                {data.meta?.controlQualityCheck?.mechanicalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled?.fs1000TankDataPlate
                  ?.report == "Pass" && (
                  <>
                    <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />{" "}
                    Pass
                  </>
                )}

                {data.meta?.controlQualityCheck?.mechanicalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled?.fs1000TankDataPlate
                  ?.report == "Fail" && (
                  <>
                    <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />{" "}
                    Fail
                  </>
                )}
                <br />
                <br />
                <span className={"primary-color"}>Serial No</span>
                <br />
                {
                  data.meta?.controlQualityCheck?.mechanicalInfo
                    ?.confirmAllUnitsAreCorrectlyLabeled?.fs1000TankDataPlate
                    ?.serialNo
                }
              </div>
            </div>
            <br />
            <br />
            <Divider />
            <span className={"primary-color"}>
              <u>Electrical Information</u>
            </span>
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <div style={{ width: "30%" }}>
                <span className={"primary-color"}>Installation Carried By</span>
                <br />
                <span>
                  {
                    data.meta?.controlQualityCheck?.electricalInfo
                      ?.electricalInformationInstallCarriedBy
                  }
                </span>
              </div>
              <div>
                <span className={"primary-color"}>Date</span>
                <br />
                <span>
                  {formatToBritishFormat(
                    data.meta?.controlQualityCheck?.electricalInfo
                      ?.electricalInformationInstallDate
                  )}
                </span>
              </div>
            </div>
            <br /> <br />
            <span className={"primary-color"}>
              <u>Further Checks (Confirm all units are correctly labelled)</u>
            </span>
            <br />
            <br />
            <div style={{ display: "flex", width: "100%" }}>
              <div className={"grp-view-page-further-checks-card"}>
                <span>Control panel model sticker inside door</span>
                <br />
                {data.meta?.controlQualityCheck?.electricalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled
                  ?.controlPanelModelInsideDoor?.report == "Pass" && (
                  <>
                    <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />{" "}
                    Pass
                  </>
                )}

                {data.meta?.controlQualityCheck?.electricalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled
                  ?.controlPanelModelInsideDoor?.report == "Fail" && (
                  <>
                    <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />{" "}
                    Fail
                  </>
                )}
                <br />
                <br />
                <div style={{ display: "flex", width: "85%" }}>
                  <span>
                    <span className={"primary-color"}>Serial No</span> <br />{" "}
                    {
                      data.meta?.controlQualityCheck?.electricalInfo
                        ?.confirmAllUnitsAreCorrectlyLabeled
                        ?.controlPanelModelInsideDoor?.serialNo
                    }
                  </span>
                  <span style={{ marginLeft: "30px" }}>
                    <span className={"primary-color"}>IMEI</span> <br />{" "}
                    {
                      data.meta?.controlQualityCheck?.electricalInfo
                        ?.confirmAllUnitsAreCorrectlyLabeled
                        ?.controlPanelModelInsideDoor?.imei
                    }
                  </span>
                </div>
              </div>
              <div className={"grp-view-page-further-checks-card"}>
                <span>Black GST unit inside control panel</span>
                <br />
                {data.meta?.controlQualityCheck?.electricalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled
                  ?.blackGstUnitInsideControlPanel?.report == "Pass" && (
                  <>
                    <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />{" "}
                    Pass
                  </>
                )}

                {data.meta?.controlQualityCheck?.electricalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled
                  ?.blackGstUnitInsideControlPanel?.report == "Fail" && (
                  <>
                    <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />{" "}
                    Fail
                  </>
                )}
                <br />
                <br />
                <div style={{ display: "flex", width: "85%" }}>
                  <span>
                    <span className={"primary-color"}>IMEI</span> <br />{" "}
                    {
                      data.meta?.controlQualityCheck?.electricalInfo
                        ?.confirmAllUnitsAreCorrectlyLabeled
                        .blackGstUnitInsideControlPanel.imei
                    }
                  </span>
                  <span style={{ marginLeft: "30px" }}>
                    <span className={"primary-color"}>Model</span> <br />{" "}
                    {
                      data.meta?.controlQualityCheck?.electricalInfo
                        ?.confirmAllUnitsAreCorrectlyLabeled
                        .blackGstUnitInsideControlPanel.model
                    }
                  </span>
                </div>
              </div>
              <div className={"grp-view-page-further-checks-card"}>
                <span>Immersion heater branding labels</span>
                <br />
                {data.meta?.controlQualityCheck?.electricalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled
                  ?.immersionHeaterBrandingLabels?.report == "Pass" && (
                  <>
                    <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />{" "}
                    Pass
                  </>
                )}

                {data.meta?.controlQualityCheck?.electricalInfo
                  ?.confirmAllUnitsAreCorrectlyLabeled
                  ?.immersionHeaterBrandingLabels?.report == "Fail" && (
                  <>
                    <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />{" "}
                    Fail
                  </>
                )}
                <br />
                <br />
                <div style={{ display: "flex", width: "85%" }}>
                  <span>
                    {" "}
                    <span className={"primary-color"}>Model</span> <br />{" "}
                    {
                      data.meta?.controlQualityCheck?.electricalInfo
                        ?.confirmAllUnitsAreCorrectlyLabeled
                        ?.immersionHeaterBrandingLabels?.model
                    }
                  </span>
                  <span style={{ marginLeft: "30px" }}>
                    <span className={"primary-color"}>Serial No</span> <br />{" "}
                    {
                      data.meta?.controlQualityCheck?.electricalInfo
                        ?.confirmAllUnitsAreCorrectlyLabeled
                        ?.immersionHeaterBrandingLabels?.serialNo
                    }
                  </span>
                </div>
              </div>
            </div>
            <br /> <br />
            <span className={"primary-color"}>
              Adveco contact details sticker inside door
            </span>
            <br />
            {data.meta?.controlQualityCheck?.electricalInfo
              ?.confirmAllUnitsAreCorrectlyLabeled
              ?.advecoContactDetailsStickerInsideDoor?.report == "Pass" && (
              <>
                <FaCheckCircle color={"green"} style={{ marginTop: 10 }} /> Pass
              </>
            )}
            {data.meta?.controlQualityCheck?.electricalInfo
              ?.confirmAllUnitsAreCorrectlyLabeled
              ?.advecoContactDetailsStickerInsideDoor?.report == "Fail" && (
              <>
                <FaCircleXmark color={"red"} style={{ marginTop: 10 }} /> Fail
              </>
            )}
            <br /> <br />
            <span className={"primary-color"}>
              Adveco brand sticker on FPi heat pump
            </span>
            <br />
            {data.meta?.controlQualityCheck?.electricalInfo
              ?.confirmAllUnitsAreCorrectlyLabeled
              ?.advecoBrandStickerOnFpiHeatPump?.report == "Pass" && (
              <>
                <FaCheckCircle color={"green"} style={{ marginTop: 10 }} /> Pass
              </>
            )}
            {data.meta?.controlQualityCheck?.electricalInfo
              ?.confirmAllUnitsAreCorrectlyLabeled
              ?.advecoBrandStickerOnFpiHeatPump?.report == "Fail" && (
              <>
                <FaCircleXmark color={"red"} style={{ marginTop: 10 }} /> Fail
              </>
            )}
            <br />
          </div>
          <br />
          <Divider />
          <br /> <br />
          <span className={"primary-color"}>
            <u>Quality Checks of Mechanical Installation</u>
          </span>
          <br />
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "30%" }}>
              <span className={"primary-color"}>
                Mechanical installation carried out by
              </span>
              <br />
              <span>
                {
                  data.meta?.controlQualityCheck
                    ?.qualityChecksForMechanicalInstall[0]
                    .installationCarriedOutBy
                }
              </span>
            </div>
            <div>
              <span className={"primary-color"}>Date</span>
              <br />
              <span>
                {formatToBritishFormat(
                  data.meta?.controlQualityCheck
                    ?.qualityChecksForMechanicalInstall[0]
                    .installationCarriedOutDate
                )}
              </span>
            </div>
          </div>
          <br />
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "30%" }}>
              <span className={"primary-color"}>
                Mechanical installation quality checks <br /> carried out by
              </span>
              <br />
              <span>
                {
                  data.meta?.controlQualityCheck
                    ?.qualityChecksForMechanicalInstall[1]
                    .installationCarriedOutBy
                }
              </span>
            </div>
            <div>
              <span className={"primary-color"}>Date</span>
              <br />
              <span>
                {formatToBritishFormat(
                  data.meta?.controlQualityCheck
                    ?.qualityChecksForMechanicalInstall[1]
                    .installationCarriedOutDate
                )}
              </span>
            </div>
          </div>
          <br />
          <br />
          <span className={"primary-color"}>Quality Checks</span>
          <br />
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkHousingForAnyDamageExternallyAndInternally === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkHousingForAnyDamageExternallyAndInternally === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check housing for any damage externally and internally
          <br />
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkHousingForAnyDamageExternallyAndInternally === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkCylindersAreFixedDownCorrectly === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check cylinders are fixed down correctly
          <br />
          <FaCheckCircle color={"green"} style={{ marginTop: 10 }} /> Check that
          the air source heat pump is secure, including straps around the heat
          pump and brackets to stabilise the unit during transit
          <br />
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkAllPipeworkIsClippedAndSecure === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkAllPipeworkIsClippedAndSecure === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check all pipework is clipped and secure
          <br />
          <FaCheckCircle color={"green"} style={{ marginTop: 10 }} /> Ensure
          pipework has been pressure tested
          <br />
          <FaCheckCircle color={"green"} style={{ marginTop: 10 }} /> Check all
          pipework is insulated and joints taped
          <br />
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkSafetyValvesArePipedtoDrain === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkSafetyValvesArePipedtoDrain === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check safety valves are piped to drain
          <br />
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkPipeworkExitingTheUnitsIsNotTooLong === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkPipeworkExitingTheUnitsIsNotTooLong === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check pipework exiting the units is not too long (this may cause
          damage in transit)
          <br />
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkUnitIsCleanInside === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkUnitIsCleanInside === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Unit is clean and tidy inside
          <br />
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkFrostProtectionCableWrappedAroundFlowAndReturn === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityChecksList
            ?.checkFrostProtectionCableWrappedAroundFlowAndReturn === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check frost protection cable wrapped around flow and return
          <Divider />
          <br />
          <span className={"primary-color"}>
            <u>Quality Checks of Electrical Installation</u>
          </span>
          <br />
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "30%" }}>
              <span className={"primary-color"}>
                Electrical installation carried out by
              </span>
              <br />
              <span>
                {
                  data.meta?.controlQualityCheck
                    ?.qualityCheckOfElectricalInstallation
                    ?.qualityChecksOfElectricalInstallationCarriedBy
                }
              </span>
            </div>
            <div>
              <span className={"primary-color"}>Date</span>
              <br />
              <span>
                {formatToBritishFormat(
                  data.meta?.controlQualityCheck
                    ?.qualityCheckOfElectricalInstallation
                    ?.qualityChecksOfElectricalInstallationCarriedByDate
                )}
              </span>
            </div>
          </div>
          <br />
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "30%" }}>
              <span className={"primary-color"}>
                Electrical installation quality checks <br /> carried out by
              </span>
              <br />
              <span>
                {
                  data.meta?.controlQualityCheck
                    ?.qualityCheckOfElectricalInstallation
                    ?.qualityChecksOfElectricalInstallationCarriedByTwo
                }
              </span>
            </div>
            <div>
              <span className={"primary-color"}>Date</span>
              <br />
              <span>
                {formatToBritishFormat(
                  data.meta?.controlQualityCheck
                    ?.qualityCheckOfElectricalInstallation
                    ?.qualityChecksOfElectricialInstallationCarriedByTwoDate
                )}
              </span>
            </div>
          </div>
          <br />
          <br />
          <span className={"primary-color"}>Quality Checks</span>
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.controlPanelSecurelyMounted == true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.controlPanelSecurelyMounted == false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Control panel securely mounted
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.controlPanelTerminatedAndLabelled === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.controlPanelTerminatedAndLabelled ===
            false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Control panel terminated and labelled
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.tubularHeaterFuseSpurInstalledAndConnected ===
            true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.tubularHeaterFuseSpurInstalledAndConnected ===
            false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Tubular heater, fuse spur installed and connected
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.lightAndSwitchInstalledAndConnected ===
            true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.lightAndSwitchInstalledAndConnected ===
            false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Light and switch installed and connected
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.dutyImmersionIsolatorAndConnection ===
            true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.dutyImmersionIsolatorAndConnection ===
            false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Duty immersion isolator and connection
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.dutyStatConnectedRearOfTankAndSet === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.dutyStatConnectedRearOfTankAndSet ===
            false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Duty stat connected rear of tank and set to 630C
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.standbyImmersionIsolatorAndConnection ===
            true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.standbyImmersionIsolatorAndConnection ===
            false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Standby immersion isolator and connection
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.airSourceHeatPumpIsolatorAndConnection ===
            true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.airSourceHeatPumpIsolatorAndConnection ===
            false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Air source heat pump isolator and connection
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.destrat === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.destrat === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Destrat pump spur and connection
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.kioskLight === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.kioskLight === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Kiosk light, switch and connection
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.returnPump === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.returnPump === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Return pump, fuse spur and isolator
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.rcdSocketInstalled === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.rcdSocketInstalled === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          RCD socket installed and connection
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.tubularHeater === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.tubularHeater === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Tubular heater, fuse spur and connection, securely mounted with no
          damage to brackets
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.earthBounding === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.earthBounding === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Earth bonding to mechanical services and electrical tray
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.NICEIC === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.NICEIC === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          NICEIC Installation certificate with the unit
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.ensureAllIsolators === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.ensureAllIsolators === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Ensure all isolators are labelled
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.checkUnitIsCleanInside === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.checkUnitIsCleanInside === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check unit is clean inside
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.checkNoScrewsSticking === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.checkNoScrewsSticking === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Check no screws sticking through the housing to outside
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.gsmToPanel === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.gsmToPanel === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          GSM to panel connections are correct (Red wire to positive terminal,
          black wire to negative, yellow wire to third terminal)
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.controlPanelWiring === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.controlPanelWiring === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          Control panel wiring schematic is included inside control panel door
          <br />
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.allManualsArePresent === true && (
            <>
              <FaCheckCircle color={"green"} style={{ marginTop: 10 }} />
            </>
          )}
          {data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
            ?.qualityChecksList?.allManualsArePresent === false && (
            <>
              <FaCircleXmark color={"red"} style={{ marginTop: 10 }} />
            </>
          )}{" "}
          All manuals are present (ASHP, water heater, preheat, immersion
          heaters, control panel)
          <br /> <br />

          {/* Material list */}
          <br /> <br />
          <span className={"primary-color"} style={{ fontWeight: 'bold' }}>MATERIALS</span>
          <br /><br />
          <div>
              {data.materials && data.materials.length > 0 ? (
                  <table style={{ width: '50%', borderCollapse: 'collapse' }}>
                      <thead>
                          <tr>
                              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Code</th>

                              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Description</th>

                              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Quantity</th>

                              {/* <th style={{ border: '1px solid #ddd', padding: '8px' }}>Unit Price/Discount</th>

                              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Amount</th> */}

                              {user_role == "1" && (
                                  <th style={{ border: '1px solid #ddd', padding: '8px' }}>Actions</th>
                              )}
                          </tr>
                      </thead>
                      <tbody>
                          {data.materials.map((material, index) => (
                              <tr key={index}>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.CODE}</td>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.DESCRIPTION}</td>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.QUANTITY}</td>
                                  {/* <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.DISCOUNT}</td>
                                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.AMOUNT}</td> */}
                                  {user_role == "1" && (
                                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                          <Icon
                                              name="pencil"
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => openEditModal(index)}
                                          />
                                      </td>
                                  )}
                              </tr>
                          ))}
                      </tbody>
                  </table>
              ) : (
                  <span>No materials available</span>
              )}
          </div>

          {/* <Grid>
            <Grid.Column width={17}>
                <Form.TextArea
                    size="medium"
                    label="Note to Merchant"
                    placeholder="Note to Merchant"
                    value={data.grpNote}
                    // disabled={true}
                    readOnly
                    onChange={() => {}}
                />
            </Grid.Column>
          </Grid> */}

          <div style={{ width: '100%', marginTop: '30px'}}>
              <span className={"primary-color"} style={{ fontWeight: 'bold' }}>Note to Merchant</span>
              <Grid.Column width={17}>
                  {/* <Form.TextArea
                      size="large"
                      // label="Note to Merchant"
                      placeholder="Note to Merchant"
                      value={data.grpNote}
                      readOnly
                      onChange={() => {}}
                  /> */}
                  <span>{data.grpNote}</span>
              </Grid.Column>
          </div>

          <Modal open={isEditModalOpen} onClose={closeEditModal}>
            {selectedMaterialIndex !== null && (
                <Modal.Header>
                    Edit Quantity: {data.materials[selectedMaterialIndex].CODE} / {data.materials[selectedMaterialIndex].DESCRIPTION}
                </Modal.Header>
            )}

            <Modal.Content>
                <div>
                    <label>Enter New Quantity:</label>
                    <br />
                    <input
                        type="number"
                        value={editQuantity}
                        onChange={(e) => setEditQuantity(e.target.value)}
                        min="0" // Optional: Prevent negative values
                        style={{ border: '1px solid #000' }}
                    />
                </div>
            </Modal.Content>

            <Modal.Actions>
                <Button color="red" onClick={closeEditModal}>
                    Cancel
                </Button>
                <Button color="green" onClick={handleSaveEdit}>
                    Save
                </Button>
            </Modal.Actions>
          </Modal>

          {user_role == "1" && (
              <>
                  <br />
                  <Button
                      size="midi"
                      color="#18823d"
                      style={{ backgroundColor: "#044f66", color: "#fff" }}
                      content={loading ? "Saving..." : "Send Email to Merchant"} // Change button text based on loading state
                      icon="mail"
                      labelPosition="right"
                      onClick={handleEmailMerchant}
                      loading={loading} // Add loading prop to button
                      disabled={isDisabled || loading} // Disable button while loading
                  />
              </>
          )}
          <br /><br />
        </div>
      </AdminDashLayout>
    </>
  );
}

export default ViewGrp;
