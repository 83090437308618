import AuthService from "../auth";
import AdminDashLayout from "../components/AdminDashLayout";
import {
  Grid,
  Button,
  Message,
  Modal,
  Form,
  Dropdown,
} from "semantic-ui-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TimesheetWeekTables from "../components/TimesheetWeekTables";
import { useEffect, useState, useRef } from "react";
import sendApiRequest from "../api";
import TimesheetStatusComponent from "../components/TimesheetStatusComponent";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ViewTimesheet() {
  const { id } = useParams();
  const user = AuthService.getCurrentUser();
  const user_role = user.role;

  const navigate = useNavigate();

  const [timesheetData, setTimesheetData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [weekHoursLogged, setWeekHoursLogged] = useState("");
  const [timesheetWeeksCount, setTimesheetWeeksCount] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");

  const [refresh, setRefresh] = useState(false);

  const timesheetRef = useRef(null);

  const downloadAsPdf = async () => {
    if(!timesheetRef.current) return;

    try {
      // First create a canvas from the HTML content
      const canvas = await html2canvas(timesheetRef.current);
      
      // Get the canvas dimensions
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      
      // Create PDF
      const pdf = new jsPDF('p', 'mm', 'a4');
      
      // Calculate if we need multiple pages
      let heightLeft = imgHeight;
      let position = 0;
      
      // Add image to PDF, creating new pages if necessary
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      
      // Download PDF
      pdf.save('downloaded-content.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }

  const handleDownload = (pdfLink) => {
    const link = document.createElement('a');
    link.href = pdfLink;
    link.download = `Approved_Timesheet_Week${timesheetWeeksCount.weekInAYear}_${timesheetData.year}.pdf`;
    link.target = "_blank"; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log(`Approved_Timesheet_Week${timesheetWeeksCount.weekInAYear}_${timesheetData.year}.pdf`);
  };

  // Use the useLocation hook to access the location object
  const location = useLocation();

  // Extract the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get a specific query parameter by name
  const week = queryParams.get("week");

  const fetchData = async () => {
    // Make an API request to fetch timesheet data
    sendApiRequest("get", `/timesheet/fetch?id=${id}`)
      .then((data) => {
        console.log(data.data.timesheetWeeks);
        // Search for the weekCount in the response and find the corresponding week
        const weekData = data.data.timesheetWeeks.weeks.find(
          (weekData) => weekData.weekCount === parseInt(week)
        );

        if (weekData) {
          setTimesheetData(data.data);
          setStatusData(weekData);
          setTableData(weekData.days);

          const weeksCount = data.data.weeksCount.find(
            (r) => r.week === parseInt(week)
          );
          console.log(weeksCount);
          setTimesheetWeeksCount(weeksCount);
          setWeekHoursLogged(weeksCount.hoursLogged);
        } else {
          toast("No timesheet record yet");
          setTimeout(() => {
            navigate("/dashboard/timesheet");
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error fetching timesheet data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id, week]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setStatus("");
    setReason("");
  };

  const handleStatusChange = (_, { value }) => {
    setStatus(value);
  };

  const handleReasonChange = (_, { value }) => {
    setReason(value);
  };

  const handleSubmit = async () => {
    const payload = {
      weekCount: parseInt(week),
      status: status,
      reason: reason,
    };

    console.log(payload);

    const req = await sendApiRequest(
      "post",
      "/timesheet/approve/" + id,
      payload
    );

    // Perform the submit action with the payload
    // ...

    toast("Timesheet Status Updated");

    handleModalClose();

    fetchData()
  };

  return (
    <>
      <AdminDashLayout>
        {timesheetData ? (
          <div style={{ paddingTop: 30, paddingRight: 20 }}>
            <Grid>
              <Grid.Column floated="left" width={8}>
                <h1>
                  <span style={{ color: "rgb(167, 167, 167)" }}>
                    <Link to={"/dashboard/timesheet"}>
                      Timesheet / {timesheetData.month} {timesheetData.year}{" "}
                    </Link>{" "}
                    /
                  </span>{" "}
                  {/* Week {week} */}
                  Week {timesheetWeeksCount.weekInAYear}
                </h1>
              </Grid.Column>
              <Grid.Column floated="right" width={4}>
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: 10, paddingTop: 5 }}></div>
                  <div style={{ textAlign: "" }}>
                    &nbsp; &nbsp;&nbsp;
                    <h5>
                      Hours Logged This Week: {weekHoursLogged} <br />{" "}
                      {/* <small style={{ color: "darkgreen" }}>
                        <Icon name="chart line" />
                        Up +25% from last week
                      </small> */}
                      {statusData.status !== "approved" && user_role === 2 && (
                        <>
                          <br />
                          <br />
                          <div>
                            &nbsp; &nbsp;&nbsp;
                            <Link
                              to={
                                "/dashboard/timesheet/update/" +
                                id +
                                "?week=" +
                                week
                              }
                            >
                              <Button
                                content="Update Timesheet"
                                icon="calendar"
                                labelPosition="right"
                                color="primary"
                              />
                            </Link>
                          </div>
                        </>
                      )}

                      {/* <div style={{ display: "flex" }}> */}
                        {statusData.status === "approved" && user_role === 1 && (
                          <>
                            {/* <br /> */}
                            {/* <br /> */}
                            <Button
                              content="DOWNLOAD PDF"
                              icon="download"
                              labelPosition="right"
                              color="green"
                              // onClick={downloadAsPdf}
                              onClick={() => handleDownload(statusData.pdfLink)}
                            />
                          </>
                        )}

                        {user_role === 1 && (
                          <>
                            {/* <br />
                            <br /> */}
                            <div>
                              &nbsp; &nbsp;&nbsp;
                              <Modal
                                open={modalOpen}
                                onClose={handleModalClose}
                                trigger={
                                  <Button
                                    content="Update Timesheet Status"
                                    icon="calendar"
                                    labelPosition="right"
                                    color="primary"
                                    onClick={handleModalOpen}
                                  />
                                }
                              >
                                <Modal.Header>
                                  Update Timesheet Status
                                </Modal.Header>
                                <Modal.Content>
                                  <Form>
                                    <Form.Field>
                                      <label>Status</label>
                                      <Dropdown
                                        placeholder="Select status"
                                        selection
                                        options={[
                                          {
                                            key: "approved",
                                            text: "Approved",
                                            value: "approved",
                                          },
                                          {
                                            key: "review",
                                            text: "Review",
                                            value: "review",
                                          },
                                          {
                                            key: "rejected",
                                            text: "Rejected",
                                            value: "rejected",
                                          },
                                        ]}
                                        value={status}
                                        onChange={handleStatusChange}
                                      />
                                    </Form.Field>
                                    {status === "rejected" && (
                                      <Form.Field>
                                        <label>Reason for Rejection</label>
                                        <Form.Input
                                          placeholder="Enter reason"
                                          value={reason}
                                          onChange={handleReasonChange}
                                        />
                                      </Form.Field>
                                    )}
                                  </Form>
                                </Modal.Content>
                                <Modal.Actions>
                                  <Button
                                    color="black"
                                    onClick={handleModalClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    positive
                                    icon="checkmark"
                                    labelPosition="right"
                                    content="Submit"
                                    onClick={handleSubmit}
                                  />
                                </Modal.Actions>
                              </Modal>
                            </div>
                          </>
                        )}
                      {/* </div> */}
                    </h5>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column floated="left" width={7}></Grid.Column>
              <Grid.Column floated="right" width={5}>
                <div style={{ paddingTop: 0 }}></div>
              </Grid.Column>
            </Grid>
            <br />
            {statusData.status == "review" && (
              <>
                <Message info>
                  <p>This timesheet has been flagged for review.</p>
                </Message>
              </>
            )}

            {statusData.status == "approved" && (
              <>
                <Message success>
                  <p>This timesheet has been approved.</p>
                </Message>
              </>
            )}

            {statusData.status == "rejected" && (
              <>
                <Message error>
                  <p>This timesheet has been rejected.</p>
                </Message>
                <Message error>
                  <p>
                    Reason: <b>{statusData.reason}</b>
                  </p>
                </Message>
              </>
            )}

            <br />
            {/* Table */}
            <div ref={timesheetRef}>
              <TimesheetWeekTables jsonData={tableData} />
              <TimesheetStatusComponent data={statusData} />
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </AdminDashLayout>
    </>
  );
}

export default ViewTimesheet;
