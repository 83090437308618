import { useParams, useLocation } from "react-router-dom";
import AuthService from '../auth';
import { useEffect, useState } from "react";
import sendApiRequest from "../api";
import { fetchWorker } from "../apiCalls";

const ViewNotification = () => {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const url = queryParams.get('url');
    const user = AuthService.getCurrentUser();
    const userId = user?.id;

    useEffect(() => {
        const updateNotification = async () => {
            if (!userId || !id) return;

            try {
                const response = await sendApiRequest('post', '/auth/user/update/notifications', { id: id })

                if (response.message) {
                    console.log('Notification updated successfully');
                    // Redirect to the original URL if provided
                    if (url) {
                        window.location.href = url;
                    }
                } else {
                    console.error('Failed to update notification');
                }
            } catch (error) {
                console.error('Error updating notification:', error);
            }
        };

        updateNotification();
    }, [userId, id, url]);

    return (
        <div>
       
        </div>
    );
}

export default ViewNotification;
