import React from "react";
import { Icon, Label, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatToBritishFormat } from "../utils";

function formatMonthYear(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("en-GB", { month: "long", year: "numeric" });
}

function VehicleLogMetaTable({ reports }) {
  if (!Array.isArray(reports) || reports.length === 0) {
    return <div>No reports available</div>;
  }

  const formatM = (m) => {
    return Number(m).toLocaleString("en-GB");
  };
  
  return (
    <>
      <Table basic="" size="large" striped celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>DATE</Table.HeaderCell>
            <Table.HeaderCell>MILEAGE</Table.HeaderCell>
            <Table.HeaderCell>WORK PERFORMED</Table.HeaderCell>
            <Table.HeaderCell>PERFORMED BY</Table.HeaderCell>
            <Table.HeaderCell>COST</Table.HeaderCell>
            <Table.HeaderCell>NOTES</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reports.map((report, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {formatToBritishFormat(report.date)}
              </Table.Cell>
              <Table.Cell>{report.mileage} miles</Table.Cell>
              <Table.Cell>
                {report.workPerformed}
              </Table.Cell>
              <Table.Cell>{report.performedBy}</Table.Cell>
              <Table.Cell>£{formatM(report.cost)}</Table.Cell>
              <Table.Cell>{report.notes}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default VehicleLogMetaTable;
