import React from "react";
import ReactDOM from "react-dom/client";
import "semantic-ui-less/semantic.less";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import "./fonts/Geist-1.3.0/statics-ttf/Geist-Medium.ttf"
import "./fonts/GreycliffCF-Bold.woff2";
import "./fonts/GreycliffCF-Light.woff2";
import "./fonts/GreycliffCF-Medium.woff2";
import "./fonts/GreycliffCF-Regular.woff2";
import "./fonts/Larsseit-Bold.woff2";
import "./fonts/Larsseit-Light.woff2";
import "./fonts/Larsseit-Medium.woff2";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Navigate,
    Routes,
} from "react-router-dom";

import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import AuthService from "./auth";
import Logout from "./components/Logout";
import Projects from "./pages/Projects";
import Inactive from "./pages/Inactive";
import CreateProject from "./pages/CreateProject";
import ViewProject from "./pages/ViewProject";
import Timesheet from "./pages/Timesheet";
import ViewTimesheet from "./pages/ViewTimesheet";
import Calendars from "./pages/Calendars";
import Messenger from "./pages/Messenger";
import Clients from "./pages/Clients";
import Workers from "./pages/Workers";
import UpdateTimesheet from "./pages/UpdateTimesheet";
import CreateClient from "./pages/CreateClient";
import RequestHoliday from "./pages/RequestHoliday";
import AddWorker from "./pages/AddWorker";
import HolidayRequests from "./pages/HolidayRequests";
import ViewWorker from "./pages/ViewWorker";
import Grp from "./pages/Grp";
import ViewGrp from "./pages/ViewGrp";
import CreateGrpReport from "./pages/CreateGrpReport";
import EditGrpReport from "./pages/EditGrpReport";
import EditProject from "./pages/EditProject";
import EditClient from "./pages/EditClient";
import EditWorker from "./pages/EditWorker";

import Pipekits from "./pages/Pipekits";
import CreatePipekit from "./pages/CreatePipekit";

import ErrorBoundary from "./components/ErrorBoundary";
import ViewStaffTimesheetWeeks from "./pages/ViewStaffTimesheetWeeks";
import VehicleLog from "./pages/VehicleLog";
import CreateVehicleLog from "./pages/CreateVehicleLog";
import ViewVehicleLog from "./pages/ViewVehicleLog";
import EditVehicleLog from "./pages/EditVehicleLog";
import EditVehicleMetaLog from "./pages/EditVehicleMetaLogs";
import CreateSmallProject from "./pages/CreateSmallProject";
import ProjectInvoiceLogs from "./pages/ProjectInvoiceLogs";
import ManageWorkerHolidayOffDays from "./pages/ManageWorkerHolidayOffDays";

import ManageWorkerHolidays from "./pages/ManageWorkerHolidays";
import ClientsProjects from "./pages/ClientsProject";
import ConfirmBooking from "./pages/ConfirmBooking";
import ProjectLandlordCert from "./pages/ProjectLandlordCert";
import ViewPipekit from "./pages/ViewPipekit";
import EditPipekit from "./pages/EditPipekit";
import ViewNotification from "./pages/ViewNotification";
import RequestAttendance from "./pages/RequestAttendance";
import ViewAttendanceLogs from "./pages/ViewAttendanceLogs";

// Middleware component to check if the user is authenticated
const AuthenticatedMiddleware = ({element: Element}) => {
    const isAuthenticated = AuthService.isLoggedIn(); // Replace with your actual authentication logic

    if (isAuthenticated) {
        return <Element/>;
    } else {
        // Redirect
        return <Navigate to="/logout"/>;
    }
    // return <Element/>;
};

const AuthenticatedRoleMiddleware = ({element: Element}) => {
    const isAuthenticated = AuthService.isLoggedIn(); // Replace with your actual authentication logic
    const user = AuthService.getCurrentUser();

    if (isAuthenticated && user.role === 1) {
        return <Element/>;
    } else {
        // Redirect
        return <Navigate to="/logout"/>;
    }
    // return <Element/>;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/login",
        element: <App/>,
    },
    {
        path: "/inactive",
        element: <Inactive/>,
    },
    {
        path: "/confirm_booking/:id",
        element: <ConfirmBooking />
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>,
    },
    {
        path: "/dashboard/*",
        element: (
            <ErrorBoundary>
                <Routes>
                    <Route
                        path="/"
                        element={<AuthenticatedMiddleware element={Dashboard}/>}
                    />
                    <Route
                        path="/projects"
                        element={<AuthenticatedMiddleware element={Projects}/>}
                    />
                    <Route
                        path="/projects/create"
                        element={<AuthenticatedRoleMiddleware element={CreateProject}/>}
                    />
                    <Route
                        path="/projects/create-small-project"
                        element={<AuthenticatedRoleMiddleware element={CreateSmallProject}/>}
                    />

                    <Route path="/projects/view/:id" element={<ViewProject/>}/>
                    
                    <Route path={"/projects/edit/:id"} element={<EditProject/>}/>

                    <Route path={"/projects/view/:id/request-attendance"} element={<RequestAttendance />} />

                    <Route path={"/projects/view/:id/view-attendance-logs"} element={<ViewAttendanceLogs />} />

                    <Route path={"/projects/invoice/logs/:id"} element={<ProjectInvoiceLogs />} />

                    <Route path={"/project/landlord/add-record/:id"} element={<ProjectLandlordCert />} />


                    <Route path="/timesheet" element={<Timesheet/>}/>
                    <Route path="/timesheet/view/:id" element={<ViewTimesheet/>}/>
                    <Route path="/timesheet/staff/view/:id/:month/:year" element={<ViewStaffTimesheetWeeks />} />
                    <Route path="/timesheet/update/:id" element={<UpdateTimesheet/>}/>
                    <Route path="/calendar" element={<Calendars/>}/>
                    <Route path="/messenger" element={<Messenger/>}/>
                    <Route path="/notifications/view/:id" element={<ViewNotification />} />
                    <Route
                        path="/clients"
                        element={<AuthenticatedRoleMiddleware element={Clients}/>}
                    />
                    <Route path="/clients/create"
                           element={<AuthenticatedRoleMiddleware element={CreateClient}/>}
                    />
                    <Route
                        path={"/clients/edit/:id"}
                        element={<AuthenticatedRoleMiddleware element={EditClient}/>}
                    />
                    <Route
                        path={"/clients/projects/:id"}
                        element={<AuthenticatedRoleMiddleware element={ClientsProjects} />}
                    />
                    <Route
                        path="/workers"
                        element={<AuthenticatedRoleMiddleware element={Workers}/>}
                    />
                    <Route
                        path={"/workers/create"}
                        element={<AuthenticatedRoleMiddleware element={AddWorker}/>}
                    />
                    <Route
                        path={"/workers/view/:id"}
                        element={<AuthenticatedRoleMiddleware element={ViewWorker}/>}
                    />
                    <Route
                        path={"/workers/edit/:id"}
                        element={<AuthenticatedRoleMiddleware element={EditWorker}/>}
                    />
                    <Route
                        path={"/workers/manage-offdays/:id"}
                        element={<AuthenticatedRoleMiddleware element={ManageWorkerHolidayOffDays} />}
                    />
                    <Route
                        path={"/workers/manage-holidays/:id"}
                        element={<AuthenticatedRoleMiddleware element={ManageWorkerHolidays} />}
                    />
                    <Route
                        path={"/manage-holidays/:id"}
                        element={<AuthenticatedMiddleware element={ManageWorkerHolidays} />}
                    />
                    <Route
                        path={"/grp"}
                        element={<AuthenticatedMiddleware element={Grp}/>}
                    />
                    <Route path={'/grp/create'}
                           element={<AuthenticatedRoleMiddleware element={CreateGrpReport}/>}
                    />
                    <Route
                        path={"/grp/view/:id"}
                        element={<AuthenticatedMiddleware element={ViewGrp}/>}
                    />
                    <Route
                        path={"/grp/edit/:id"}
                        element={<AuthenticatedMiddleware element={EditGrpReport}/>}
                    />
                    
                    <Route
                        path={"/pipekits"}
                        element={<AuthenticatedMiddleware element={Pipekits} />}
                    />

                    <Route
                        path={"/pipekits/create"}
                        element={<AuthenticatedRoleMiddleware element={CreatePipekit} />}
                    />

                    <Route
                        path={"/pipekits/view/:id"}
                        element={<AuthenticatedMiddleware element={ViewPipekit} />}
                    />

                    <Route
                        path={"/pipekits/edit/:id"}
                        element={<AuthenticatedMiddleware element={EditPipekit}/>}
                    />

                    <Route
                        path="/request-holiday"
                        element={<RequestHoliday/>}
                    />
                    <Route
                        path={"/holiday-requests"}
                        element={<AuthenticatedRoleMiddleware element={HolidayRequests}/>}
                    />
                    <Route
                        path={"/vehicle-log"}
                        element={<AuthenticatedMiddleware element={VehicleLog} />}
                    />
                    <Route
                        path={"/vehicle-log/create"}
                        element={<AuthenticatedMiddleware element={CreateVehicleLog} />}
                    />
                    <Route
                        path={"/vehicle-log/view/:id"}
                        element={<AuthenticatedMiddleware element={ViewVehicleLog} />}
                    />
                    <Route
                        path={"/vehicle-log/edit/:id"}
                        element={<AuthenticatedMiddleware element={EditVehicleLog} />}
                    />
                    <Route
                        path={"/vehicle-log/edit/logs/:id"}
                        element={<AuthenticatedMiddleware element={EditVehicleMetaLog} />}
                    />
                    <Route
                        path={"/vehicle-log/create"}
                        element={<AuthenticatedMiddleware element={CreateVehicleLog} />}
                        />

                </Routes>
            </ErrorBoundary>
        ),
    },
    {
        path: "/logout",
        element: <Logout/>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

reportWebVitals();
