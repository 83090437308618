import React, { useState } from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';
import ImageGallery from "react-image-gallery";

const LightboxInner = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const imagez = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    }
  ];

  const generatedImages = images.map((image) => ({
    original: image,
    thumbnail: image,
  }));

  return (
    <>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`${index}`}
          style={{
            width: '180px',
            height: 'auto',
            border: '1px solid #fff',
            borderRadius: '10px',
            margin: '5px',
            cursor: 'pointer',
          }}
          onClick={() => openModal(index)}
        />
      ))}

      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        closeIcon
        size="small"
        basic
      >
        <Modal.Content >
        <br /> <br />
          <ImageGallery items={generatedImages} startIndex={selectedImageIndex} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={closeModal}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default LightboxInner;
