import AdminDashLayout from "../components/AdminDashLayout";
import { FaBusinessTime } from "react-icons/fa6";
import {Grid, Button, Form} from "semantic-ui-react";
import { Link } from "react-router-dom";
import WorkersTable from "../components/WorkersTable";
import {useEffect, useState} from "react";
import {fetchWorkers} from "../apiCalls";

function Workers() {
  const [workersCount, setWorkersCount] = useState(0);
  const [workers, setWorkers] = useState([]);
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(100);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchWorkers(page, pageSize, localStorage.getItem("token")).then((data) => {
      setLoading(false)
      if (data) {
        setWorkersCount(data.totalCount);
        setWorkers(data.data);
      }
    });
  }, [page, pageSize]); // Add 'page' and 'pageSize' as dependencies

  const handlePrevPageChange = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPageChange = () => {
    const maxPage = Math.ceil(workersCount / pageSize);
      setPage(page + 1);
  };

  const handleDeleteWorker = (workerId) => {
    // Remove the client from the clients array
    const updatedWorkers = workers.filter((worker) => worker.id !== workerId);
    setWorkers(updatedWorkers);
  };
  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 30, paddingRight: 10 }}>
          <Grid>
            <Grid.Column floated="left" width={5}>
              <h1>Worker Database</h1>
              <h3 style={{ color: "#044f66", marginTop: 10, fontSize: 20 }}>
                <FaBusinessTime /> In Record: {workersCount}
              </h3>
            </Grid.Column>
            <Grid.Column floated="right" width={7}>
              <div style={{ paddingTop: 20, display: 'flex' }}>
              <Link to={"/dashboard/holiday-requests"}>
                  <Button
                    style={{ backgroundColor: "rgb(238,174,202);" }}
                    content="View Holiday Requests"
                    labelPosition="right"
                    icon="list"
                  />
                </Link>

                <Link to={"/dashboard/workers/create"}>
                  <Button
                    color="primary"
                    content="ADD A NEW WORKER"
                    icon="plus"
                    labelPosition="right"
                  />
                </Link>
              </div>
            </Grid.Column>
          </Grid>
        <br /> <br />
          <Form loading={loading ? true : false}>
            <WorkersTable
                count={workersCount}
                workers={workers}
                onPrevPageChange={handlePrevPageChange}
                onNextPageChange={handleNextPageChange}
                onDeleteWorker={handleDeleteWorker}
            />
          </Form>
        </div>
      </AdminDashLayout>
    </>
  );
}

export default Workers;
