import { useEffect } from 'react';
import './App.css';
import AuthService from "./auth";
import Login from './pages/Login';
import {useNavigate} from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const isLoggedIn = AuthService.isLoggedIn();


  useEffect(() => {
    if(isLoggedIn) {
      return navigate("/dashboard");
    }
  }, [1])

  return (
      <Login />
  );
}

export default App;