import AdminDashLayout from "../components/AdminDashLayout";
import { FaBusinessTime } from "react-icons/fa6";
import { Grid, Button, Form, Card } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ClientsTable from "../components/ClientsTable";
import { useEffect, useState } from "react";
import { fetchClients } from "../apiCalls";

function Clients() {
    const [clientCount, setClientCount] = useState(0);
    const [clients, setClients] = useState([]);
    const [page, setPage] = useState(1); // Current page number
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(true);
    const [selectedClientType, setSelectedClientType] = useState(""); // State for selected client type

    const handleCardClick = (type) => {
        setSelectedClientType(type);
    };

    const filteredClients = clients.filter(client => client.client_type === selectedClientType);

    useEffect(() => {
        fetchClients(page, pageSize, localStorage.getItem("token")).then((data) => {
            setLoading(false)
            if (data) {
                setClientCount(data.totalCount);
                setClients(data.data);
            }
        });
    }, [page, pageSize]); // Add 'page' and 'pageSize' as dependencies

    const handlePrevPageChange = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPageChange = () => {
        const maxPage = Math.ceil(clientCount / pageSize);
        if (page < maxPage) {
            setPage(page + 1);
        }
    };

    const handleDeleteClient = (clientId) => {
        // Remove the client from the clients array
        const updatedClients = clients.filter((client) => client.id !== clientId);
        setClients(updatedClients);
    };

    const cardColors = ["#ede7f6", "#e8f5e9", "#e3f2fd", "#fbe9e7"];


    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 30, paddingRight: 10 }}>
                    <Grid>
                        <Grid.Column floated="left" width={7}>
                            <h1>Client Database</h1>
                            {selectedClientType && (
                                <>
                                    <h3 style={{ color: "#044f66", marginTop: 10, fontSize: 20 }}>
                                        <FaBusinessTime /> Clients: {filteredClients.length}
                                    </h3>
                                </>
                            )}
                        </Grid.Column>
                        <Grid.Column floated="right" width={4}>
                            <div style={{ paddingTop: 20, float: 'right' }}>
                                <Link to={"/dashboard/clients/create"}>
                                    <Button
                                        color="primary"
                                        content="ADD A NEW CLIENT"
                                        icon="plus"
                                        labelPosition="right"
                                    />
                                </Link>
                            </div>
                        </Grid.Column>
                    </Grid>
                    <br /> <br />
                    <Card.Group stackable itemsPerRow={2}>
                        <Grid columns={2} stackable>

                            <Grid.Column>
                                <Card
                                    key="big"
                                    style={{
                                        backgroundColor:
                                            cardColors[
                                            Math.floor(
                                                Math.random() *
                                                cardColors.length
                                            )
                                            ],
                                        padding: 25,
                                        marginBottom: 20,
                                        height: 200,
                                    }}
                                    raised
                                >

                                    <Card.Content>
                                        <Card.Description>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginTop: "0px",
                                                    cursor: "pointer"
                                                }}

                                                onClick={() => handleCardClick("big")}
                                            >
                                                <h2
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: 50,
                                                    }}
                                                >
                                                    Big Clients
                                                </h2>
                                            </div>
                                        </Card.Description>
                                    </Card.Content>

                                </Card>
                            </Grid.Column>

                            <Grid.Column>

                                <Grid.Column>
                                    <Card
                                        key="big"
                                        style={{
                                            backgroundColor:
                                                cardColors[
                                                Math.floor(
                                                    Math.random() *
                                                    cardColors.length
                                                )
                                                ],
                                            padding: 25,
                                            marginBottom: 20,
                                            height: 200,
                                        }}
                                        raised
                                    >

                                        <Card.Content>
                                            <Card.Description>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginTop: "0px",
                                                        cursor: "pointer"
                                                    }}

                                                    onClick={() => handleCardClick("small")}
                                                >
                                                    <h2
                                                        style={{
                                                            textAlign: "center",
                                                            paddingTop: 50,
                                                        }}
                                                    >
                                                        Small Clients
                                                    </h2>
                                                </div>
                                            </Card.Description>
                                        </Card.Content>

                                    </Card>
                                </Grid.Column>

                            </Grid.Column>
                        </Grid>

                    </Card.Group>
                    <br /> <br />
                    {selectedClientType && (
                        <>


                            <Form loading={loading ? true : false}>
                                <ClientsTable
                                    count={filteredClients.length}
                                    clients={filteredClients} // Pass filtered clients
                                    onPrevPageChange={handlePrevPageChange}
                                    onNextPageChange={handleNextPageChange}
                                    onDeleteClient={handleDeleteClient}
                                />
                            </Form>
                        </>
                    )}

                </div>
            </AdminDashLayout>
        </>
    );
}

export default Clients;
