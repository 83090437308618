import React, { useEffect, useState } from 'react';
import logo from "../assets/logo.png";
import "../App.css";
import { Container, Button } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { approveProjectBooking, cancelProjectBooking, fetchProject, rescheduleProjectBooking } from '../apiCalls';

function ConfirmBooking() {
    const { id } = useParams();

    const [showButtons, setShowButtons] = useState(true)
    const [showConfirmation, setShowConfirmation] = useState(true);
    const [showConfirmationText, setShowConfirmationText] = useState(false)
    const [showRescheduleForm, setShowRescheduleForm] = useState(false);
    const [project, setProject] = useState({})
    const [date, setDate] = useState(new Date())
    const [time, setTime] = useState(new Date().toTimeString())

    useEffect(() => {
        fetchProject(id, localStorage.getItem('token')).then(res => {
            //console.log(res.data[0])
            setProject(res.data[0])
        })

        if (project.projectClientNotificationStatus) {
            setShowButtons(false)
            setShowConfirmation(true)
            setShowConfirmationText("Your booking has been completed successfully.")
        }
    }, [])

    const handleAcceptBooking = async () => {
        setShowConfirmation(true)
        setShowConfirmationText("Your booking has been confirmed successfully.")
        setShowButtons(false)

        await approveProjectBooking(id)
    };

    const handleCloseRescheduleBooking = () => {
        setShowConfirmation(false);
        setShowRescheduleForm(false);
        setShowButtons(true);
    }

    const handleRescheduleBooking = () => {
        setShowConfirmation(false);
        setShowRescheduleForm(true);
        setShowButtons(false)
    };

    const handleCancelBooking = async () => {
        setShowButtons(false)
        setShowConfirmation(true)
        setShowConfirmationText("Your booking has been cancelled successfully.")

        await cancelProjectBooking(id)
    }

    const handleSubmit = async () => {
        setShowButtons(false)
        setShowConfirmation(true)
        setShowConfirmationText("Your booking has been rescheduled successfully!.")
        setShowRescheduleForm(false);

        await rescheduleProjectBooking(id, date, time)
    }

    return (
        <div className="App">
            <Container textAlign="center">
                <img src={logo} className="logo" alt="logo" />
                <br />
                <br />
            </Container>
            <Container>
             

                {showButtons && (
                    <div className="auth-box" style={{ background: 'transparent', color: '#000', textAlign: 'center' }}>
                        <h1 className="text-white">Confirm your booking, {project.clientName}.</h1>
                        <Button size="big" color="green" onClick={handleAcceptBooking}>Accept Booking</Button>
                        <br /><br />
                        <Button size="big" color="orange" onClick={handleRescheduleBooking}>Reschedule Booking</Button>
                        <br /><br />
                        <Button size="big" color="red" onClick={handleCancelBooking}>Cancel Booking</Button>
                    </div>
                )}

                {showConfirmation && (
                    <div className="auth-box" style={{ background: 'transparent', color: '#000', textAlign: 'center' }}>
                        <h1 className="text-white">{showConfirmationText}</h1>
                    </div>
                )}
                {showRescheduleForm && (
                    <div className="ui form">
                        <h4 className="ui header text-white text-center center-text" style={{ color: 'white', textAlign: 'center' }}>Rescheduling? Choose your preferred date and time:</h4>
                        {/* Add Date picker, Time picker, Submit button, and Cancel button here */}
                        <div className="field">
                            <label className='text-white' color='white' style={{ color: 'white'}}>Date</label>
                            <input type="date" onChange={(e) => setDate(e.target.value)} />
                        </div>
                        <div className="field">
                            <label style={{ color: 'white'}}>Time</label>
                            <input type="time" onChange={(e) => setTime(e.target.value + ' ' + (new Date().getHours() >= 12 ? 'AM' : 'PM'))} />
                        </div>
                        <Button className="ui black button" onClick={handleSubmit}>Submit</Button>
                        <Button className="ui red button" onClick={handleCloseRescheduleBooking}>Cancel</Button>
                    </div>
                )}
            </Container>
        </div>
    );
}

export default ConfirmBooking;