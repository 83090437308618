import React from "react";
import { Icon, Label, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatToBritishFormat } from "../utils";

function formatMonthYear(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("en-GB", { month: "long", year: "numeric" });
}

function VehicleLogTable({ reports }) {
  if (!Array.isArray(reports) || reports.length === 0) {
    return <div>No reports available</div>;
  }

  return (
    <>
      <Table basic="" size="large" striped celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>NUMBER PLATE</Table.HeaderCell>
            <Table.HeaderCell>ASSIGNED DRIVER</Table.HeaderCell>
            <Table.HeaderCell>CURRENT MILEAGE & DATE</Table.HeaderCell>
            <Table.HeaderCell>NEXT MOT DUE</Table.HeaderCell>
            <Table.HeaderCell>SERVICE DUE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reports.map((report, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                <Link to={"/dashboard/vehicle-log/view/" + report.id}>
                  <Button style={{ backgroundColor: "yellow", color: "black" }}>
                    {report.numberPlate}
                  </Button>
                </Link>
              </Table.Cell>
              <Table.Cell>{report.currentDriver}</Table.Cell>
              <Table.Cell>
                {report.mileage} miles - {formatToBritishFormat(report.created_at)}
              </Table.Cell>
              <Table.Cell>{formatMonthYear(report.motDue)}</Table.Cell>
              <Table.Cell>{formatMonthYear(report.nextService)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default VehicleLogTable;
