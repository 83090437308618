import logo from '../assets/logo.png';
import '../App.css';
import { Container } from 'semantic-ui-react';
function AuthPageLayout({ children }) {
    return (
        <div className="App">
            <Container textAlign='center'>
                <img src={logo} className="logo" alt="logo" />
                <br /><br />
            </Container>
            <Container>
                {children}
            </Container>
        </div>
    );
}

export default AuthPageLayout;