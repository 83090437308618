import React, { useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-modal";
import { List } from "semantic-ui-react";
import { updateImages, updateGrpImages } from "../apiCalls";
import ImageGallery from "react-image-gallery";

const Lightbox = ({ images, id = 0, type = 'project', column = 'photos' }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imagesD, setImagesD] = useState(images)

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const deleteImage = async (deleteImageIndex) => {
    // Check if deleteIndex is valid
    if (
        deleteImageIndex !== null &&
        deleteImageIndex >= 0 &&
        deleteImageIndex < images.length
    ) {
      const updatedImages = [...imagesD];
      updatedImages.splice(deleteImageIndex, 1); // Remove the schematic at deleteIndex
      setImagesD(updatedImages)

      switch(type) {
        case 'project':
          await updateImages(id, updatedImages)
          break;
        case 'grp':
          await updateGrpImages(id, updatedImages, column)
          //aot
      }
      
      // Update the state or call a function to update the parent component's state
      // For example: updateSchematics(id, updatedSchematics);

      // await updateSchematics(id, updatedSchematics);

      toast("Deleted");
    }

    // closeDeleteModal();
  };


  const generatedImages = images.map((image) => ({
    original: image,
    thumbnail: image,
  }));

  return (
    <>
      {imagesD.map((image, index) => (
        <>
          <img
            key={index}
            src={image}
            alt={`${index}`}
            style={{
              width: "180px",
              height: "auto",
              border: "1px solid #fff",
              borderRadius: "10px",
              margin: "5px",
              cursor: "pointer",
            }}
            onClick={() => openModal(index)}
          />
 <a href="javascript:;" onClick={() => deleteImage(index)}>
          <List.Icon style={{ color: "red" }} name="trash" />
        </a>{" "}
        </>
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}

        basic
        
      >
        <button
          onClick={closeModal}
          style={{ position: "absolute", top: 2, right: 10 }}
        >
          Close
        </button>
       
        <center>

          <ImageGallery items={generatedImages} startIndex={selectedImageIndex} />
        </center>
      </Modal>
    </>
  );
};

export default Lightbox;
