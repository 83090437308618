import Avatar from "react-avatar";
import React from "react";

function UserAvatar({worker, size = 35, style = null}) {
    const imgSize = size + 'px';
    return (
        <>
            {!worker?.staff_photo_uri && (
                <Avatar name={worker?.firstname} round={true} size={size} style={style} />
            )}

            {worker?.staff_photo_uri && (
                <img src={worker.staff_photo_uri} alt={"loading"} width={imgSize} style={{ borderRadius: '88px', marginBottom: "-10px"}} />
            )}
        </>
    )
}

export { UserAvatar }