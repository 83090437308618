import React from "react";
import { Button, Card, Grid, Icon, Table } from "semantic-ui-react";
import { FaCheckCircle, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatToBritishFormat, projectsData } from "../utils";
import PipekitAssignedto from "./PipekitAssignedTo";

const cardColors = ["#ede7f6", "#e8f5e9", "#e3f2fd", "#fbe9e7"];

const PipekitCards = ({ projectsData, onPrevPageChange, onNextPageChange, canPaginate }) => (
    
    <><Card.Group itemsPerRow={2} stackable>
        {projectsData.map((project) => (
            <Card key={project.id} style={{
                backgroundColor:
                    cardColors[Math.floor(Math.random() * cardColors.length)],
                padding: 25, paddingRight: "45px"
            }} raised>
                <Link to={`/dashboard/pipekits/view/${project.id}`}
                    style={{ textDecoration: 'none', textAlign: 'justify', color: 'inherit' }}>
                    <Card.Content>
                        <Grid>
                            <Grid.Column floated="left" width={10}>
                                <Card.Header>
                                    <h2>
                                        Pipekit {project.advecoNumber}
                                    
                                        {(project.installConfirm === "YES") && (
                                            <>
                                                <FaCheckCircle color={"green"} style={{ marginLeft: 10 }} />
                                            </>
                                        )}
                                    </h2>
                                </Card.Header>
                            </Grid.Column>
                        </Grid>
                        <Card.Description>
                            <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                                <PipekitAssignedto peopleAssigned={project.assignedTo} />
                            </div>
                        </Card.Description>
                    </Card.Content>
                </Link>
            </Card>
        ))}
    </Card.Group>
        <br />
        {canPaginate === true && (
            <>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3">
                            <Button floated="left" icon color="primary" labelPosition="left" onClick={onPrevPageChange}>
                                Previous Page
                                <Icon name="left arrow" />
                            </Button>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="7">
                            <Button floated="right" icon color="primary" labelPosition="right" onClick={onNextPageChange}>
                                Next Page
                                <Icon name="right arrow" />
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </>
        )}
    </>

);

export default PipekitCards;
