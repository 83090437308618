import React, {useEffect, useMemo, useState} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Form, Loader, Message} from 'semantic-ui-react';
import {fetchCalEvents, fetchProjects} from '../apiCalls'; // Import your API function here
import {useSwipeable} from 'react-swipeable'; // Import the Swipeable component
import 'moment/locale/en-gb'; // Import the locale data for en-GB
// import ICalendarLink from "react-icalendar-link";

const localizer = momentLocalizer(moment);

const events = [];

const generateEventsFromProject = (project) => {

    const localEvents = []

    const assignedToUsers = project.assignedToUserData
        .filter((user) => user !== null)
        .map((user) => user.firstname);

    if (project.projectMilestones) {

        if (project.projectMilestones.length > 0) {
            project.projectMilestones.forEach((milestone) => {
    
                const milestoneAssignedToUsers = milestone.assignedTo
                    .map((userId) => {
                        const user = project.staffs.find((u) => u.id === userId);
                        return user ? user.firstname : '';
                    });
    
                    const assignedToUsers = project.assignedToUserData
                        .filter((user) => user !== null)
                        .map((user) => user.firstname);
    
                localEvents.push({
                    start: new Date(milestone.startDate),
                    end: new Date(milestone.endDate),
                    title: `[${project.projectName} ${milestone.visitName}] Assigned to: ${milestoneAssignedToUsers.join(', ')}`,
                    url: '/dashboard/projects/view/' + project.id
                });
            });
        }
        
    } else {
       
        localEvents.push({
            start: new Date(project.projectStartDate),
            end: new Date(project.projectStartDate),
            title: `${project.projectName} Assigned to: ${assignedToUsers.join(', ')}`,
            url: '/dashboard/projects/view/' + project.id
        });

    }

    return localEvents;
};

const generateEventsFromCalEvents = (data) => {
    const localEvents = []

    localEvents.push({
        start: new Date(data.start),
        end: new Date(data.end),
        title: data.title,
        url: data.link || '/'
    });

    return localEvents;
}

const handleEventClick = (event, e) => {
    // Handle the event click here, e.g., open a link
    window.open(event.url); // Open the link in a new tab
};

const CalendarComponent = ({ selectedMonth, selectedYear, updateMonthAndYear }) => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentDate = useMemo(() => moment(`${selectedYear}-${selectedMonth}-01`), [
        selectedMonth,
        selectedYear,
    ]);

    useEffect(() => {
        // Fetch data whenever selectedMonth or selectedYear changes
        const fetchData = async () => {
            try {
                const projectsData = await fetchProjects(0, 0, localStorage.getItem('token')); // Adjust page and pageSize as needed
                const calEventsData = await fetchCalEvents(0, 0, localStorage.getItem('token')); // Adjust page and pageSize as needed

                //console.log(projectsData)

                setLoading(false);

                // Generate events from the fetched projects and calendar events
                const projectsEventsList = projectsData.data.map((project) => generateEventsFromProject(project));
                const calEventsList = calEventsData.data.map((event) => generateEventsFromCalEvents(event));

                console.log(calEventsList)

                const allEvents = [...projectsEventsList, ...calEventsList];

                const tEvents = []

                allEvents.forEach((e) => {
                    if(e.length > 0) {
                        e.forEach((d) => {
                         
                            tEvents.push(d)
                        })
                    }
                })
                
                setEvents(tEvents);
                

            } catch (error) {
                setError(error.message || 'An error occurred while fetching data.');
                setLoading(false);
            }
        };
    
        fetchData();
    }, [selectedMonth, selectedYear]); // Add selectedMonth and selectedYear to the dependency array

    const handleSwipe = (direction) => {
        if (direction === 'left') {
            const nextMonth = currentDate.clone().add(1, 'months');
            updateMonthAndYear(nextMonth.format('MMMM'), nextMonth.year());
        } else if (direction === 'right') {
            const prevMonth = currentDate.clone().subtract(1, 'months');
            updateMonthAndYear(prevMonth.format('MMMM'), prevMonth.year());
        }
    };

    // Add onNavigate prop to update the parent component's state
    const handleNavigate = (newDate) => {
        updateMonthAndYear(moment(newDate).format('MMMM'), moment(newDate).year());
    };

    const handleSwipes = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    // const eventiCal = [{
    //     title: "My Title",
    //     description: "My Description",
    //     startTime: "2018-10-07T10:30:00+10:00",
    //     endTime: "2018-10-07T12:00:00+10:00",
    //     location: "10 Carlotta St, Artarmon NSW 2064, Australia",
    //     attendees: [
    //       "Hello World <hello@world.com>",
    //       "Hey <hey@test.com>",
    //     ]
    // }]

    return (
        <div>
            <Form loading={loading}>
                {error && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>{error}</p>
                    </Message>
                )}

                {loading ? (
                    <Loader>Loading</Loader>
                ) : (
                    <>
                        {/* Render the Calendar */}
                        <div {...handleSwipes}>
                        {/* <ICalendarLink event={eventiCal}
        Add to Calendar
      </ICalendarLink> */}
                            <Calendar
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                culture='en-GB'
                                key={`${selectedYear}-${selectedMonth}`} // Use selectedMonth and selectedYear as the key
                                defaultDate={moment().toDate()}
                                onSelectEvent={(event, e) => {
                                    handleEventClick(event, e); // Handle the event click
                                }}

                                onNavigate={handleSwipe} // Listen for navigation changes
                                weekStartsOn={1} // Start the week on Monday
                                // min={moment().subtract(visibleMonths, 'months').toDate()}
                                // max={moment().add(visibleMonths, 'months').toDate()}
                            />
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
};

export default CalendarComponent;