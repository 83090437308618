import React from 'react';
import Avatar from 'react-avatar';

const ViewPipekitAssignedTo = ({ peopleAssigned, viewProject = false }) => {
    const MAX_NAMES_TO_DISPLAY = 3;

    const marginBottom = viewProject === true ? '-9px' : '0px';

    if (!peopleAssigned || peopleAssigned.length === 0) {
        return <div>No one assigned</div>;
    }

    // Filter out null or undefined users
    const validPeople = peopleAssigned.filter(person => person);

    if (validPeople.length === 0) {
        return <div>No one assigned</div>;
    }

    const [firstPerson, ...restOfPeople] = validPeople;

    let displayText = '';

    if (restOfPeople.length === 0) {
        displayText = firstPerson.firstname || '';
    } else if (restOfPeople.length === 1) {
        displayText = `${firstPerson.firstname || ''} and ${restOfPeople[0].firstname || ''}`;
    } else if (restOfPeople.length > 1) {
        const numberOfOthers = restOfPeople.length;
        displayText = `${firstPerson.firstname || ''} and ${numberOfOthers} others`;
    }

    const avatars = validPeople.map((person, index) => (
        <div key={index} style={{ display: 'inline-block', marginLeft: index === 0 ? '0px' : '-5px' }}>
            {person.staff_photo_uri ? (
                <img
                    src={person.staff_photo_uri}
                    alt="no image"
                    width={35}
                   
                    style={{ borderRadius: '100px', marginBottom: marginBottom }}
                />
            ) : (
                <Avatar name={person.firstname} size={35} round={true} />
            )}
        </div>
    ));

    return (
        <div style={{ paddingTop: 10, paddingBottom: 5, fontSize: validPeople.length === 1 ? 16 : 18 }}>
            <b>Assigned To: </b>
            {avatars}
            &nbsp;&nbsp;
            <span style={{ fontSize: validPeople.length === 1 ? 16 : 18 }}>{displayText}</span>
        </div>
    );
};

export default ViewPipekitAssignedTo;
