import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Breadcrumb, Message, Dropdown, Checkbox, Input } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import sendApiRequest from "../api";
import AuthService from "../auth";
import { formatToYYYYMMDD } from "../utils";
import { toast } from "react-toastify";

const RequestAttendance = () => {
    const user = AuthService.getCurrentUser();
    const { id } = useParams();

    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const [urgency, setUrgency] = useState('not_urgent');
    const [staffs, setStaffs] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [reason, setReason] = useState('');
    const [sendClientNotification, setSendClientNotification] = useState(false);
    const [clientPhoneNumber, setClientPhoneNumber] = useState('+44');

    const [apiErrors, setApiErrors] = useState(null);
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");

    useEffect(() => {
        const fetchProjectAndStaffs = async () => {
            try {
                const [projectRes, staffsRes] = await Promise.all([
                    sendApiRequest("get", `/projects/fetch?id=${id}`, localStorage.getItem("token")),
                    sendApiRequest("get", "/workers", localStorage.getItem("token"))
                ]);

                setProject(projectRes.data[0]);

                const staffData = staffsRes.data
                    .filter((worker) => worker.role === 2 || worker.role === 1)
                    .map((worker) => ({
                        key: worker.id,
                        text: worker.firstname,
                        value: worker.firstname,
                    }));

                setStaffs(staffData);
            } catch (error) {
                console.error("Error fetching data:", error);
                setApiErrors(true);
                setApiErrorMessage("Failed to load project and staff data.");
            }
        };

        fetchProjectAndStaffs();
    }, [id]);

    const handleSubmit = async () => {
        setErrors({});
        setApiErrors(null);
        setApiErrorMessage("");
        setApiSuccess(false);
        setApiSuccessMessage("");

        if (!reason) {
            setErrors({ reason: "Please enter a reason for your attendance request." });
            return;
        }

        if (!selectedStaff) {
            setErrors({ staff: "Please select a staff member." });
            return;
        }

        setLoading(true);

        const formData = {
            id: project.id,
            reason: reason,
            date: formatToYYYYMMDD(date),
            time: time.toTimeString().split(' ')[0],
            priority: urgency,
            staffs: selectedStaff,
            status: 'PENDING',
            sendNotif: sendClientNotification,
            clientPhoneNumber: sendClientNotification ? clientPhoneNumber : null,
            projName: project.projectName,
        };

        try {
            await sendApiRequest(
                "post",
                "/projects/attendance_requests/create",
                formData,
                localStorage.getItem("token")
            );

            setApiSuccess(true);
            setApiSuccessMessage("Attendance request sent successfully. A staff will be in touch shortly.");
            toast.success("Attendance request sent successfully. A staff will be in touch shortly");
        } catch (error) {
            console.error(error);
            setApiErrors(true);
            setApiErrorMessage("Failed to submit attendance request. Please try again later.");
            toast.error("Failed to submit request.");
        } finally {
            setLoading(false);
        }
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button type="button" className="custom-input" onClick={onClick} ref={ref}
            style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }}>
            {value}
        </button>
    ));

    return (
        <AdminDashLayout>
            <div style={{ paddingTop: 40 }}>
                <Breadcrumb>
                    <Link to="/dashboard"><Breadcrumb.Section link>Dashboard</Breadcrumb.Section></Link>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Link to="/dashboard/projects"><Breadcrumb.Section link>Projects</Breadcrumb.Section></Link>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Link to={"/dashboard/projects/view/" + project?.id}><Breadcrumb.Section link>{project?.projectName}</Breadcrumb.Section></Link>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>Request Attendance</Breadcrumb.Section>
                </Breadcrumb>
            </div>
            <Form loading={loading}>
                {apiErrors && <Message error header="Error" content={apiErrorMessage} />}
                {apiSuccess && <Message success header="Success" content={apiSuccessMessage} />}
                <br />
                <Form.Field>
                    <label>Pick a Date</label>
                    <DatePicker
                        selected={date}
                        onChange={(date) => setDate(date)}
                        customInput={<CustomInput />}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Priority</label>
                    <Form.Group inline>
                        <Form.Radio
                            label='Urgent'
                            value='urgent'
                            checked={urgency === 'urgent'}
                            onChange={() => setUrgency('urgent')}
                        />
                        <Form.Radio
                            label='Not Urgent'
                            value='not_urgent'
                            checked={urgency === 'not_urgent'}
                            onChange={() => setUrgency('not_urgent')}
                        />
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <label>Pick a Time</label>
                    <DatePicker
                        selected={time}
                        onChange={(time) => setTime(time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        customInput={<CustomInput />}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Select Staff</label>
                    <Dropdown
                        placeholder='Select Staff'
                        fluid
                        selection
                        multiple
                        options={staffs}
                        onChange={(_, { value }) => setSelectedStaff(value)}
                        error={errors.staff}
                    />
                </Form.Field>
                <Form.TextArea
                    label='Reason'
                    placeholder='Enter reason for attendance request...'
                    value={reason}
                    onChange={(e, { value }) => setReason(value)}
                    error={errors.reason}
                />

            
                <div className="faint-teal" style={{ marginTop: '20px', padding: '15px', backgroundColor: '#e6f3f5', borderRadius: '5px' }}>
                    <Form.Field>
                        <Checkbox
                            toggle
                            label="Get SMS Notification?"
                            checked={sendClientNotification}
                            onChange={() => setSendClientNotification(!sendClientNotification)}
                        />
                    </Form.Field>
                    {sendClientNotification && (
                        <Form.Field>
                            <Input
                                fluid
                                type="tel"
                                label="Phone Number"
                                placeholder="+44"
                                value={clientPhoneNumber}
                                onChange={(e) => setClientPhoneNumber(e.target.value)}
                            />
                        </Form.Field>
                    )}
                </div>
                <br />
                <Button
                    primary
                    icon="send"
                    labelPosition="right"
                    content="Submit Request"
                    onClick={handleSubmit}
                />
            </Form>
        </AdminDashLayout>
    );
};

export default RequestAttendance;

