import logo from "../assets/logo.png";
import "../App.css";
import { Link } from "react-router-dom";
import { Container, Divider } from "semantic-ui-react";
import { Button, Form } from "semantic-ui-react";
import { useState } from "react";
import { emailRegex } from "../utils";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });

  const handleSubmit = () => {
    setLoading(true);

    const formData = {
      email: email
    };

    const newErrors = {};

    if (formData.email === "") {
      newErrors.email = "Please enter your email address";
    } else {
      if (!emailRegex.test(email)) {
        newErrors.email = "Please enter a valid email address";
      }
    }

    setErrors(newErrors);

    console.log(formData);

    setLoading(false);

    
  };

  return (
    <div className="App">
      <Container textAlign="center">
        <img src={logo} className="logo" alt="logo" />
        <br />
        <br />
      </Container>
      <Container>
        <div className="auth-box">
          <Form>
            <Form.Input
              name="email"
              label="Email address"
              icon="mail"
              iconPosition="left"
              placeholder="jane@domain.com"
              value={email}
              required={true}
              type="email"
              error={errors.email ? { content: errors.email } : null}
              onChange={(e) => {
                errors.email = null;
                setEmail(e.target.value);
              }}
            />
            {loading ? (
              <Button fluid primary loading>
                Submit
              </Button>
            ) : (
              <Button fluid primary onClick={handleSubmit}>
                Submit
              </Button>
            )}
            <Divider />
            <Link to={"/"}>
              <Button fluid color="secondary">
                Go back
              </Button>
            </Link>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default ForgotPassword;
