import React, {useState} from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {Button, Form, Grid, Breadcrumb, Message} from "semantic-ui-react";
import {Link, useNavigate} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {emailRegex, phoneRegex} from "../utils";
import sendApiRequest from "../api";
import toast from "react-hot-toast";

const CreateClient = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [clientType, setClientType] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState({email: "", password: ""});
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            name: name,
            address: address,
            email: email,
            phone: phone,
            client_type: clientType,
            password: password
        };

        let newErrors;
        newErrors = {};

        if (formData.name !== "") {

        } else {
            newErrors.name = "Please enter the clients name";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        if (formData.email !== "") {
            if (!emailRegex.test(email)) {
                newErrors.email = "Please enter a valid email address";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        } else {
            newErrors.email = "Please enter the clients email address";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        if (formData.phone !== "") {
            if (!phoneRegex.test(formData.phone)) {
                newErrors.phone = "Please a valid phone number";
                setErrors(newErrors);
                setApiSuccess(false);
                setLoading(false);
                return;
            }
        } else {
            newErrors.phone = "Please enter the clients phone number";
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        // Login request
        (async () => {
            const postData = await sendApiRequest("post", "/clients/create", formData, localStorage.getItem('token'));
            if (postData !== null) {
                // console.log(postData);
                // any errors?
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    const errors = postData.errors;
                    const errorMsg = [];

                    if (postData.errors) {
                        errors.forEach((e) => {
                            errorMsg.push(e.msg);
                        });
                    } else {
                        errorMsg.push(postData.error);
                    }

                    setApiErrorMessage(errorMsg);
                }

                // if we got here, no errors.
                if (postData.message == "success") {
                    setApiSuccess(true);
                    setApiSuccessMessage("Client Record Created Successfully");
                    toast("Client Record Created Successfully")
                    setName('')
                    setAddress('')
                    setEmail('')
                    setPhone('')
                }


            } else {

                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                toast("Error occurred while submitting data to the API.")
                setApiErrors(true)
                setApiSuccess(false);
                setLoading(false);

                // console.log("Error occurred while submitting data to the API.");
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            <AdminDashLayout>
                <div style={{paddingTop: 40}}>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right chevron"/>
                        <Link to={"/dashboard/clients"}>
                            <Breadcrumb.Section link>Clients</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow"/>
                        <Breadcrumb.Section>Create Client</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
                <div style={{paddingTop: 30, paddingRight: 20}}>
                    <Grid>
                        <Grid.Column width={8} floated="left">
                            {apiErrors ? (
                                <Message
                                    error
                                    header="Whoops!"
                                    list={apiErrorMessage}
                                />
                            ) : null}

                            {apiSuccess ? (
                                <Message
                                    success
                                    header="Success!"
                                    content={apiSuccessMessage}
                                />
                            ) : null}
                            <Form loading={loading ? true : false}>
                                
                                <Form.Select
                                    size="medium"
                                    label="Client Type"
                                    placeholder="Select Client Type"
                                    value={clientType}
                                    required={true}
                                    error={errors.clientType ? {content: errors.clientType} : null}
                                    onChange={(e, {value}) => {
                                        errors.clientType = null;
                                        setClientType(value);
                                    }}
                                    options={[
                                        { key: 'small', text: 'Small Client', value: 'small' },
                                        { key: 'big', text: 'Big Client', value: 'big' },
                                        // Add more options as needed
                                    ]}
                                />

                                <Form.Input
                                    size="medium"
                                    label="Client Name"
                                    placeholder="Enter Client Name"
                                    value={name}
                                    required={true}
                                    error={errors.name ? {content: errors.name} : null}
                                    onChange={(e) => {
                                        errors.name = null;
                                        setName(e.target.value);
                                    }}
                                />
                                <Form.Input
                                    size="medium"
                                    label="Client Address"
                                    placeholder="Enter Client Address"
                                    value={address}
                                    required={true}
                                    error={errors.address ? {content: errors.address} : null}
                                    onChange={(e) => {
                                        errors.address = null;
                                        setAddress(e.target.value);
                                    }}
                                />
                                <Form.Input
                                    size="medium"
                                    label="Client Email"
                                    placeholder="Enter Client Email"
                                    value={email}
                                    required={true}
                                    error={errors.email ? {content: errors.email} : null}
                                    onChange={(e) => {
                                        errors.email = null;
                                        setEmail(e.target.value);
                                    }}
                                />

                                <Form.Input
                                    size="medium"
                                    label="Client Phone Number"
                                    placeholder="Enter Client Phone Number"
                                    value={phone}
                                    required={true}
                                    error={errors.phone ? {content: errors.phone} : null}
                                    onChange={(e) => {
                                        errors.phone = null;
                                        setPhone(e.target.value);
                                    }}
                                />

                                <Form.Input
                                    size="medium"
                                    label="Password"
                                    placeholder="Enter Client Password"
                                    type="password"
                                    value={password}
                                    required={true}
                                    error={errors.phone ? {content: errors.phone} : null}
                                    onChange={(e) => {
                                        errors.phone = null;
                                        setPassword(e.target.value);
                                    }}
                                />

                                <Button
                                    size="midi"
                                    color="primary"
                                    content="Create Client"
                                    icon="plus"
                                    labelPosition="right"
                                    onClick={handleSubmit}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </AdminDashLayout>
        </>
    );
};

export default CreateClient;
