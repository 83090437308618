import logo from "../assets/logo.png";
import "../App.css";
import { Container } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
function Inactive() {
  return (
    <div className="App">
      <Container textAlign="center">
        <img src={logo} className="logo" alt="logo" />
        <br />
        <br />
      </Container>
      <Container>
        <div className="auth-box" style={{ color: '#000', textAlign: 'center' }}>
          <h4>Your session has expired. This is a security feature.</h4>
          <a href='/login'><Button fluid color="primary">Login here</Button></a>
        </div>
      </Container>
    </div>
  );
}

export default Inactive;
