import { Form, Grid, Checkbox, Button, TextArea } from "semantic-ui-react";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import sendApiRequest from "../api";
import { toast } from "react-toastify";
import { formatToYYYYMMDD } from "../utils"

const CreateInvoiceModalComponent = ({ defaultData, onSubmitFromParent }) => {
  const [formData, setFormData] = useState({
    amount: ""
  });

  const id = defaultData.id;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmitFromParent = async () => {
    console.log(formData);

    const data = {
      "projectInvoiceMeta": {
        "estimate": formData.amount,
        "logs": []
      }
    }

    console.log(data)

    const r = await sendApiRequest('post', '/projects/edit/' + id, data);
    console.log(r)

    toast("Project Invoice Created")

    onSubmitFromParent()
  };

  return (
    <div style={{ overflow: "scroll", height: "auto" }}>
      <div
        className={"grp-view-page-further-checks-card"}
        style={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <span className={"primary-color"} style={{ fontSize: 18 }}>
          Project Estimate
        </span>
        <br />
        <br />
        <Grid>
          <Grid.Column width={5}>
            <Form.Input
              size="medium"
              label="Estimate"
              name="applianceType"
              style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
              placeholder="Enter Cost"
              value={formData.amount || ""}
              onChange={(e) =>
                handleChange(
                  { target: { name: "amount", value: e.target.value } },
                  "projectInvoiceMeta"
                )
              }
            />
          </Grid.Column>
        </Grid>
      </div>
      <br />
      <Button color="primary" onClick={handleSubmitFromParent}>
        Save Invoice
      </Button>
    </div>
  );
};

export default CreateInvoiceModalComponent;
