import React from "react";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";

const DashCards = ({
  totalProjectsCount = 0,
  totalHoursLogged = 0,
  totalCompletedProjectsCount = 0,
  role = 1,
}) => (
  <>
    {role === 1 && (
      <>
        <Card.Group stackable itemsPerRow={3}>
          <Card loading style={{ backgroundColor: "#b3e5fc", padding: 10 }}>
            <Link to={'/dashboard/projects'} style={{ padding: "1em 1em" }}>
            <Card.Content>
              <Card.Header style={{ fontSize: "1.3em" }}>TOTAL PROJECTS</Card.Header>
              <Card.Description>
                <h1 style={{ fontWeight: "bold", fontSize: 40 }}>
                  {totalProjectsCount}
                </h1>
              </Card.Description>
            </Card.Content>
            </Link>
          </Card>
          <Card style={{ backgroundColor: "#ffccbc", padding: 10 }}>
          <Link to={'/dashboard/timesheet'} style={{ padding: "1em 1em" }}>
          <Card.Content>
              <Card.Header style={{ fontSize: "1.3em" }}>HOURS LOGGED</Card.Header>
              <Card.Description>
                <h1 style={{ fontWeight: "bold", fontSize: 40 }}>
                  {totalHoursLogged}
                </h1>
              </Card.Description>
            </Card.Content>
          </Link>
            
          </Card>
          <Card style={{ backgroundColor: "#b2dfdb", padding: 10 }}>
          <Link to={'/dashboard/projects'} style={{ padding: "1em 1em" }}>
          <Card.Content>
              <Card.Header style={{ fontSize: "1.3em" }}>COMPLETED PROJECTS</Card.Header>
              <Card.Description>
                <h1 style={{ fontWeight: "bold", fontSize: 40 }}>
                  {totalCompletedProjectsCount}
                </h1>
              </Card.Description>
            </Card.Content>
          </Link>
            
          </Card>
        </Card.Group>
      </>
    )}

    {role === 2 && (
      <>
        <Card.Group stackable itemsPerRow={2}>
          <Card loading style={{ backgroundColor: "#b3e5fc", padding: 10 }}>
            <Card.Content>
              <Card.Header>TOTAL PROJECTS</Card.Header>
              <Card.Description>
                <h1 style={{ fontWeight: "bold", fontSize: 40 }}>
                  {totalProjectsCount}
                </h1>
              </Card.Description>
            </Card.Content>
          </Card>

          <Card style={{ backgroundColor: "#b2dfdb", padding: 10 }}>
            <Card.Content>
              <Card.Header>COMPLETED PROJECTS</Card.Header>
              <Card.Description>
                <h1 style={{ fontWeight: "bold", fontSize: 40 }}>
                  {totalCompletedProjectsCount}
                </h1>
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </>
    )}
  </>
);

export default DashCards;
