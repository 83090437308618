import React from "react";
import { Header, Table, Label, Grid, Message } from "semantic-ui-react";

const StatusComponent = ({ data }) => {

  function highlightCondition(condition) {
    const highlightedCondition = condition.replace(
      /good/g,
      '<b style="color: orange;">good</b>'
    );
    return highlightedCondition.replace(
      /excellent/g,
      '<b style="color: green;">excellent</b>'
    );
  }

  return (
    <div>
      <h2>Vehicle Status</h2>
      <h4>
        Vehicle Registration: <b>{data.vehicle.registration}</b> &nbsp; &nbsp;
        Mileage: <b>{data.vehicle.mileage}</b>
      </h4>
      <ul
        className="timesheet-table"
        style={{ marginLeft: "-40px", paddingBottom: "0px" }}
      >
        {data.vehicle.items &&
          data.vehicle.items.map((item, index) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{
                __html: `${item.name} is in ${highlightCondition(item.condition)} condition`,
              }}
            ></li>
          ))}
      </ul>

      <h2>Damage To Company Tools</h2>
      <p>{data.damageToTools}</p>
      <br />
      <h2>Further Status / PPE Checks</h2>
      <h4 className={"primary-color"}>is PPE equipment in good order?</h4>
      <ul
        className="timesheet-table"
        style={{ marginLeft: "-40px", paddingBottom: "0px" }}
      >
        {data.ppeChecks &&
          data.ppeChecks.items.map((item, index) => (
            <li key={index}>
              {item.name} <Label color={item.color}>{item.status}</Label>
            </li>
          ))}
      </ul>
      <b className="primary-color">
        If there is a NO in any of the items above, notify the OFFICE
        immediately for replacement(s).
      </b>
      <br />
      <br />
      <br />
      <Grid>
        <Grid.Column floated="left" width={10}></Grid.Column>
        <Grid.Column floated="right" width={3}>
          <h3>Signed: {data.signature !== "" && <img src={data.signature} alt="staff signature"/>}</h3>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default StatusComponent;
