import React from 'react';
import {BsFillChatLeftTextFill} from 'react-icons/bs';

const MessageIcon = ({ messageCount }) => {
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <BsFillChatLeftTextFill
                size={30}
                color="#044f66"
                style={{ paddingRight: 10 }}
            />
            {messageCount > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: -5,
                        right: -5,
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 12,
                        color: 'white',
                    }}
                >
                    {messageCount}
                </div>
            )}
        </div>
    );
};

export default MessageIcon;
