import React from "react";
import { Icon, Label, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatToBritishFormat, formatM } from "../utils";

function formatMonthYear(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString("en-GB", { month: "long", year: "numeric" });
}

function ProjectInvoiceLogsTable({ reports }) {
  if (!Array.isArray(reports) || reports.length === 0) {
    return <div></div>;
  }

  return (
    <>
      <Table basic="" size="large" striped celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>DATE</Table.HeaderCell>
            <Table.HeaderCell>MERCHANT</Table.HeaderCell>
            <Table.HeaderCell>COST</Table.HeaderCell>
            <Table.HeaderCell>COST (incl. VAT)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reports.map((report, index) => (
            <Table.Row key={index}>                
              <Table.Cell>{formatToBritishFormat(report.date)}</Table.Cell>
              <Table.Cell>
                {report.merchant}
              </Table.Cell>
              <Table.Cell>£{formatM(report.cost)}</Table.Cell>
              <Table.Cell>£{formatM(report.costVat)}</Table.Cell>
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>Total Spend: £{formatM(reports.reduce((total, report) => total + Number(report.costVat), 0))}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

export default ProjectInvoiceLogsTable;
