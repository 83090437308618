import React from "react";
import { List } from "semantic-ui-react";
import { FaClock } from "react-icons/fa";
import moment from "moment";

const ProjectMilestones = ({ projectMilestones }) => {
  if (!projectMilestones || projectMilestones.length === 0) {
    return <div>No milestones available</div>;
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 10 }}>
      <List divided relaxed>
        {projectMilestones.map((milestone, index) => (
          <List.Item key={index}>
            <List.Content>
              <List.Header style={{ paddingBottom: 5 }}>
                <big style={{ marginBottom: "10px" }}>
                  {milestone.visitName}
                </big>{" "}
                <br />
                <small>
                  <FaClock /> {moment(milestone.startDate).format("DD/MM/YYYY")}{" "}
                  to {moment(milestone.endDate).format("DD/MM/YYYY")}
                </small>
              </List.Header>
              <List.Description>
                <span className="primary-color">Assigned To:</span>{" "}
                {milestone.assignedTo.length === 1 ? (
                  <span>{milestone.assignedTo[0]?.firstname}</span>
                ) : (
                  milestone.assignedTo.map((user, userIndex) => (
                    <span key={userIndex}>
                      {user?.firstname}
                      {userIndex < milestone.assignedTo.length - 1 ? ", " : ""}
                    </span>
                  ))
                )}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default ProjectMilestones;
