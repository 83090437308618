import Sidenav from "./SideNav";
import TopNav from "./TopNav";
import {Link, ScrollRestoration} from "react-router-dom";
import React, {useState} from "react";
import logo from "../assets/logo.png";
import { ToastContainer } from 'react-toastify';
import Auth from "../auth";

function AdminDashLayout({children}) {
    const [navOpen, setNavOpen] = useState(false);

    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    const user = Auth.getCurrentUser();

    if (user.role === undefined) {
        return window.location = '/login'
    }

    return (
        <>
            {navOpen && <div className="dim-overlay" onClick={toggleNav} />}
            <Sidenav navOpen={navOpen} toggleNav={toggleNav}/>
            <div className="App-dash">
                <main className="dash-main">
                    <div className={"hidden-desktops"}>
                        <div>
                            <center>
                                <div className="header-container">
                                    <div className="header-content">
                                        <div className="header-button">
                                            <button
                                                className="primary"
                                                onClick={toggleNav}
                                                aria-label="Toggle Navigation"
                                            >
                                                Menu
                                            </button>
                                        </div>
                                        <div className="header-logo">
                                            <img src={logo} alt="logo" />
                                        </div>
                                        <div className="header-button">
                                            <Link to={"/logout"}>
                                                <button
                                                    className="primary"
                                                    onClick={toggleNav}
                                                    aria-label="Toggle Navigation"
                                                >
                                                    Logout
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </center>
                            <br />
                        </div>
                    </div>
                    <TopNav/>
                    {children}
                </main>
            </div>
            <ToastContainer />
            <ScrollRestoration/>
        </>
    );
}

export default AdminDashLayout;
