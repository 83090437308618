function WorkerRoleName({role}) {
    switch (role) {
        case 1:
            return <span>Admin</span>
            break;
        case 2:
            return <span>Staff</span>
            break;
        case 3:
            return <span>GRP: Non-Staff</span>
            break;
        case 4:
            return <span>GRP: Electrician</span>
            break;
        default:
            return <span></span>
    }
}

export { WorkerRoleName }