import { FaCheckCircle } from "react-icons/fa";
import React from "react";

function ProjectJobReport({ projectJobReport }) {
    if (!projectJobReport) {
        // Handle the case when projectJobReport is null or undefined
        return <div>No data available.</div>;
    }

    const {
        applianceDetails,
        partsRequired,
        worksCarriedOut,
        safety,
        office,
        merchants,
        orderDate,
        contact,
        signed,
    } = projectJobReport;

    return (
        <div style={{ overflow: "scroll", height: "550px" }}>
            <div className={"grp-view-page-further-checks-card"} style={{ borderRadius: '10px', padding: '20px' }}>
                <span className={"primary-color"} style={{ fontSize: 18 }}><u>Appliance Details</u></span>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Appliance Type</span>
                <br />
                <b>{applianceDetails.applianceType}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Make</span>
                <br />
                <b>{applianceDetails.make}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Model No</span>
                <br />
                <b>{applianceDetails.modelNo}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>KW</span>
                <br />
                <b>{applianceDetails.KW}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Serial No</span>
                <br />
                <b>{applianceDetails.serialNo}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>GC No</span>
                <br />
                <b>{applianceDetails.GCNo}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Fuel</span>
                <br />
                <b>{applianceDetails.fuel}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Condition</span>
                <br />
                <b>{applianceDetails.condition}</b>
            </div>
            <br />
            <div className={"grp-view-page-further-checks-card"}>
                <span className={"primary-color"} style={{ fontSize: 18 }}><u>Parts Required</u></span>
                <br /><br />
                {partsRequired.map((part, index) => (
                    <b key={index}>{part}<br /></b>
                ))}
                <br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Merchants</span>
                <br />
                {merchants.map((merchant, index) => (
                    <b key={index}>{merchant}<br /></b>
                ))}
                <br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Order Date</span>
                <br />
                <b>{orderDate}</b>
            </div>
            <br />
            <div className={"grp-view-page-further-checks-card"}>
                <span className={"primary-color"} style={{ fontSize: 18 }}><u>Works Carried Out / Comments</u></span>
                <br /><br />
                <b>{worksCarriedOut}</b>
            </div>
            <br />
            <div className={"grp-view-page-further-checks-card"}>
                <span className={"primary-color"} style={{ fontSize: 18 }}><u>Safety</u></span>
                <br /><br />
                <b>{safety}</b>
            </div>
            <br />
            <div className={"grp-view-page-further-checks-card"}>
                <span className={"primary-color"} style={{ fontSize: 18 }}><u>Office</u></span>
                <br />
                <b>Quote Required</b> {office.quoteRequired === true && ( <FaCheckCircle color={"green"} style={{ marginTop: 10 }}/> )}
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Order No</span>
                <br />
                <b>{office.orderNo}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Invoice No</span>
                <br />
                <b>{office.invoiceNo}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Comments</span>
                <br />
                <b>{office.comments}</b>
            </div>
            <br />
            <div className={"grp-view-page-further-checks-card"}>
                <span className={"primary-color"} style={{ fontSize: 18 }}><u>Contact</u></span>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Name</span>
                <br />
                <b>{contact.name}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Site Address</span>
                <br />
                <b>{contact.siteAddress}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Post Code</span>
                <br />
                <b>{contact.postCode}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Tel No</span>
                <br />
                <b>{contact.telNo}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Email</span>
                <br />
                <b>{contact.email}</b>
            </div>
            <br />
            <div className={"grp-view-page-further-checks-card"}>
                <span className={"primary-color"} style={{ fontSize: 18 }}><u>Signed</u></span>
                <br />
                <br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Engineer</span>
                <br />
                <b>{signed.engineer}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Print Name</span>
                <br />
                <b>{signed.printName}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Client</span>
                <br />
                <b>{signed.client}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Print Name</span>
                <br />
                <b>{signed.clientPrintName}</b>
                <br /><br />
                <span className={"primary-color"} style={{ fontSize: 15 }}>Date</span>
                <br />
                <b>{signed.date}</b>
            </div>
        </div>
    );
}

export default ProjectJobReport;
