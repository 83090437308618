import React, { useEffect, useState } from "react";
import {
  Icon,
  Input,
  Table,
  Button,
  Grid,
  Breadcrumb,
  Modal,
  Form,
} from "semantic-ui-react";
import { FaCheckCircle } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import AdminDashLayout from "../components/AdminDashLayout";
import { Link } from "react-router-dom";
import sendApiRequest from "../api";
import { toast } from "react-toastify";

// Function to format date as "19th Dec 2023 - 18 Jan 2024"
function formatDateRange(startDate, endDate) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const startFormatted = new Date(startDate).toLocaleDateString(
    undefined,
    options
  );
  const endFormatted = new Date(endDate).toLocaleDateString(undefined, options);
  return `${startFormatted} - ${endFormatted}`;
}

function HolidayRequests() {
  const [holidayRequests, setHolidayRequests] = useState([]);
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(5); // Number of items per page
  const [searchTerm, setSearchTerm] = useState(""); // Search term

  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [declineReason, setDeclineReason] = useState("");

  useEffect(() => {
    fetchHolidayRequests(page, pageSize);
  }, [page, pageSize]);

  const fetchHolidayRequests = (pageNumber, itemsPerPage) => {
    sendApiRequest(
      "get",
      `/workers/holiday_requests?pageNumber=${pageNumber}&pageSize=${itemsPerPage}`
    ).then((res) => {
      console.log(res.data);
      setHolidayRequests(res.data);
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };

  // Filter holiday requests based on search term
  const filteredHolidayRequests = holidayRequests.filter((request) =>
    request.user.firstname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleApproveClick = (request) => {
    setSelectedRequest(request);
    setApproveModalOpen(true);
  };

  const handleDeclineClick = (request) => {
    setSelectedRequest(request);
    setDeclineModalOpen(true);
  };

  const handleApproveConfirm = async () => {
    const sr = selectedRequest;
    //console.log(sr)
    const id = sr.id;
    //console.log(id)
    // Call your API to approve the request here using selectedRequest
    const r = await sendApiRequest("post", "/workers/holiday_requests/update/" + id, {
        approved: "yes"
    });
    //console.log(r)
    toast("Holiday Request Approved");
    fetchHolidayRequests(page, pageSize);
    // Then close the modal
    setApproveModalOpen(false);
  };

  const handleDeclineConfirm = async () => {
    const sr = selectedRequest;
    //console.log(sr)
    const id = sr.id;
    //console.log(id)
    // Call your API to decline the request here using selectedRequest and declineReason
    await sendApiRequest("post", "/workers/holiday_requests/update/" + id, {
        approved: "no",
        reason: declineReason
    });
    toast("Holiday Request Declined");
    // Then close the modal
    fetchHolidayRequests(page, pageSize);
    setDeclineModalOpen(false);
  };

  return (
    <AdminDashLayout>
      <div style={{ paddingTop: 40 }}>
        <Breadcrumb>
          <Link to={"/dashboard"}>
            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
          </Link>
          <Breadcrumb.Divider icon="right chevron" />
          <Link to={"/dashboard/workers"}>
            <Breadcrumb.Section link>Workers</Breadcrumb.Section>
          </Link>
          <Breadcrumb.Divider icon="right arrow" />
          <Breadcrumb.Section>Holiday Requests</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div style={{ paddingTop: 30, paddingRight: 20 }}>
        <Grid>
          <Grid.Column width={10}>
            <div style={{ paddingBottom: 15 }}>
              <h2>Holiday Requests</h2>
              <span style={{ fontSize: 17 }}>
                You have {holidayRequests.length} pending holiday requests to
                approve.
              </span>
            </div>
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <div style={{ paddingBottom: 15 }}>
              <Input
                icon="search"
                iconPosition="left"
                placeholder="Search holiday requests"
                className=""
                size="midi"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </Grid.Column>
        </Grid>
        <Table basic="very" size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S/N</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Holiday Requested</Table.HeaderCell>
              <Table.HeaderCell>Dates</Table.HeaderCell>
              <Table.HeaderCell>Approve?</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredHolidayRequests.map((request, index) => (
              <Table.Row key={request.id}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{request.user.firstname}</Table.Cell>
                <Table.Cell>{request.request}</Table.Cell>
                <Table.Cell>
                  {formatDateRange(request.start_date, request.end_date)}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    style={{ borderRadius: "100px" }}
                    size="mini"
                    as="a"
                    color="primary"
                    onClick={() => handleApproveClick(request)}
                  >
                    <FaCheckCircle />
                  </Button>{" "}
                  /{" "}
                  <Button
                    style={{ borderRadius: "100px" }}
                    size="mini"
                    as="a"
                    color="red"
                    onClick={() => handleDeclineClick(request)}
                  >
                    <FaXmark />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                <Button
                  floated="left"
                  icon
                  color="primary"
                  labelPosition="right"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  Previous Page
                  <Icon name="left arrow" />
                </Button>
                <Button
                  floated="left"
                  icon
                  color="primary"
                  labelPosition="right"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={holidayRequests.length < pageSize}
                >
                  Next Page
                  <Icon name="right arrow" />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
      {/* Approve Modal */}
      <Modal open={approveModalOpen} onClose={() => setApproveModalOpen(false)}>
        <Modal.Header>Approve Request</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className="primary-color">Are you sure you want to approve this request?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setApproveModalOpen(false)}>
            Cancel
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Approve"
            onClick={handleApproveConfirm}
          />
        </Modal.Actions>
      </Modal>

      {/* Decline Modal */}
      <Modal open={declineModalOpen} onClose={() => setDeclineModalOpen(false)}>
        <Modal.Header>Decline Request</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className="primary-color">Are you sure you want to decline this request?</p>
            <Form>
              <Form.TextArea
                label="Reason for Decline"
                placeholder="Please provide a reason for declining the request..."
                value={declineReason}
                onChange={(e) => setDeclineReason(e.target.value)}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setDeclineModalOpen(false)}>
            Cancel
          </Button>
          <Button
            negative
            icon="times"
            labelPosition="right"
            content="Decline"
            onClick={handleDeclineConfirm}
          />
        </Modal.Actions>
      </Modal>
    </AdminDashLayout>
  );
}

export default HolidayRequests;
