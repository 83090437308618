import axios from "axios";
import AuthService from "./auth";

export const API_URL = (() => {
  const hostname = window.location.hostname;
  if (hostname === "localhost") {
    return "http://localhost:3000";
    // return "http://localhost:8000";
    // return "https://api.test.workforce.gdheatingandgas.co.uk";
  } else if (hostname === "test.workforce.gdheatingandgas.co.uk") {
    return "https://api.test.workforce.gdheatingandgas.co.uk";
  } else {
    return "https://api.workforce.gdheatingandgas.co.uk";
  }
})();
// const API_ENV = process.env.REACT_APP_API_ENV;

const sendApiRequest = async (
  method = "get",
  url,
  data = null,
  token = localStorage.getItem('token')
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };

  try {
    const response = await axios({
      method: method,
      url: API_URL + url,
      data: data,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    if (error.response && (error.response.status === 400 || error.response.status === 401)) {

      if (error.response.data.error === "Invalid token") {
        AuthService.logout();
      }

      console.log("Bad Request Error:", error.response.data);
      return error.response.data || null;
      // Handle 400 error specifically
    } else {
      // console.log("Error:", error.message);
      // Handle other errors
      return null;
    }
  }
};

export default sendApiRequest;
