import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import sendApiRequest from './api';
import AuthService from './auth'

var firebaseConfig = {
  apiKey: "AIzaSyDJyyfF2Wmk0idXz80dBF9kUusV-Rq84kM",
  authDomain: "gd-workforce.firebaseapp.com",
  projectId: "gd-workforce",
  storageBucket: "gd-workforce.appspot.com",
  messagingSenderId: "360580697361",
  appId: "1:360580697361:web:f2cb407ecd32de5f3310b0"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BNJ4UylJ4-bsSTuYrZHP1Rs5IHvQIr-iW4fqUztbjyoSUd-rBp6PHqW7MHSmSKrsg8Bhmn-zmcljkcTkxhq8onw' })
      .then( async (currentToken) => {
        if (currentToken) {
          // console.log('current token for client: ', currentToken);

          let user = AuthService.getCurrentUser()

          const sr = await sendApiRequest('post', '/workers/update_fcm/' + user.id, {
            fcmToken: currentToken
          });

          // console.log(sr)

          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          // console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
      });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);

      // Display notification
      if (Notification.permission === 'granted') {
        const notificationTitle = payload.data.title;
        const notificationOptions = {
          body: payload.data.body,
          icon: '/logo512.png'
          // icon: payload.notification.icon,
        };

        new Notification(notificationTitle, notificationOptions);
      }
    });
});