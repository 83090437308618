import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {
  Button,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import sendApiRequest from "../api";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";

const EditVehicleMetaLog = () => {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({});
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [apiErrors, setApiErrors] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [staffOptions, setStaffs] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
      (res) => {
        const staffData = res.data.map((workers) => ({
          key: workers.firstname,
          text: workers.firstname,
          value: workers.firstname,
        }));

        setStaffs(staffData);
      }
    );
  }, [0]);

  const [logEntries, setLogEntries] = useState([
    {
      date: new Date(),
      mileage: "",
      workPerformed: "",
      performedBy: "",
      cost: "",
      notes: "",
    },
  ]);

  const addLogEntry = () => {
    setLogEntries([
      ...logEntries,
      {
        date: new Date(),
        mileage: "",
        workPerformed: "",
        performedBy: "",
        cost: "",
        notes: "",
      },
    ]);
  };

  const removeLogEntry = (index) => {
    const updatedLogEntries = [...logEntries];
    updatedLogEntries.splice(index, 1);
    setLogEntries(updatedLogEntries);
  };

  useEffect(() => {
    sendApiRequest("get", "/vehicle-logs/fetch/" + id).then((r) => {
      if (r.data.meta && r.data.meta.length > 0) {
        setLogEntries(r.data.meta);
      } else {
        setLogEntries([
          {
            date: new Date(),
            mileage: "",
            workPerformed: "",
            performedBy: "",
            cost: "",
            notes: "",
          },
        ]);
      }
      setReport(r.data);
    });
  }, []);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="example-custom-input"
      style={{
        width: "100%",
        height: "37px",
        background: "#fff",
        color: "#000",
        textAlign: "left",
        border: "1px solid #ddd",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const handleSubmit = () => {
    setLoading(true);
    setApiSuccess(false);
    setApiErrors(false);

    const d = {meta: JSON.stringify(logEntries)}

    console.log(d);

    // Login request
    (async () => {
      const postData = await sendApiRequest(
        "post",
        "/vehicle-logs/edit/" + id,
        d,
        localStorage.getItem("token")
      );
      if (postData !== null) {
        // console.log(postData);
        // any errors?
        if (postData.errors || postData.error) {
          setApiErrors(true);
          setApiSuccess(false);
          const errors = postData.errors;
          const errorMsg = [];

          if (postData.errors) {
            errors.forEach((e) => {
              errorMsg.push(`${e.path}: ${e.msg}`);
            });
          } else {
            errorMsg.push(postData.error);
          }

          setApiErrorMessage(errorMsg);
        }

        // if we got here, no errors.
        if (postData.message == "success") {
          setApiSuccess(true);
          setApiSuccessMessage("Vehicle Record Logs Modified Successfully");
          toast("Vehicle Record Logs Modified Successfully")
        }
      } else {
        setApiErrorMessage([
          "Error occurred while submitting data to the API.",
        ]);
        setApiErrors(true);
        setApiSuccess(false);
        setLoading(false);

        // console.log("Error occurred while submitting data to the API.");
      }
    })();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 40 }}>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/vehicle-log"}>
              <Breadcrumb.Section link>Vehicle Log</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right arrow" />
            <Link to={"/dashboard/vehicle-log/view/" + id}>
              <Breadcrumb.Section link>{report.numberPlate}</Breadcrumb.Section>
            </Link>

            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section>Log Work/Serviced Performed</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          {apiErrors ? (
            <Message error header="Whoops!" list={apiErrorMessage} />
          ) : null}

          {apiSuccess ? (
            <Message success header="Success!" content={apiSuccessMessage} />
          ) : null}
          <Form loading={loading ? true : false}>
            {logEntries.map((log, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <>
                  <Grid>
                    <Grid.Column
                      width={10}
                      className="grp-view-page-further-checks-card"
                    >
                      <h4>Vehicle Details Service Details - Log {index + 1}</h4>
                      <br />

                      <label style={{ paddingBottom: 20 }}>Date</label>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={log.date ? new Date(log.date) : new Date()}
                        value={log.date ? new Date(log.date) : new Date()}
                        onChange={(date) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].date = date;
                          setLogEntries(updatedLogEntries);
                        }}
                        customInput={<ExampleCustomInput />}
                      />
                      <br />
                      <br />

                      <Form.Input
                        size="medium"
                        label="Mileage"
                        placeholder="Enter Mileage"
                        value={log.mileage}
                        error={
                          errors.mileage ? { content: errors.mileage } : null
                        }
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].mileage = e.target.value;
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Form.TextArea
                        size="medium"
                        label="Work Performed"
                        placeholder="Enter Work Performed"
                        value={log.workPerformed}
                        error={
                          errors.mileage ? { content: errors.mileage } : null
                        }
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].workPerformed =
                            e.target.value;
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Form.Input
                        size="medium"
                        label="Work Performed By"
                        placeholder="John Doe"
                        value={log.performedBy}
                        error={
                          errors.mileage ? { content: errors.mileage } : null
                        }
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].performedBy = e.target.value;
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Form.Input
                        size="medium"
                        label="Cost"
                        placeholder="Enter Cost"
                        value={log.cost}
                        error={
                          errors.mileage ? { content: errors.mileage } : null
                        }
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].cost = e.target.value;
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Form.TextArea
                        size="medium"
                        label="Notes"
                        placeholder="Enter Notes"
                        value={log.notes}
                        error={
                          errors.mileage ? { content: errors.mileage } : null
                        }
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].notes = e.target.value;
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Button color="red" onClick={() => removeLogEntry(index)}>
                        Remove Log
                      </Button>
                    </Grid.Column>
                  </Grid>
                </>
              </div>
            ))}
            <br />
            <Button
              size="midi"
              color="primary"
              content="Add New Work"
              icon="question"
              labelPosition="right"
              onClick={addLogEntry}
            />

            <br />
            <br />
            <div style={{ position: "fixed", right: "20px", bottom: "20px" }}>
              <Button
                size="midi"
                color="primary"
                content="Save Changes"
                icon="plus"
                labelPosition="right"
                onClick={handleSubmit}
                floated="right"
              />
            </div>
          </Form>
        </div>
      </AdminDashLayout>
    </>
  );
};

export default EditVehicleMetaLog;
