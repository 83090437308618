import React, {useEffect, useState} from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {Button, Grid, Breadcrumb} from "semantic-ui-react";
import {Link, useParams} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {FaMailBulk, FaMapPin, FaPhone} from "react-icons/fa";
import WorkerProjectCards from "../components/WorkerProjectCards";
import {fetchWorker} from "../apiCalls";
import {UserAvatar} from "../components/UserAvatar";
import MapsComponent from "../components/MapsComponent";

const ViewWorker = () => {
    const { id } = useParams();
    const [worker, setWorker] = useState('')
    const [workerGeo, setWorkerGeo] = useState(null)

    useEffect(() => {
        fetchWorker(id, localStorage.getItem('token')).then(res => {
            setWorker(res.data)
            setWorkerGeo((res.data.geolocation == null ? null : JSON.parse(res.data.geolocation)));
        });
    }, [id]);
    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 40 }}>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Link to={"/dashboard/workers"}>
                            <Breadcrumb.Section link>Workers</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        
                        <Breadcrumb.Section>{worker.firstname}</Breadcrumb.Section>
                    </Breadcrumb>
                </div>

                <Grid>
                    <Grid.Column floated="left" width={7}>
                        <div style={{ paddingTop: 30, paddingRight: 20 }}>
                            <div style={{display: "inline-flex"}}>
                                <div>
                                    <UserAvatar worker={worker} size={70} />
                                </div>
                                <div style={{ paddingLeft: 20, marginTop: 10 }}>
                                    <span style={{ fontSize: 25, paddingBottom: 20 }}>{worker.firstname + ' ' + worker.lastname}</span>
                                    <br />
                                    <span style={{ fontSize: 15 }}> <FaPhone color={"#044f66"} /> {worker.phone_number}</span>
                                    <br />
                                    <span style={{ fontSize: 15 }}> <FaMailBulk color={"#044f66"} /> {worker.email}</span>
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column floated="right" width={8}>
                        <div style={{ paddingTop: 20, float: 'right' }}>
                            <Link to={"/dashboard/workers/manage-holidays/" + id}>
                                <Button
                                    color="purple"
                                    content="Manage Holidays"
                                    icon="calendar"
                                    labelPosition="right"
                                />
                            </Link>
                            <Link to={"/dashboard/workers/manage-offdays/" + id}>
                                <Button
                                    color="purple"
                                    content="Manage Off Days"
                                    icon="calendar"
                                    labelPosition="right"
                                />
                            </Link>
                            <Link to={"/dashboard/workers/edit/" + id}>
                                <Button
                                    color="primary"
                                    content="Edit Profile"
                                    icon="pencil"
                                    labelPosition="right"
                                />
                            </Link>
                           
                        </div>
                    </Grid.Column>
                </Grid>

                <Grid>
                    <Grid.Column floated="left" width={6}>
                        <div style={{ paddingTop: 30, paddingRight: 20 }}>
                            <h3>Assigned Projects</h3>
                            <WorkerProjectCards projects={worker.projects} />
                        </div>
                    </Grid.Column>
                    <Grid.Column floated="left" width={10}>
                        <div style={{ paddingTop: 20, float: 'left' }}>
                            <h3 style={{ color: "#044f66" }}>Live Location <FaMapPin color={"#044f66"} /></h3>
                            {workerGeo && (
                                <MapsComponent apiKey={"AIzaSyAQ-yd0m2mS27fWC6Fr_WNXlGgZuhlNja4"} latitude={workerGeo.lat} longitude={workerGeo.long} />
                            )}

                            {workerGeo == null && (
                                <h4>This worker is not sharing their location yet.</h4>
                            )}
                        </div>
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <h4 className={"primary-color"}><u>Holiday Stats</u></h4>
                        <h4>Days Assigned: {worker.holiday_days} days</h4>
                        <h4>Days Used: 0 days</h4>
                        <h4>Days Left: 0 days</h4>
                        <br /><br /><br /><br /><br /><br />
                    </Grid.Column>
                </Grid>
            </AdminDashLayout>
        </>
    );
};

export default ViewWorker;
