import DashCards from "../components/DashCards";
import RecentProjects from "../components/RecentProjects";
import {useCallback, useEffect, useState} from "react";
import {fetchDashboardStats} from '../apiCalls'
import sendApiRequest from "../api";
import AuthService from "../auth";

function StaffDashboardComponent(callback, deps) {
    const [totalProjects, setTotalProjects] = useState(0);
    const [totalHoursLogged, setTotalHoursLogged] = useState(0);
    const [totalCompletedProjectsCount, setTotalCompletedProjectsCount] = useState(0);
    const [projects, setProjects] = useState();

    const user = AuthService.getCurrentUser();

    const requestGeolocation = useCallback(() => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const {latitude, longitude} = position.coords;
                    const cords = {lat: latitude, long: longitude};

                    try {
                        await sendApiRequest('post', '/workers/update_geo/' + user.id, {
                            cords: cords
                        });
                    } catch (err) {
                        // ssh
                    }
                    // onLocationReceived({ latitude, longitude });
                },
                (error) => {
                    console.error('Geolocation error:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, [user]);

    useEffect(() => {
        fetchDashboardStats(localStorage.getItem('token')).then(data => {
            if(data) {
                setTotalProjects(data.totalProjects ?? 0);
                setTotalHoursLogged(data.hoursLogged ?? 0);
                setTotalCompletedProjectsCount(data.completedProjects ?? 0);
                setProjects(data.recentProjects)
            }
        });

        requestGeolocation();
        // toast('Fetched');
    }, [1]);


    return (
        <>
            <div style={{paddingTop: 30, paddingRight: 10}}>
                <h3>Dashboard</h3>
                <h4 style={{color: "rgb(145, 142, 142)", marginTop: 10}}>
                    Activity summary of the week
                </h4>
                <DashCards totalCompletedProjectsCount={totalCompletedProjectsCount} totalHoursLogged={totalHoursLogged} totalProjectsCount={totalProjects} role={user.role} />
                <h3 style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    Recent Projects
                </h3>
                <RecentProjects projects={projects}/>
            </div>
        </>
    );
}

export default StaffDashboardComponent;

