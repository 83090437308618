import React, {useState} from "react";
import {Button, Card, Dropdown, Grid, Icon, Input, Modal, Table} from "semantic-ui-react";
import {FaMapMarkerAlt} from "react-icons/fa";
import {Link} from "react-router-dom";
import {formatToBritishFormat} from "../utils";
import ProjectStatusLabel from "./ProjectStatusLabel";
import ProjectAssignedPeople from "./ProjectAssignedPeople";
import DatePicker from "react-datepicker";

const getItemsPerRow = () => {
    // Adjust these values based on your desired breakpoints
    if (window.innerWidth <= 768) {
        return 1; // Show 1 item per row on smaller screens
    } else if (window.innerWidth <= 1524) {
        return 2; // Show 2 items per row on medium screens
    } else {
        return 3; // Show 3 items per row on larger screens
    }
};

const itemsPerRow = getItemsPerRow();

function ProjectCards({count, projects, onPrevPageChange, onNextPageChange, canPaginate, defaultStatus = "on-going"}) {
    const [searchQuery, setSearchQuery] = useState("");

    const [statusFilter, setStatusFilter] = useState(defaultStatus);
    const [dateFilter, setDateFilter] = useState("");
    const [isModalOpen, setModalOpen] = useState(false); // State for modal visibility

    // Status filter handler
 

    const handleDateButtonClick = () => {
        setModalOpen(true); // Open the modal on button click
    };

    const handleDateSelect = (date) => {
        setDateFilter(date);
        setModalOpen(false); // Hide the modal after selecting a date
    };

    // Date filter handler
   

    // const filteredProjects = projects.filter((project) =>
    //     [
    //         project.projectName
    //     ].some((field) => field.toLowerCase().includes(searchQuery.toLowerCase()))
    // );

    console.log(projects)

    const filteredProjects = projects.filter((project) =>
        [
            project.projectName
        ].some((field) => field.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (statusFilter === "" || project.projectStatus === statusFilter) && // Apply status filter
        (dateFilter === "" || project.projectStartDate === dateFilter) // Apply date filter
    );

    return (
        <>
            <Grid>
                <Grid.Column mobile={16} computer={5} tablet={2} floated="right">
                    <div className="filter-options">
                        <div className="filter-buttons">
                            <Dropdown text="Status" button labelPosition="left">
                                <Dropdown.Menu>
                                    <Dropdown.Item text="All" value="all" onClick={() => setStatusFilter("")} />
                                    <Dropdown.Item text="Draft" value="draft" onClick={() => setStatusFilter("draft")} />
                                    <Dropdown.Item text="Ongoing" value="on-going" onClick={() => setStatusFilter("on-going")} />
                                    <Dropdown.Item text="Completed" value="completed" onClick={() => setStatusFilter("completed")} />
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button content="Date" icon="calendar" labelPosition="right" onClick={handleDateButtonClick} />
                            <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
                                <Modal.Header>Select a Date</Modal.Header>
                                <Modal.Content>
                                    <DatePicker
                                        selected={dateFilter}
                                        onChange={handleDateSelect}
                                        placeholderText="Select a date"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                                    <Button positive onClick={handleDateSelect}>
                                        Select
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        </div>
                    </div>
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column mobile={16} computer={4} tablet={2} floated="right">
                    <div style={{paddingBottom: 15, marginLeft: "20px"}}>
                        <Input
                            icon="search"
                            iconPosition="left"
                            placeholder="Search Projects"
                            className=""
                            size="midi"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </Grid.Column>
            </Grid>

            <Card.Group stackable itemsPerRow={itemsPerRow}>
                {filteredProjects.map((project, index) => (
                    <Card key={index} style={{backgroundColor: "#fff", padding: 25}} raised>
                        <Link to={"/dashboard/projects/view/" + project.id}
                              style={{textDecoration: 'none', textAlign: 'justify', color: 'inherit'}}>
                            <Card.Content>
                                <Grid>
                                    <Grid.Column floated="left" width={10}>
                                        <Card.Header>
                                            <h4>{project.projectName}</h4>
                                        </Card.Header>
                                    </Grid.Column>
                                    <Grid.Column floated="right" width={6}>
                                        <b>{formatToBritishFormat(project.projectStartDate || project.projectMilestones[0].startDate )}</b>
                                    </Grid.Column>
                                </Grid>
                                <Card.Description>
                                    <div style={{paddingTop: 20, paddingBottom: 10}}>
                                        <ProjectStatusLabel status={project.projectStatus} />
                                    </div>
                                    <div style={{paddingTop: 20, paddingBottom: 10}}>
                                        <FaMapMarkerAlt/> {project.clientAddress}
                                    </div>
                                    <ProjectAssignedPeople peopleAssigned={project.assignedToUserData} />
                                </Card.Description>
                            </Card.Content>
                        </Link>
                    </Card>
                ))}

            </Card.Group>
            <br />
            {canPaginate === true && (
                <>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="3">
                                <Button floated="left" icon color="primary" labelPosition="left" onClick={onPrevPageChange}>
                                    Previous Page
                                    <Icon name="left arrow" />
                                </Button>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="7">
                                <Button floated="right" icon color="primary" labelPosition="right" onClick={onNextPageChange}>
                                    Next Page
                                    <Icon name="right arrow" />
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </>
            )}
        </>
    )
};

export default ProjectCards;
