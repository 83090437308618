import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, Form, Dropdown, Button } from "semantic-ui-react";

function AddProjectMilestoneComponent({ milestones, onMilestonesChange, staffOptions }) {
  const addMilestone = () => {
    const newMilestone = {
      startDate: new Date(),
      endDate: new Date(),
      assignedTo: [],
      visitName: "",
    };
    const updatedMilestones = [...milestones, newMilestone];
    onMilestonesChange(updatedMilestones);
  };

  const removeMilestone = (index) => {
    const updatedMilestones = [...milestones];
    updatedMilestones.splice(index, 1);
    onMilestonesChange(updatedMilestones);
  };

  const handleStartDateChange = (date, index) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index].startDate = date;
    onMilestonesChange(updatedMilestones);
  };

  const handleEndDateChange = (date, index) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index].endDate = date;
    onMilestonesChange(updatedMilestones);
  };

  const handleAssignedToChange = (value, index) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index].assignedTo = value;
    onMilestonesChange(updatedMilestones);
  };

  const handleVisitNameChange = (value, index) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index].visitName = value;
    onMilestonesChange(updatedMilestones);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="example-custom-input" style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left" }} onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
      <div>
        {milestones.map((milestone, index) => (
            <div className={"multiple-site"} key={index}>
          <span className={"primary-color"} style={{ fontSize: 15 }}>
            <u>{`Visit ${index + 1}`}</u>
          </span>
              <br />
              <br />
              <Grid columns={2}>
                <Grid.Column>
                  <label>Project Start Date</label>
                  <DatePicker
                      selected={milestone.startDate || new Date()}
                      onChange={(date) => handleStartDateChange(date, index)}
                      dateFormat="dd/MM/yyyy"
                      customInput={<ExampleCustomInput />}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label>Project End Date</label>
                  <DatePicker
                      selected={milestone.endDate || new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => handleEndDateChange(date, index)}
                      customInput={<ExampleCustomInput />}
                  />
                </Grid.Column>
              </Grid>
              <Grid columns={2}>
                <Grid.Column>
                  <label>Assigned To</label>
                  <Dropdown
                      fluid
                      multiple
                      search
                      selection
                      options={staffOptions}
                      required={true}
                      onChange={(e, { value }) => {
                        handleAssignedToChange(value, index);
                      }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label>Visit Name</label>
                  <Form.Input
                      size="medium"
                      placeholder="eg: 1st visit"
                      onChange={(e) => handleVisitNameChange(e.target.value, index)}
                  />
                </Grid.Column>
              </Grid>
              <br />
              {index === milestones.length - 1 ? (
                  <Button type={"button"} color="primary" size={"mini"} onClick={addMilestone}>
                    Add a new milestone
                  </Button>
              ) : (
                  <Button color="red" size={"mini"} type="button" onClick={() => removeMilestone(index)}>
                    Remove milestone
                  </Button>
              )}
            </div>
        ))}
      </div>
  );
}

export default AddProjectMilestoneComponent;
