import React from "react";
import { List, Label } from "semantic-ui-react";
import { FaClock } from "react-icons/fa";
import moment from "moment";

const ProjectActivities = ({ projectActivities }) => {
    if (!projectActivities || projectActivities.length === 0) {
        return <div>No activities available</div>;
    }

    const activitiyColor = (type) => {
        switch (type) {
            case "draft":
                return "default"
            break;

            case "on-going":
                return "orange";
                break;

            case "completed":
                return "green";
                break;

            default:
                return "default"
        }
    }

    return (
        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
            <List divided relaxed>
                {projectActivities.map((activity, index) => (
                    <List.Item key={index}>
                        <List.Content>
                            <List.Header style={{ paddingBottom: 5 }}>
                                {activity.title}{" "}
                                <span className="primary-color">{activity.user.name}</span>
                            </List.Header>
                            <List.Description>
                                <small>
                                    <FaClock /> {moment(activity.created_at).format("h:mm A")} | {" "}
                                    {moment(activity.created_at).format("DD/MM/YYYY")}
                                </small>
                            </List.Description>
                            {activity.status.type !== 'draft' && (
                                <div
                                    style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        borderRadius: "99px",
                                    }}
                                >
                                    <Label
                                        color={activitiyColor(activity.status.type)}
                                        style={{ borderRadius: "99px" }}
                                    >
                                        {activity.status.type}
                                    </Label>
                                </div>
                            )}
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default ProjectActivities;
