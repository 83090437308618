import { Button, Grid } from "semantic-ui-react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Link } from "react-router-dom";
import VehicleLogTable from "../components/VehicleLogTable";
import { useEffect, useState } from "react";
import sendApiRequest from "../api";

const VehicleLog = () => {
  const [vreports, setvReports] = useState({});

  useEffect(() => {
    sendApiRequest("get", "/vehicle-logs").then((r) => {
      //console.log(r.data);
      setvReports(r.data);
    });
  });
  
  return (
    <AdminDashLayout>
      <>
        <div style={{ paddingTop: 40, paddingRight: 10 }}>
          <Grid>
            <Grid.Column floated="left" width={7}>
              <h3 style={{ color: "#044f66", marginTop: 20, fontSize: 20 }}>
                Vehicle Maintenance Log
              </h3>
            </Grid.Column>
            <Grid.Column floated="right" width={4}>
              <div style={{ paddingTop: 20, float: "right" }}>
                <Link to={"/dashboard/vehicle-log/create"}>
                  <Button
                    color="primary"
                    content="ADD A NEW VEHICLE +"
                    icon="plus"
                    labelPosition="right"
                  />
                </Link>
              </div>
            </Grid.Column>
          </Grid>
          <br /> <br />
          {/* <GrpCards projectsData={data}
                              onPrevPageChange={handlePrevPageChange}
                              onNextPageChange={handleNextPageChange}
                              canPaginate={canPaginate}
                    /> */}
          <VehicleLogTable reports={vreports} />
        </div>
      </>
    </AdminDashLayout>
  );
};

export default VehicleLog;
