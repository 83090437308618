import React from "react";
import {MdHome} from "react-icons/md";
import {Link, useLocation, matchPath} from "react-router-dom";
import {TiSpannerOutline} from "react-icons/ti";
import {MdOutlineAccessTimeFilled} from "react-icons/md";
import {SlCalender} from "react-icons/sl";
import {AiOutlineMessage} from "react-icons/ai";
import {IoMdBriefcase} from "react-icons/io";
import {FaCar, FaPeopleGroup} from "react-icons/fa6";
import {FaLink} from "react-icons/fa";
import Auth from '../auth'
import {IoMdDocument} from "react-icons/io";
import pipekitsvg from '../assets/pipe.svg'

const AdminSidenav = () => {
    const location = useLocation();
    const isActiveRoute = (pathPattern) => {
        const match = matchPath({path: pathPattern}, location.pathname);

        return match !== null;
    };

    const user = Auth.getCurrentUser()
    const userId = user.id;

    return (
        <>
            <li className={location.pathname === "/dashboard" ? "active" : ""}>
                <Link to={"/dashboard"}>
                    <MdHome size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                Dashboard
              </span>
                </Link>
            </li>
            <br/>
            <li
                className={
                    isActiveRoute("/dashboard/projects") ||
                    isActiveRoute("/dashboard/projects/create") ||
                    isActiveRoute("/dashboard/projects/create-small-project") ||
                    isActiveRoute("/dashboard/projects/invoice/logs/:id") ||
                    isActiveRoute("/dashboard/projects/view/:id") ||
                    isActiveRoute("/dashboard/projects/edit/:id") ||
                    isActiveRoute("/dashboard/project/landlord/add-record/:id") ||
                    isActiveRoute("/dashboard/projects/view/:id/*")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/projects"}>
                    <TiSpannerOutline size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                Projects
              </span>
                </Link>
            </li>
            <br/>
            <li
                className={
                    isActiveRoute("/dashboard/timesheet") ||
                    isActiveRoute("/dashboard/timesheet/view/:id") ||
                    isActiveRoute("/dashboard/timesheet/staff/view/:id/:month/:year") ||
                    isActiveRoute("/dashboard/timesheet/update/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/timesheet"}>
                    <MdOutlineAccessTimeFilled
                        size={30}
                        style={{marginBottom: "-10px"}}
                    />
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                Timesheet
              </span>
                </Link>
            </li>
            <br/>
            <li
                className={
                    location.pathname === "/dashboard/calendar" ? "active" : ""
                }
            >
                <Link to={"/dashboard/calendar"}>
                    <SlCalender size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                Calendar
              </span>
                </Link>
            </li>
            <br/>
            <li
                className={
                    location.pathname === "/dashboard/messenger" ? "active" : ""
                }
            >
                <Link to={"/dashboard/messenger"}>
                    <AiOutlineMessage size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                Messenger
              </span>
                </Link>
            </li>


            <br/>
            <li
                className={
                    isActiveRoute("/dashboard/clients") ||
                    isActiveRoute("/dashboard/clients/create") ||
                    isActiveRoute("/dashboard/clients/edit/:id") ||
                    isActiveRoute("/dashboard/clients/projects/:id") ||
                    isActiveRoute("/dashboard/clients/delete/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to="/dashboard/clients">
                    <IoMdBriefcase size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                    Clients
                  </span>
                </Link>
            </li>
            <br/>
            <li
                className={
                    isActiveRoute("/dashboard/request-holiday")
                        ? "active"
                        : ""
                }
            >
                <Link to={`/dashboard/request-holiday`}>
                    <IoMdDocument size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                    Holidays
                  </span>
                </Link>
            </li>
            <br />
            <li

                className={
                    isActiveRoute("/dashboard/workers") ||
                    isActiveRoute("/dashboard/workers/create") ||
                    isActiveRoute("/dashboard/holiday-requests") ||
                    isActiveRoute("/dashboard/workers/view/:id") ||
                        isActiveRoute("/dashboard/workers/edit/:id") ||
                        isActiveRoute("/dashboard/workers/manage-offdays/:id") ||
                        isActiveRoute("/dashboard/workers/manage-holidays/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/workers"}>
                    <FaPeopleGroup size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                    Workers
                  </span>
                </Link>
            </li>
            <br />
            <li

                className={
                    isActiveRoute("/dashboard/grp") ||
                    isActiveRoute("/dashboard/grp/view/:id") ||
                        isActiveRoute("/dashboard/grp/create") ||
                        isActiveRoute("/dashboard/grp/edit/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/grp"}>
                    <FaLink size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                    GRP Housing
                  </span>
                </Link>
            </li>
            <br />
            <li

                className={
                    isActiveRoute("/dashboard/vehicle-log") ||
                    isActiveRoute("/dashboard/vehicle-log/view/:id") ||
                        isActiveRoute("/dashboard/vehicle-log/create") ||
                        isActiveRoute("/dashboard/vehicle-log/edit/:id") || 
                        isActiveRoute("/dashboard/vehicle-log/edit/logs/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/vehicle-log"}>
                    <FaCar size={30} style={{marginBottom: "-10px"}}/>
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                    Vehicle Log
                  </span>
                </Link>
            </li>
            <br />
            <li

                className={
                    isActiveRoute("/dashboard/pipekits") ||
                    isActiveRoute("/dashboard/pipekits/view/:id") ||
                        isActiveRoute("/dashboard/pipekits/create") ||
                        isActiveRoute("/dashboard/pipekits/edit/:id") || 
                        isActiveRoute("/dashboard/pipekits/edit/logs/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/pipekits"}>
                    <img style={{width: '30px'}} src={pipekitsvg} />
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                    Pipe Kits
                  </span>
                </Link>
            </li>
        </>
    );
};

export default AdminSidenav;
