import { Breadcrumb, Button, Grid } from "semantic-ui-react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Link, useParams } from "react-router-dom";
import VehicleLogTable from "../components/VehicleLogTable";
import { useEffect, useState } from "react";
import sendApiRequest from "../api";
import VehicleLogMetaTable from "../components/VehicleLogMetaTable";
import { formatToLongDate } from "../utils";

const ViewVehicleLog = () => {
  const { id } = useParams();

  const [deleteModal, setDeleteModal] = useState({})

  const [report, setvReports] = useState({});

  useEffect(() => {
    sendApiRequest("get", "/vehicle-logs/fetch/" + id).then((r) => {
      console.log(r);
      setvReports(r.data);
    });
  }, []);


  return (
    <AdminDashLayout>
      <>
      <div style={{ paddingTop: 40 }}>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/vehicle-log"}>
              <Breadcrumb.Section link>Vehicle Log</Breadcrumb.Section>
            </Link>

          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 40, paddingRight: 10 }}>
          <Grid>
            <Grid.Column floated="left" width={7}>
              <Button style={{ backgroundColor: "yellow", color: "black" }}>
                {report.numberPlate}
              </Button>
            </Grid.Column>
            <Grid.Column floated="right" width={4}>
              <div style={{ paddingTop: 2, float: "right" }}>
                <Link to={"/dashboard/vehicle-log/edit/" + report.id}>
                  <Button
                    color="orange"
                    content="EDIT VEHICLE DETAILS"
                    icon="plus"
                    labelPosition="right"
                  />
                </Link>
              </div>
            </Grid.Column>

            <div style={{ paddingTop: 15, float: "right" }}>
              {" "}
              <Link to={"/dashboard/vehicle-log/edit/logs/" + report.id}>
                <Button
                  color="green"
                  content="EDIT VEHICLE LOGS"
                  icon="plus"
                  labelPosition="right"
                 
                />
              </Link>
            </div>

            <div style={{ paddingTop: 15, float: "right" }}>
              {" "}
           
                <Button
                  color="red"
                  content="DELETE VEHICLE LOGS"
                  icon="trash"
                  labelPosition="right"
                  size="mini"
                  onClick={deleteModal}
                />
        
            </div>
          </Grid>
          <br /> <br />
          <Grid>
            <Grid.Column floated="left" width={8}>
              <span style={{ fontSize: "20px" }}>Make: {report.make}</span>
              <br />
              <br />
              <span style={{ fontSize: "20px" }}>Model: {report.model}</span>
              <br />
              <br />
              <span style={{ fontSize: "20px" }}>Year: {report.year}</span>
              <br />
              <br />
              <span style={{ fontSize: "20px" }}>
                Mileage: {report.mileage} miles
              </span>
              <br />
              <br />
              <span style={{ fontSize: "20px" }}>
                Current Driver: {report.currentDriver}
              </span>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column floated="left" width={6}>
              <Grid>
                <Grid.Column floated="right" width={6}>
                  <div style={{ fontSize: "20px", paddingTop: 8 }}>
                    <span>MOT PASS </span>
                  </div>
                </Grid.Column>
                <Grid.Column width={10}>
                  <button
                    className="example-custom-input"
                    style={{
                      width: "100%",
                      height: "37px",
                      background: "#fff",
                      color: "#000",
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    {formatToLongDate(report.motPass)}
                  </button>
                </Grid.Column>
                <Grid.Column floated="right" width={6}>
                  <div style={{ fontSize: "20px", paddingTop: 8 }}>
                    <span>MOT DUE </span>
                  </div>
                </Grid.Column>
                <Grid.Column width={10}>
                  <button
                    className="example-custom-input"
                    style={{
                      width: "100%",
                      height: "37px",
                      background: "#fff",
                      color: "#000",
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    {formatToLongDate(report.motDue)}
                  </button>
                </Grid.Column>
                <Grid.Column floated="right" width={6}>
                  <div style={{ fontSize: "20px", paddingTop: 8 }}>
                    <span>SERVICE </span>
                  </div>
                </Grid.Column>
                <Grid.Column width={10}>
                  <button
                    className="example-custom-input"
                    style={{
                      width: "100%",
                      height: "37px",
                      background: "#fff",
                      color: "#000",
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    {formatToLongDate(report.service)}
                  </button>
                </Grid.Column>
                <Grid.Column floated="right" width={6}>
                  <div style={{ fontSize: "20px", paddingTop: 8 }}>
                    <span>NEXT SERVICE </span>
                  </div>
                </Grid.Column>
                <Grid.Column width={10}>
                  <button
                    className="example-custom-input"
                    style={{
                      width: "100%",
                      height: "37px",
                      background: "#fff",
                      color: "#000",
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    {formatToLongDate(report.nextService)}
                  </button>
                </Grid.Column>
                <Grid.Column floated="right" width={6}>
                  <div style={{ fontSize: "20px", paddingTop: 8 }}>
                    <span>INSURANCE DUE</span>
                  </div>
                </Grid.Column>
                <Grid.Column width={10}>
                  <button
                    className="example-custom-input"
                    style={{
                      width: "100%",
                      height: "37px",
                      background: "#fff",
                      color: "#000",
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    {formatToLongDate(report.insuranceDue)}
                  </button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <br /> <br />
          
              <Grid>
            <Grid.Column floated="left" width={7}>
            <h3 style={{ color: "#044f66", marginTop: 20, fontSize: 20 }}>
                Vehicle Maintenance Log
              </h3>
            </Grid.Column>
          
            <div style={{ paddingTop: 15, float: "right" }}>
              {" "}
              <Link to={"/dashboard/vehicle-log/edit/logs/" + report.id}>
                <Button
                  color="purple"
                  content="LOG ENTRIES"
                  icon="plus"
                  labelPosition="right"
                />
              </Link>
            </div>
          </Grid>
          <br /> <br />
          <VehicleLogMetaTable reports={report.meta} />
        </div>
      </>
    </AdminDashLayout>
  );
};

export default ViewVehicleLog;
