import React from "react";
import {Button, Card, Grid, Icon, Table} from "semantic-ui-react";
import {FaCheckCircle, FaMapMarkerAlt} from "react-icons/fa";
import {Link} from "react-router-dom";
import { formatToBritishFormat } from "../utils";
import { FaCircleXmark } from "react-icons/fa6";

const GrpCards = ({ projectsData, onPrevPageChange, onNextPageChange, canPaginate }) => (
    <><Card.Group itemsPerRow={2} stackable>
        {projectsData.map((project) => (
            <Card key={project.id} style={{backgroundColor: "#fff", padding: 25, paddingRight: "45px"}} raised>
                <Link to={`/dashboard/grp/view/${project.id}`}
                      style={{textDecoration: 'none', textAlign: 'justify', color: 'inherit'}}>
                    <Card.Content>
                        <Grid>
                            <Grid.Column floated="left" width={10}>
                                <Card.Header>
                                    <h4><FaMapMarkerAlt/> {project.destination} 
                                        {(project.meta?.controlQualityCheck?.mechanicalInfo.checkMechanicalWorksComplete && project.meta?.controlQualityCheck?.electricalInfo.checkElectricalWorksComplete) && (
                                            <>
                                                <FaCheckCircle color={"green"} style={{ marginTop: 10, marginLeft: 10 }} />
                                            </>
                                        )}

                                        {(!project.meta?.controlQualityCheck?.mechanicalInfo.checkMechanicalWorksComplete && !project.meta?.controlQualityCheck?.electricalInfo.checkElectricalWorksComplete) && (
                                            <>
                                                <FaCircleXmark color={"red"} style={{ marginTop: 10, marginLeft: 10 }} />
                                            </>
                                        )}
                                     </h4>
                                </Card.Header>
                            </Grid.Column>
                            <Grid.Column floated="left" width={6}>
                                <b>{formatToBritishFormat(project.meta?.collectionDate)}</b>
                            </Grid.Column>
                        </Grid>
                        <Card.Description>
                            <div style={{paddingTop: 20, paddingBottom: 10}}>
                                Order No: {project.orderNo}
                            </div>
                        </Card.Description>
                    </Card.Content>
                </Link>
            </Card>
        ))}
    </Card.Group>
        <br />
        {canPaginate === true && (
            <>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3">
                            <Button floated="left" icon color="primary" labelPosition="left" onClick={onPrevPageChange}>
                                Previous Page
                                <Icon name="left arrow" />
                            </Button>
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan="7">
                            <Button floated="right" icon color="primary" labelPosition="right" onClick={onNextPageChange}>
                                Next Page
                                <Icon name="right arrow" />
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </>
        )}
    </>

);

export default GrpCards;
