import React from "react";
import logo from "../assets/logo.png";
import { Divider } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import AuthService from "../auth";
import AdminSidenav from "./AdminSidenav";
import StaffSideNav from "./StaffSideNav";
import NonStaffSideNav from "./NonStaffSideNav";
import ClientSideNav from "./ClientSideNav";

const Sidenav = ({ navOpen }) => {
    const navigate = useNavigate();

    const user = AuthService.getCurrentUser();

    if (user.user) {
        return navigate("/login");
    }

    return (
        <>
            <nav className={`nav ${navOpen ? "nav-open" : ""}`}>
                <img src={logo} className="nav-logo hidden-mobile" alt="logo" />
                <div className={"hidden-mobile"}><Divider /></div>
                <ul className="navs">
                    {user.role === 1 && (
                        <AdminSidenav />
                    )}

                    {user.role === 2 && (
                        <StaffSideNav />
                    )}

                    {(user.role === 3 || user.role === 4) && (
                        <NonStaffSideNav />
                    )}

                    {(user.role === 5) && (
                        <ClientSideNav />
                    )}
                    <br />
                    <li className="">
                        <Link to={"/logout"}>
                            <FiLogOut size={30} style={{ marginBottom: "-10px" }} />
                            <span className="nav-link" style={{ marginBottom: "10px" }}>
                                Logout
                            </span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Sidenav;
