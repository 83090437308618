import AuthService from "../auth";
import AdminDashLayout from "../components/AdminDashLayout";
import AdminDashboardComponent from "../components/AdminDashboardComponent";
import StaffDashboardComponent from "../components/StaffDashboardComponent";
import NonStaffDashboardComponent from "../components/NonStaffDashboardComponent";
import ClientDashboardComponent from "../components/ClientDashboardComponent";

function Dashboard() {
    const user = AuthService.getCurrentUser();

    return (
        <>
            <AdminDashLayout>
                {user.role === 1 && (
                    <AdminDashboardComponent/>
                )}

                {user.role === 2 && (
                    <StaffDashboardComponent/>
                )}

                {(user.role === 3 || user.role === 4) && (
                    <NonStaffDashboardComponent/>
                )}

                {(user.role === 5) && (
                    <ClientDashboardComponent />
                )}

            </AdminDashLayout>
        </>
    );
}

export default Dashboard;
