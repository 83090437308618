import {
    startOfWeek,
    endOfWeek,
    eachWeekOfInterval,
    eachDayOfInterval,
    getWeek,
    startOfMonth,
    endOfMonth,
    format,
    getMonth,
    parse
  } from 'date-fns';
  
  /**
   * Get all weeks in a year with their start and end dates
   * @param {number} year - The year to get weeks for
   * @returns {Array<{weekNumber: number, startDate: Date, endDate: Date, dates: Date[]}>}
   */
  export const getWeeksInYear = (year) => {
    const startDate = new Date(year, 0, 1); // January 1st
    const endDate = new Date(year, 11, 31); // December 31st
  
    const weeks = eachWeekOfInterval(
      { start: startDate, end: endDate },
      { weekStartsOn: 1 } // Week starts on Monday
    ).map(week => {
      const start = startOfWeek(week, { weekStartsOn: 1 });
      const end = endOfWeek(week, { weekStartsOn: 1 });
      const weekNum = getWeek(week, { weekStartsOn: 1 });
      
      // Get all dates in the week
      const dates = eachDayOfInterval({ start, end });
  
      return {
        weekNumber: weekNum,
        startDate: start,
        endDate: end,
        dates,
        // Format dates as strings for easy display
        dateRange: `${format(start, 'dd MMM')} - ${format(end, 'dd MMM')}`
      };
    });
  
    return weeks;
  };
  
  /**
   * Get all weeks that occur in a specific month
   * @param {number} year - The year
   * @param {number} month - The month (0-11)
   * @returns {Array<{weekNumber: number, startDate: Date, endDate: Date, dates: Date[]}>}
   */
  export const getWeeksInMonth = (year, month) => {
    const monthStart = startOfMonth(new Date(year, month));
    const monthEnd = endOfMonth(new Date(year, month));
  
    const weeks = eachWeekOfInterval(
      { start: monthStart, end: monthEnd },
      { weekStartsOn: 1 }
    ).map(week => {
      const start = startOfWeek(week, { weekStartsOn: 1 });
      const end = endOfWeek(week, { weekStartsOn: 1 });
      const weekNum = getWeek(week, { weekStartsOn: 1 });
      const dates = eachDayOfInterval({ start, end });
  
      // Identify if this is a partial week (spans across months)
      const isPartialStart = getMonth(start) !== month;
      const isPartialEnd = getMonth(end) !== month;
  
      return {
        weekNumber: weekNum,
        startDate: start,
        endDate: end,
        dates,
        dateRange: `${format(start, 'dd MMM')} - ${format(end, 'dd MMM')}`,
        isPartialStart,
        isPartialEnd
      };
    });
  
    return weeks;
  };
  
  /**
   * Get details for a specific week number in a year
   * @param {number} year - The year
   * @param {number} weekNumber - The week number (1-53)
   * @returns {Object} Week details
   */
  export const getWeekDetails = (year, weekNumber) => {
    const allWeeks = getWeeksInYear(year);
    return allWeeks.find(week => week.weekNumber === weekNumber);
  };
  
  // Example usage
 export const exampleUsage = () => {
    // Get all weeks in 2024
    const weeks2024 = getWeeksInYear(2024);
    console.log('First week of 2024:', weeks2024[0]);
  
    // Get all weeks in August 2024
    const weeksInAugust = getWeeksInMonth(2024, 10); // Month is 0-based, so 7 is August
    console.log('Weeks in August 2024:', weeksInAugust);
  
    // Get details for week 31
    const week31 = getWeekDetails(2024, 31);
    console.log('Week 31 details:', week31);
  };

  

export const getMonthNumber = (monthName) => {
  try {
    // Parse the month name to a date object
    const date = parse(monthName, 'MMMM', new Date());
    return date.getMonth();
  } catch (error) {
    try {
      // Try parsing three-letter abbreviation
      const date = parse(monthName, 'MMM', new Date());
      return date.getMonth();
    } catch (error) {
      throw new Error(`Invalid month name: ${monthName}`);
    }
  }
};
