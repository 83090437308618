import React, { useEffect, useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Breadcrumb, Table, Label, Button } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import sendApiRequest from "../api";
import { format } from 'date-fns';
import AuthService from '../auth'
import { toast } from "react-toastify";

const ViewAttendanceLogs = () => {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [staffs, setStaffs] = useState(null)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const user = AuthService.getCurrentUser()
    const role = user.role

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await sendApiRequest("get", `/projects/fetch?id=${id}`, localStorage.getItem("token"));
                setProject(response.data[0]);
            } catch (error) {
                console.error("Error fetching project data:", error);
                setError("Failed to load project data.");
            } finally {
                setLoading(false);
            }
        };

        fetchProject();
    }, [id]);

    const handleApprove = async (id) => {
        try {
            await sendApiRequest("post", `/projects/attendance_requests/approve`, {
                id: project.id,
                index: id // Assuming attendanceLog has an id
            }, localStorage.getItem("token"));

            // Optionally, refresh the project data or update the state to reflect the approval
            toast.success("Attendance request approved successfully.");
        } catch (error) {
            console.error("Error approving attendance request:", error);
        }
    };

    const handleDecline = async (id) => {
        try {
            await sendApiRequest("post", `/projects/attendance_requests/decline`, {
                id: project.id,
                index: id // Assuming attendanceLog has an id
            }, localStorage.getItem("token"));
            // Optionally, refresh the project data or update the state to reflect the decline
            toast.success("Attendance request declined successfully.");
        } catch (error) {
            console.error("Error declining attendance request:", error);
        }
    };


    const renderStaffs = (staffs) => {
        return staffs.join(', ');
    };

    const renderPriorityLabel = (priority) => {
        const color = priority === 'urgent' ? 'red' : 'green';
        return <Label color={color}>{priority.toUpperCase()}</Label>;
    };

    const renderStatusLabel = (status) => {
        const color = status === 'PENDING' ? 'orange' : status === 'APPROVED' ? 'green' : 'red';
        return <Label color={color}>{status}</Label>;
    }

    const renderAttendanceLogs = () => {
        if (!project || !project.attendance_logs) return null;

        const logs = JSON.parse(project.attendance_logs);
        const reverse = logs.slice().reverse()
        return reverse.map((log, index) => (
            <Table.Row key={index}>
                <Table.Cell>{format(new Date(log.date), 'dd/MM/yyyy')}</Table.Cell>
                <Table.Cell>{renderPriorityLabel(log.priority)} - {format(new Date(`2000-01-01T${log.time}`), 'h:mm a')}</Table.Cell>
                <Table.Cell>{log.reason}</Table.Cell>
                <Table.Cell>{renderStaffs(log.staffs)}</Table.Cell>
                <Table.Cell>{renderStatusLabel(log?.status || 'PENDING')}</Table.Cell>
                {role === 1 && (
                    <>
                        {log.status === 'PENDING' && (
                            <Table.Cell>
                                <Button className="green" size="small" onClick={() => handleApprove(log?.id)}>Approve</Button>
                                 <Button className="red" size="small" onClick={() => handleDecline(log?.id)}>&nbsp;Decline</Button>
                            </Table.Cell>
                        )}

                        {log.status === 'APPROVED' && (
                            <Table.Cell>
                                <Button className="red" size="small" onClick={() => handleDecline(log?.id)}>Decline</Button>
                            </Table.Cell>
                        )}

                        {log.status === 'DECLINED' && (
                            <Table.Cell>
                                <Button className="green" size="small" onClick={() => handleApprove(log?.id)}>Approve</Button> 
                            </Table.Cell>
                        )}
                    </>
                )}
            </Table.Row>
        ));
    };

    if (loading) return <AdminDashLayout>Loading...</AdminDashLayout>;
    if (error) return <AdminDashLayout>Error: {error}</AdminDashLayout>;

    return (
        <AdminDashLayout>
            <div style={{ paddingTop: 40, paddingBottom: 20 }}>
                <Breadcrumb>
                    <Link to="/dashboard"><Breadcrumb.Section link>Dashboard</Breadcrumb.Section></Link>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Link to="/dashboard/projects"><Breadcrumb.Section link>Projects</Breadcrumb.Section></Link>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Link to={`/dashboard/projects/view/${project?.id}`}><Breadcrumb.Section link>{project?.projectName}</Breadcrumb.Section></Link>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>Viewing Attendance - {project?.projectName}</Breadcrumb.Section>
                </Breadcrumb>
            </div>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date Requested</Table.HeaderCell>
                        <Table.HeaderCell>Priority / Time</Table.HeaderCell>
                        <Table.HeaderCell>Description / Notes</Table.HeaderCell>
                        <Table.HeaderCell>Staff(s) Requested</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        {role === 1 && (
                            <>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderAttendanceLogs()}
                </Table.Body>
            </Table>
        </AdminDashLayout>
    );
};

export default ViewAttendanceLogs;