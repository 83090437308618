import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {
  Button,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { Link, redirect, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import sendApiRequest from "../api";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { fetchProject } from "../apiCalls";
import { isArray } from "lodash";

const ProjectInvoiceLogs = () => {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({});
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [apiErrors, setApiErrors] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [staffOptions, setStaffs] = useState([]);
  const [projectdata, setProjectData] = useState([])

  const { id } = useParams();

  const [logEntries, setLogEntries] = useState([]);

  useEffect(() => {
    fetchProject(id, localStorage.getItem('token')).then((r) => {
      console.log(r)
      setProjectData(r.data[0])

      const projectInvoiceMeta = r.data[0].projectInvoiceMeta?.logs;

      if (Array.isArray(projectInvoiceMeta) && projectInvoiceMeta.length > 0) {
        setLogEntries(projectInvoiceMeta);
      } else {
        setLogEntries([
          {
            date: new Date(),
            merchant: "",
            cost: "",
            costVat: ""
          },
        ]);
      }
    })
  }, [0]);

  const addLogEntry = () => {
    setLogEntries([
      ...logEntries,
      {
        date: new Date(),
        merchant: "",
        cost: "",
        costVat: ""
      },
    ]);
  };

  const removeLogEntry = (index) => {
    const updatedLogEntries = [...logEntries];
    updatedLogEntries.splice(index, 1);
    setLogEntries(updatedLogEntries);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="example-custom-input"
      style={{
        width: "100%",
        height: "37px",
        background: "#fff",
        color: "#000",
        textAlign: "left",
        border: "1px solid #ddd",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const handleSubmit = async () => {
    setLoading(true);
    setApiSuccess(false);
    setApiErrors(false);

    const data = {
      "projectInvoiceMeta": {
        "estimate": projectdata.projectInvoiceMeta.estimate,
        "logs": logEntries
      }
    }

    // Login request

    const postData = await sendApiRequest(
      "post",
      "/projects/edit/" + id,
      data,
      localStorage.getItem("token")
    );
    if (postData !== null) {
      console.log(postData);
      // any errors?
      if (postData.errors || postData.error) {
        setApiErrors(true);
        setApiSuccess(false);
        const errors = postData.errors;
        const errorMsg = [];

        if (postData.errors) {
          errors.forEach((e) => {
            errorMsg.push(`${e.path}: ${e.msg}`);
          });
        } else {
          errorMsg.push(postData.error);
        }

        setApiErrorMessage(errorMsg);
      }

      // if we got here, no errors.

        setApiSuccess(true);
        setApiSuccessMessage("Invoice Logs Modified Successfully");
        toast("Invoice Logs Modified Successfully")

        redirect("/dashboard/projects/view/" + id)
      
    } else {
      setApiErrorMessage([
        "Error occurred while submitting data to the API.",
      ]);
      setApiErrors(true);
      setApiSuccess(false);
      setLoading(false);

      // console.log("Error occurred while submitting data to the API.");
    }


    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 40 }}>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/projects"}>
              <Breadcrumb.Section link>Projects</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/projects/view/" + id}>
              <Breadcrumb.Section link>View: {projectdata.projectName}</Breadcrumb.Section>
            </Link>

            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section>Log Invoice - {projectdata.projectName}</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          {apiErrors ? (
            <Message error header="Whoops!" list={apiErrorMessage} />
          ) : null}

          {apiSuccess ? (
            <Message success header="Success!" content={apiSuccessMessage} />
          ) : null}
          <br /> s
          <Form loading={loading ? true : false}>
            {logEntries.map((log, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <>
                  <Grid>
                    <Grid.Column
                      width={10}
                      className="grp-view-page-further-checks-card"
                    >
                      <h4>Log Invoice {index + 1}</h4>
                      <br />

                      <label style={{ paddingBottom: 20 }}>Date</label>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        selected={log.date ? new Date(log.date) : new Date()}
                        value={log.date ? new Date(log.date) : new Date()}
                        onChange={(date) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].date = date;
                          setLogEntries(updatedLogEntries);
                        }}
                        customInput={<ExampleCustomInput />}
                      />
                      <br />
                      <br />

                      <Form.Input
                        size="medium"
                        label="Merchant"
                        placeholder="Enter Merchant"
                        value={log.merchant}
                        error={
                          errors.merchant ? { content: errors.merchant } : null
                        }
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].merchant = e.target.value;
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Form.Input
                        size="medium"
                        label="Cost"
                        placeholder="Enter Cost"
                        value={log.cost}
                        error={
                          errors.cost ? { content: errors.cost } : null
                        }
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].cost = e.target.value;
                          updatedLogEntries[index].costVat = log.cost ? (log.cost * 1.2).toFixed(2) : '';
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Form.Input
                        size="medium"
                        label="Cost (incl. VAT)"
                        value={log.costVat}
                        readOnly
                        onChange={(e) => {
                          const updatedLogEntries = [...logEntries];
                          updatedLogEntries[index].costVat = log.cost ? (log.cost * 1.2).toFixed(2) : '';
                          setLogEntries(updatedLogEntries);
                        }}
                      />

                      <Button color="red" onClick={() => removeLogEntry(index)}>
                        Remove Log
                      </Button>
                    </Grid.Column>
                  </Grid>
                </>
              </div>
            ))}
            <br />
            <Button
              size="midi"
              color="primary"
              content="Add Extra Log"
              icon="plus"
              labelPosition="right"
              onClick={addLogEntry}
            />

            <br />
            <br />
            <div style={{ position: "fixed", right: "20px", bottom: "20px" }}>
              <Button
                size="midi"
                color="primary"
                content="Save Entries"
                icon="cloud"
                labelPosition="right"
                onClick={handleSubmit}
                floated="right"
              />
            </div>
          </Form>
        </div>
      </AdminDashLayout>
    </>
  );
};

export default ProjectInvoiceLogs;
