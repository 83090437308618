import DashCards from "../components/DashCards";
import RecentProjects from "../components/RecentProjects";
import {useEffect, useState} from "react";
import {fetchDashboardStats} from '../apiCalls'
function AdminDashboardComponent() {
    const [totalProjects, setTotalProjects] = useState(0);
    const [totalHoursLogged, setTotalHoursLogged] = useState(0);
    const [totalCompletedProjectsCount, setTotalCompletedProjectsCount] = useState(0);
    const [projects, setProjects] = useState();

    useEffect(() => {
        fetchDashboardStats(localStorage.getItem('token')).then(data => {
            if(data) {
                setTotalProjects(data.totalProjects ?? 0);
                setTotalHoursLogged(data.hoursLogged ?? 0);
                setTotalCompletedProjectsCount(data.completedProjects ?? 0);
                setProjects(data.recentProjects)
            }
        });
        // toast('Fetched');
    }, []);

    return (
        <>
            <div style={{paddingTop: 30, paddingRight: 10}}>
                <h3>Dashboard</h3>
                <h4 style={{color: "rgb(145, 142, 142)", marginTop: 10}}>
                    Activity summary of the week
                </h4>
                <DashCards totalCompletedProjectsCount={totalCompletedProjectsCount} totalHoursLogged={totalHoursLogged} totalProjectsCount={totalProjects} />
                <h3 style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    Recent Projects
                </h3>
                <RecentProjects projects={projects}/>
            </div>
        </>
    );
}

export default AdminDashboardComponent;
