import React, { useState, useEffect, useCallback, useMemo } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Grid, Modal, Input, Dropdown, Form, Card, Popup } from "semantic-ui-react";
import TimesheetCards from "../components/TimesheetCards";
import TimesheetsCardsAdminStaffs from "../components/TimesheetsCardsAdminStaffs";
import AuthService from "../auth";
import sendApiRequest from "../api";
import { Link, useParams, useNavigate } from "react-router-dom"; // Import your API request function
import TimesheetCardsAdmin from "../components/TimesheetCardsAdmin";
import { getWeeksInMonth, getMonthNumber } from "../timesheetUtils";

const getPastYears = (count = 10) => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: count }, (_, index) => currentYear - index);
};

function ViewStaffTimesheetWeeks() {
  const user = AuthService.getCurrentUser();
  const user_role = user.role;

  const { id, month, year } = useParams();

  const currentDate = new Date();
  // const currentMonth = month || currentDate.toLocaleString("default", { month: "long" });
  // const currentYear = year || currentDate.getFullYear();

  const [timesheetData, setTimesheetData] = useState([]);
  const [timesheetStaffData, setTimesheetStaffData] = useState([])
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [timesheetId, setTimesheetId] = useState('')
  const [statusData, setStatusData] = useState([]);
  const [staffId, setStaffId] = useState();
  

  const [currentMonth, setCurrentMonth] = useState(month || currentDate.toLocaleString("default", { month: "long" }));
  const [currentYear, setCurrentYear] = useState(year || currentDate.getFullYear());

  const weeksInMonth = useMemo(() => {
    const weeks = getWeeksInMonth(currentYear, getMonthNumber(currentMonth));
    return weeks;
  }, [currentMonth, currentYear]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTimesheetData(); // Fetch timesheet data when the component mounts
  }, []);

  const fetchTimesheetData = async () => {
    setLoading(true);
    try {
      // Modify the API endpoint and request method as needed
      const response = await sendApiRequest("get", "/timesheet/fetch?id="+id);
      setTimesheetData(response.data.weeksCount);
      setTimesheetId(response.data.id);
      setStaffId(response.data.staff_id);
      console.log(response);
      
      navigate(`/dashboard/timesheet/staff/view/${id}/${response.data.month}/${response.data.year}`);
      setCurrentMonth(response.data.month);
      setCurrentYear(response.data.year);

      setStatusData(response.data.timesheetWeeks)

    } catch (error) {
      console.error("Error fetching timesheet data: ", error);
    }

    setLoading(false);
  };

  const handleFilterDates = async (currentMonth, currentYear, staff_id) => {
    setLoading(true);
    try {
      const response = await sendApiRequest("get", "/timesheet/filter?month=" + currentMonth + "&year=" + currentYear + "&id=" + staff_id);

      if (response.message === "success") {
        // Check if the 'data' property in the response is null
        if (response.data === null) {
          // Handle the case where data is null (e.g., display a message or set an empty array)
          setTimesheetData([]);
        } else {
          setTimesheetData(response.data[0].weeksCount);
          setTimesheetId(response.data[0].id);
          console.log(response);
    
          setStatusData(response.data[0].timesheetWeeks)
        }
      } else {
        // Handle other possible error scenarios if needed
        console.error("Error filtering dates:", response.message);
      }
    } catch (error) {
      console.error("Error filtering dates:", error);
    }
    setLoading(false);
  };

  const monthsOfTheYear = [
    {
      "monthNumber": "01",
      "monthShort": "Jan",
      "monthFull": "January"
    },
    {
      "monthNumber": "02", 
      "monthShort": "Feb",
      "monthFull": "February"
    },
    {
      "monthNumber": "03",
      "monthShort": "Mar", 
      "monthFull": "March"
    },
    {
      "monthNumber": "04",
      "monthShort": "Apr",
      "monthFull": "April"
    },
    {
      "monthNumber": "05",
      "monthShort": "May",
      "monthFull": "May"
    },
    {
      "monthNumber": "06",
      "monthShort": "Jun",
      "monthFull": "June"
    },
    {
      "monthNumber": "07",
      "monthShort": "Jul",
      "monthFull": "July"
    },
    {
      "monthNumber": "08",
      "monthShort": "Aug",
      "monthFull": "August"
    },
    {
      "monthNumber": "09",
      "monthShort": "Sep",
      "monthFull": "September"
    },
    {
      "monthNumber": "10",
      "monthShort": "Oct",
      "monthFull": "October"
    },
    {
      "monthNumber": "11",
      "monthShort": "Nov",
      "monthFull": "November"
    },
    {
      "monthNumber": "12",
      "monthShort": "Dec",
      "monthFull": "December"
    }
  ];

  const getCardColor = (index, month) => {
    if (month === currentMonth) return "#0C94BE";
      if(index < 4) {
        return "#D3F0D2"
      } else if (index < 8) {
        return "#D8EDF3"
      } else {
        return "#FCE9D4"
      }
  }

  const changeDate = (newMonth = currentMonth, newYear = currentYear) => {
    navigate(`/dashboard/timesheet/staff/view/${id}/${newMonth}/${newYear}`);
    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
    handleFilterDates(newMonth, newYear, staffId);
    setModalOpen(false);
  };

  const pastYears = getPastYears();

  const options = pastYears.map((year) => ({
    key: year,
    text: year,
    value: year,
  }));

  return (
      <>
        <AdminDashLayout>
          <div style={{ paddingTop: 30, paddingRight: 20 }}>
            <div className="timesheet-container">
              <Grid>
                <Grid.Column mobile={16} computer={7} floated="left">
                  <h1>
                    <span className="timesheet-subtitle"><Link to={'/dashboard/timesheet'}>Timesheet</Link></span> /{" "}
                    {currentMonth} {currentYear}
                  </h1>
                </Grid.Column>

                <Grid.Column mobile={16} computer={3} floated="">
                  {/* {user_role === 1 && (
                      <div className="filter-dates">
                        <Modal
                            trigger={
                              <Button
                                  content="Filter Dates"
                                  icon="calendar"
                                  labelPosition="right"
                                  color="primary"
                                  disabled={loading}
                              />
                            }
                            onClose={() => setModalOpen(false)}
                            onOpen={() => setModalOpen(true)}
                            open={modalOpen}
                            size="medium"
                        >
                          <Modal.Header>Filter Dates</Modal.Header>
                          <Modal.Content>
                            <Form 
                              style={{
                                display: "flex",
                                gap: "40px",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    color: `${currentYear === new Date().getFullYear() ? "#0c94be" : "black"}`,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: `${currentYear === new Date().getFullYear() ? "#CCE8F1" : ""}`,
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  onClick={() => changeDate(currentMonth, new Date().getFullYear())}
                                >
                                  This Year
                                </p>

                                <p
                                  style={{
                                    color: `${currentYear === new Date().getFullYear() - 1 ? "#0c94be" : "black"}`,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: `${currentYear === new Date().getFullYear() - 1 ? "#CCE8F1" : ""}`,
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  onClick={() => changeDate(currentMonth, new Date().getFullYear() - 1)}
                                >
                                  Last Year
                                </p>
                                <Form.Field>
                                  <label>Month & Year</label>
                                  <Input
                                      type="date"
                                      value={currentYear}
                                      onChange={(e) => changeDate(currentMonth, e.target.value)}
                                      disabled={loading}
                                  />
                                </Form.Field>
                              </div>
                              <Card.Group stackable itemsPerRow={4}>
                                {
                                  monthsOfTheYear.map((month, index) => (
                                    <Card
                                      key={index}
                                      style={{
                                          backgroundColor: getCardColor(index, month.monthFull),
                                          padding: 5,
                                          marginBottom: 20,
                                          color: "black",
                                          fontWeight: "700",
                                          border: `${currentMonth === month.monthFull ? "6px solid #dbdbdb" : "none"}`,
                                          height: auto,
                                      }}
                                      onClick={() => changeDate(month.monthFull, currentYear)}
                                      raised
                                    >
                                      <Card.Content>
                                        {month.monthShort}
                                      </Card.Content>
                                    </Card>
                                  ))
                                }

                              </Card.Group>
                            </Form>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                                color="youtube"
                                onClick={() => {
                                  setModalOpen(false)
                                }}
                            >
                              Close
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                  handleFilterDates();
                                  setModalOpen(false);
                                }}
                                disabled={loading}
                            >
                              Apply
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </div>
                  )} */}

                  {
                    user_role === 1 && (
                      <div className="filter-dates">
                        <Popup
                          trigger={
                            <Button
                                content="Filter By"
                                icon="search"
                                labelPosition="right"
                                color="primary"
                                disabled={loading}
                            />
                          }
                          size='small'
                          flowing
                          on="click"
                          pinned
                          position='bottom right'
                        >
                            <Form 
                              style={{
                                display: "flex",
                                gap: "40px",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    color: `${currentYear === new Date().getFullYear() ? "#0c94be" : "black"}`,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: `${currentYear === new Date().getFullYear() ? "#CCE8F1" : ""}`,
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  onClick={() => changeDate(currentMonth, new Date().getFullYear())}
                                >
                                  This Year
                                </p>

                                <p
                                  style={{
                                    color: `${currentYear === new Date().getFullYear() - 1 ? "#0c94be" : "black"}`,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: `${currentYear === new Date().getFullYear() - 1 ? "#CCE8F1" : ""}`,
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  onClick={() => changeDate(currentMonth, new Date().getFullYear() - 1)}
                                >
                                  Last Year
                                </p>

                                <Dropdown
                                  placeholder="--SELECT MORE--"
                                  fluid
                                  selection
                                  options={options}
                                  onChange={(e, { value }) => changeDate(currentMonth, value)}
                                />
                              </div>
                              <Card.Group stackable itemsPerRow={4} style={{width: "500px"}}>
                                {
                                  monthsOfTheYear.map((month, index) => (
                                    <Card
                                      key={index}
                                      style={{
                                          backgroundColor: getCardColor(index, month.monthFull),
                                          padding: 5,
                                          marginBottom: 20,
                                          color: "black",
                                          fontWeight: "700",
                                          border: `${currentMonth === month.monthFull ? "6px solid #dbdbdb" : "none"}`,
                                          // height: auto,
                                      }}
                                      onClick={() => changeDate(month.monthFull, currentYear)}
                                      raised
                                    >
                                      <Card.Content style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        {month.monthShort}
                                      </Card.Content>
                                    </Card>
                                  ))
                                }

                              </Card.Group>
                            </Form>
                        </Popup>
                      </div>
                    )
                  }
                </Grid.Column>
              </Grid>
            </div>
            <br />

            <h2
              style={{
                color: "primary"
              }}
            >
              {currentMonth} {currentYear}
            </h2>


            <p>
              WEEK {weeksInMonth[0].weekNumber} - WEEK {weeksInMonth[weeksInMonth.length - 1].weekNumber}
            </p>

            <br />

            <br />
            <TimesheetCardsAdmin statusData={statusData} weeksInMonth={weeksInMonth} id={timesheetId} timesheetData={timesheetData} />

          </div>
        </AdminDashLayout>
      </>
  );
}

export default ViewStaffTimesheetWeeks;
