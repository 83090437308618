import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Grid, Breadcrumb, Dropdown, Message, Table } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { roleOptions, formatToBritishFormat, formatMonthYear } from "../utils";
import { emailRegex, phoneRegex } from '../utils'
import sendApiRequest, { API_URL } from "../api";
import { fetchWorker, uploadFiles } from "../apiCalls";
import DatePicker from "react-datepicker";

const ManageWorkerHolidayOffDays = () => {
    const { id } = useParams();
    const [worker, setWorker] = useState('')
    const [role, setRole] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('');

    const [offDayStarts, setOffDayStarts] = useState(new Date())
    const [offDayEnds, setOffDayEnds] = useState(new Date())
    const [reasonForOffDay, setReasonForOffDay] = useState('')
    const [reasonForOffDayOthers, setReasonForOffDayOthers] = useState('')

    const [loading, setLoading] = useState('');
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const [files, setFiles] = React.useState([]);
    const [allocatedHolidayTime, setAllocatedHolidayTime] = useState(1)
    const [holidayOffDaysLog, setHolidayOffDaysLog] = useState([])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling behavior
        });
    };

    useEffect(() => {
        fetchWorker(id, localStorage.getItem('token')).then(response => {
            setWorker(response.data);
            setRole(response.data.role);
            setFirstname(response.data.firstname);
            setLastname(response.data.lastname)
            setAddress(response.data.address);
            setEmail(response.data.email);
            setPhone(response.data.phone_number);
            setAllocatedHolidayTime(response.data.holiday_days)
            
            setHolidayOffDaysLog(response.data.holiday_off_days ? JSON.parse(response.data.holiday_off_days) : [])
        })
        setLoading(false);
    }, [id]);

    const updateFiles = (incomingFiles) => {
        //do something with the files
        setFiles(incomingFiles);
        //even your own upload implementation
        // uploadFiles(incomingFiles)
    };
    const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };

    const weeksDropdown = [];

    for (let i = 1; i <= 12; i++) {
        const weeksText = i === 1 ? "1 week" : `${i} weeks`;
        weeksDropdown.push({ key: i, value: i, text: weeksText });
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button type="button" className="example-custom-input" style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }} onClick={onClick} ref={ref}>
          {value}
        </button>
      ));

    const defaultDropdownOption = weeksDropdown.find(option => option.value === allocatedHolidayTime);

    const handleSubmit = () => {
        scrollToTop();
        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);

        const formData = {
            userId: id,
            offDayStarts: offDayStarts,
            offDayEnds: offDayEnds,
            reasonForOffDay: reasonForOffDay === 'others' ? reasonForOffDayOthers : reasonForOffDay
        };

        let newErrors;
        newErrors = {};

        // request
        (async () => {

            try {
                // Construct formData here, including the photoUri if it was set
                const apiData = {
                    ...formData
                };

                // Send the API request
                const postData = await sendApiRequest("post", "/workers/holiday_off_days/create", apiData, localStorage.getItem('token'));

                if (postData !== null) {
                    // Handle API response here
                    if (postData.errors || postData.error) {
                        // Handle errors
                        setApiErrors(true);
                        setApiSuccess(false);
                        const errors = postData.errors || [postData.error];
                        const errorMsg = errors.map((e) => e.msg);
                        setApiErrorMessage(errorMsg);
                    } else {
                        holidayOffDaysLog.push(postData.data)
                        // Handle success
                        //toast("Changes Saved Successfully")
                        setApiSuccess(true);
                        setApiSuccessMessage("Created");
                    }
                } else {
                    // Handle API request error
                    setApiErrorMessage(["Error occurred while submitting data to the API."]);
                    setApiErrors(true);
                    setApiSuccess(false);
                }
            } catch (error) {
                // Handle any unexpected errors
                console.error(error);
            } finally {
                // Ensure setLoading is set to false
                setLoading(false);
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (<>
        <AdminDashLayout>
            <div style={{ paddingTop: 40 }}>
                <Breadcrumb>
                    <Link to={"/dashboard"}>
                        <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider icon="right chevron" />
                    <Link to={"/dashboard/workers"}>
                        <Breadcrumb.Section link>Workers</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider icon="right chevron" />
                    <Link to={"/dashboard/workers/view/" + id}>
                        <Breadcrumb.Section link>{firstname}</Breadcrumb.Section>
                    </Link>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>Manage Off Days</Breadcrumb.Section>
                </Breadcrumb>
            </div>
            <div style={{ paddingTop: 30, paddingRight: 20 }}>
                <Grid>
                    <Grid.Column width={15} floated="left">
                        {apiErrors ? (<Message
                            error
                            header="Whoops!"
                            list={apiErrorMessage}
                        />) : null}

                        {apiSuccess ? (<Message
                            success
                            header="Success!"
                            content={apiSuccessMessage}
                        />) : null}
                        <Form loading={loading ? true : false}>

                            <Form.Field>
                                <label>Off Day Starts</label>
                                <DatePicker
                                    selected={offDayStarts || new Date()}
                                    onChange={(date) => setOffDayStarts(date)}
                                    dateFormat="dd/MM/yyyy"
                                    required={true}
                                    customInput={<ExampleCustomInput />}
                                    error={errors.offDayStarts ? { content: errors.offDayStarts } : null}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>Off Day Ends</label>
                                <DatePicker
                                    selected={offDayEnds || new Date()}
                                    onChange={(date) => setOffDayEnds(date)}
                                    dateFormat="dd/MM/yyyy"
                                    required={true}
                                    customInput={<ExampleCustomInput />}
                                    error={errors.offDayStarts ? { content: errors.offDayStarts } : null}
                                />
                            </Form.Field>
                            
                            <label>Reason For Off Day <b
                                style={{ color: 'red', paddingBottom: '10px' }}>*</b></label>
                            <Dropdown
                                placeholder="Reason For Off Day"
                                fluid
                                search
                                selection
                                options={[
                                    { key: 'sick', text: 'Sick', value: 'sick' },
                                    { key: 'resting', text: 'Resting', value: 'resting' },
                                    { key: 'others', text: 'Others', value: 'others' }
                                ]}
                                value={reasonForOffDay}
                                required={true}
                                error={errors.role ? { content: errors.role } : null}
                                onChange={(e, { value }) => {
                                    errors.role = null;
                                    setReasonForOffDay(value);
                                }}
                            />
                            <br />
                                
                            {reasonForOffDay === 'others' && (
                                <Form.Input
                                    label={"Other Reason:"}
                                    size="medium"
                                    required={true}
                                    error={errors.address ? { content: errors.address } : null}
                                    onChange={(e) => {
                                        errors.address = null;
                                        setReasonForOffDayOthers(e.target.value);
                                    }}
                                />
                            )}

                            <Button
                                size="midi"
                                color="primary"
                                content="Add Off Day"
                                icon="send"
                                labelPosition="right"
                                onClick={handleSubmit}
                            />
                        </Form>
                        <br /> <br />
                        <h5>Off Days Log</h5>
                        <Table basic="" size="large" striped celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                                    <Table.HeaderCell>End Date</Table.HeaderCell>
                                    <Table.HeaderCell>Duration</Table.HeaderCell>
                                    <Table.HeaderCell>Reason</Table.HeaderCell>
                                    <Table.HeaderCell>Approved By</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {holidayOffDaysLog && holidayOffDaysLog.slice().reverse().map((report, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            {formatToBritishFormat(report.offDayStarts)}
                                        </Table.Cell>
                                        <Table.Cell>{formatToBritishFormat(report.offDayEnds)}</Table.Cell>
                                        <Table.Cell>
                                            {report.duration}
                                        </Table.Cell>
                                        <Table.Cell>{report.reasonForOffDay}</Table.Cell>
                                        <Table.Cell>{report.approvedBy}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid>
            </div>
        </AdminDashLayout>
    </>);
};

export default ManageWorkerHolidayOffDays;
