import React, { useEffect, useState, useRef } from "react";
import AuthService from "../auth";
import AdminDashLayout from "../components/AdminDashLayout";
import {
  Grid,
  Dropdown,
  Form,
  Button,
  Card,
  TextArea,
} from "semantic-ui-react";
import { calculateHours, dayOptions, hadLunchOptions } from "../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import sendApiRequest from "../api";
import { toast } from "react-toastify";
import { getDatesInWeekOfMonth } from "../utils";
import SignatureCanvas from 'react-signature-canvas';

const daysMap = {
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
  Sun: "Sunday"
};

const getFullDayName = (shortDay) => daysMap[shortDay] || shortDay;

function UpdateTimesheet() {
  const { id } = useParams();

  const user = AuthService.getCurrentUser();
  const [loading, setLoading] = useState(false);

  // Use the useLocation hook to access the location object
  const location = useLocation();

  // Extract the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get a specific query parameter by name
  const week = queryParams.get("week");

  const sigPadStaff = useRef({});

  const [formData, setFormData] = useState({
    signedStaff: "",
});

  const navigate = useNavigate();

  const [clientsOptions, setClients] = useState("");

  const [hideSaveButton, setHideSaveButton] = useState(false)

  const [timesheet, setTimesheet] = useState({});
  const [clientEntries, setClientEntries] = useState([]);
  const [day, setDay] = useState("");
  const [daysInWeek, setDaysInWeek] = useState([]);
  const [statusData, setStatusData] = useState({
    day: "",
    vehicle: {
      registration: "",
      mileage: "",
      items: [
        { name: "Oil", condition: "" },
        { name: "Water", condition: "" },
        { name: "Tyres", condition: "" },
      ],
    },
    damageToTools: "",
    ppeChecks: {
      items: [
        { name: "Fire Extinguisher", status: "" },
        { name: "Safety Footwear", status: "" },
        { name: "Hi-Vis Vest", status: "" },
        { name: "Googles", status: "" },
        { name: "Hard Hat", status: "" },
        { name: "Ear Defenders", status: "" },
      ],
    },
  });

  const [weeksActivities, setWeeksActivities] = useState({
    day: "",
    lunch: [],
    clients: [],
    endTimes: [],
    projects: [],
    startTimes: [],
    totalHours: [],
  });

  useEffect(() => {
    sendApiRequest("get", "/clients", localStorage.getItem("token")).then(
      (res) => {
        // Extract the "data" array from the API response
        const responseData = res.data;

        // Transform the data to the desired format
        const transformedData = responseData.map((item) => ({
          key: item.id,
          text: item.clientName,
          value: item.clientName,
        }));

        setClients(transformedData);
      }
    );
  }, [1]);

  const fetchData = async (d) => {
    // Make an API request to fetch timesheet data based on the 'id' parameter
    await sendApiRequest("get", `/timesheet/fetch?id=${id}`)
      .then((data) => {
        // Update the component state with the fetched data
        setTimesheet(data.data);

        // Initialize clientEntries with data from the API response
        const initialClientEntries = data.data.timesheetWeeks.weeks.map(
          (week) => {
            // Assuming you want to filter based on a specific weekCount
            const weekQueryParam = queryParams.get("week");
            const targetWeekCount = weekQueryParam;
            console.log(week);

            if (week.weekCount == targetWeekCount) {
              const filteredDay = week.days.filter((r) => r.day == d);
              
              const dayOptions = week.days.map((day, index) => {
                const date = new Date(day.dayDate);
                const currentDay = date.getDate();
                const month = date.toLocaleString("en-US", { month: "short" });
                const monthLong = date.toLocaleString("en-US", { month: "long" });
                const year = date.getFullYear();

                const formattedDate = `${currentDay} ${month}, ${year}`;

                return {
                  key: index + 1,
                  text: `${getFullDayName(day.day)} - ${formattedDate}`,
                  value: day.day,
                  selected: false
                }
              });

              console.log(dayOptions);
              setDaysInWeek(dayOptions);

              setStatusData(week);
              setDay(d);

              if (filteredDay.length == 0) {
                return [
                  [
                    {
                      client: "",
                      jobCarriedOut: "",
                      startTime: new Date(),
                      hadLunch: "",
                      endTime: new Date(),
                      totalHours: "",
                    },
                ],
                ];
              }

              return filteredDay.map((day) => {
                return day.clients.map((client, index) => {

                  const startTime = day.startTimes[index] || new Date();
                  const endTime = day.endTimes[index] || new Date();
                  let totalHours = calculateHours(startTime, endTime);

                  // Check if "hadLunch" is set to "YES," and deduct 30 minutes (0.5 hours)
                  if (day.lunch[index] === 'YES') {
                    totalHours -= 0.5;
                  }

                  // Format total hours as a string with "hrs"

                  return {
                    day: d,
                    client: client || "",
                    jobCarriedOut: day.projects[index] || "",
                    startTime: startTime,
                    hadLunch: day.lunch[index] || "",
                    endTime: endTime,
                    totalHours: totalHours || "",
                  };

                });
              });

            }
          }
        );

        const init = initialClientEntries.filter((r) => r !== undefined);

        const c = init[0][0];

        setClientEntries(c);
      })
      .catch((error) => {
        console.error("Error fetching timesheet data:", error);
      });
  };

  useEffect(() => {
    fetchData(day);
  }, [1]);

  const fetchNewDataBasedOnDay = async (e) => {
    await fetchData(e);
  };

  const handleAddClient = () => {
    setClientEntries([
      ...clientEntries,
      {
        client: "",
        jobCarriedOut: "",
        startTime: new Date(),
        hadLunch: "",
        endTime: new Date(),
        totalHours: "",
        projects: "",
      },
    ]);
  };

  useEffect(() => {
    const updatedWeeksActivities = {
      ...weeksActivities,
      day: day,
      lunch: clientEntries.map((item) => item.hadLunch),
      clients: clientEntries.map((item) => item.client),
      endTimes: clientEntries.map((item) => item.endTime),
      projects: clientEntries.map((item) => item.jobCarriedOut),
      startTimes: clientEntries.map((item) => item.startTime),
      totalHours: clientEntries.map((item) => calculateHours(item.startTime, item.endTime, item.hadLunch).toFixed(1)),
    };

    setWeeksActivities(updatedWeeksActivities);
  }, [clientEntries]);

  const handleRemoveClient = (index) => {
    const updatedClients = [...clientEntries];
    updatedClients.splice(index, 1);
    setClientEntries(updatedClients);
  };

  const handleClientFieldChange = (index, field, value) => {
    const updatedClients = [...clientEntries];
    updatedClients[index][field] = value;
    setClientEntries(updatedClients);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const newStatusData = {
      vehicle: {
        registration: statusData.vehicle.registration,
        mileage: statusData.vehicle.mileage,
        items: statusData.vehicle.items,
      },
      damageToTools: statusData.damageToTools,
      ppeChecks: {
        items: statusData.ppeChecks.items.map((item) => ({
          name: item.name,
          status: item.status,
          color: item.status === "Yes" ? "green" : "red",
        })),
      },
      signature: user.firstname,
    };

    const weekData = {
      weekCount: parseInt(week), // Adjust this value as needed
      days: weeksActivities,
      ...newStatusData,
    };

    console.log(weekData)

    const req = await sendApiRequest(
      "post",
      "/timesheet/update/" + id,
      weekData
    );

    toast("Timesheet Updated");
    setLoading(false);
    setTimeout(() => {
      navigate("/dashboard/timesheet/view/" + id + "/?week=" + week);
    }, 1000);
  };

  const handleSubmitFromParent = async () => {
    let signedStaff;

    setHideSaveButton(true)
    setLoading(true)

    if (sigPadStaff.current.isEmpty()) {
        toast.error("Staff signature is required.");
        return;
    } else {
        signedStaff = sigPadStaff.current.getTrimmedCanvas().toDataURL('image/png');
    }

    console.log(signedStaff);

    toast("Processing...Please wait...")

    const weekQueryParam = queryParams.get("week");
    const targetWeekCount = weekQueryParam;

    const data = {
        "weekCount": targetWeekCount,
        "staffSignature": signedStaff,
    }

    console.log(data)

    const r = await sendApiRequest('post', '/timesheet/sign/' + id, data);
    console.log(r)

    toast(r.message || "error, try again")

    setHideSaveButton(false)
    setLoading(false)
};

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <Grid>
            <Grid.Column floated="left" width={8}>
              <h2>
                <span style={{ color: "rgb(167, 167, 167)" }}>
                  <Link to={"/dashboard/timesheet"}>
                    Timesheet / {timesheet.month} {timesheet.year}
                  </Link>{" "}
                  /{" "}
                  <Link
                    to={"/dashboard/timesheet/view/" + id + "/?week=" + week}
                  >
                    Week {week} /
                  </Link>
                </span>{" "}
                Update
              </h2>
            </Grid.Column>
          </Grid>
          <div style={{ paddingTop: 30, paddingRight: 20 }}>
            <Grid>
              <Grid.Column width={8} floated="left">
                <Form loading={loading ? true : false}>
                  <label>Day</label>
                  <Dropdown
                    placeholder="Choose"
                    fluid
                    search
                    selection
                    // options={dayOptions}
                    options={daysInWeek}
                    onChange={(e, { value }) => {
                      setStatusData({
                        ...statusData,
                        day: value,
                      });
                      setDay(value);
                      fetchNewDataBasedOnDay(value);
                    }}
                    value={day}
                  />

                  {day !== '' && (
                    <>
                    {clientEntries &&
                    clientEntries.map((client, index) => (
                      <div key={index}>
                        <br />
                        <label>Client {index + 1}</label>
                        <br />
                        <Dropdown
                          placeholder="Choose"
                          fluid
                          search
                          selection
                          options={clientsOptions}
                          onChange={(e, { value }) => {
                            handleClientFieldChange(index, "client", value);
                          }}
                          value={client.client}
                        />
                        <br />
                        <Form.TextArea
                          style={{ minHeight: 50 }}
                          label="Job Carried Out"
                          placeholder="Type here"
                          onChange={(e) =>
                            handleClientFieldChange(
                              index,
                              "jobCarriedOut",
                              e.target.value
                            )
                          }
                          value={client.jobCarriedOut}
                        />
                        <div>
                          <label>Start Time</label>
                          <br />
                          <DatePicker
                            selected={new Date(client.startTime)}
                            onChange={(time) =>
                              handleClientFieldChange(index, "startTime", time)
                            }
                            showTimeSelect
                            
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <br />
                        <label>Had Lunch?</label>
                        <Dropdown
                          placeholder="Choose"
                          fluid
                          search
                          selection
                          options={hadLunchOptions}
                          onChange={(e, { value }) =>
                            handleClientFieldChange(index, "hadLunch", value)
                          }
                          defaultValue={client.hadLunch}
                        />
                        <br />
                        <div>
                          <label>End Time</label>
                          <br />
                          <DatePicker
                            selected={new Date(client.endTime)}
                            onChange={(time) =>
                              handleClientFieldChange(index, "endTime", time)
                            }
                            showTimeSelect
                            
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                          />
                        </div>
                        {index !== 0 && (
                          <>
                            <br />
                            <Button
                              color="red"
                              content="Remove Client"
                              icon="trash"
                              labelPosition="right"
                              onClick={() => handleRemoveClient(index)}
                            />
                            <br /> <br />
                          </>
                        )}
                        {index === clientEntries.length - 1 && (
                          <>
                            <br />
                            <Button
                              color="green"
                              content="Add Client"
                              icon="plus"
                              labelPosition="right"
                              onClick={handleAddClient}
                            />
                          </>
                        )}
                      </div>
                    ))}
                    </>
                  )}
                  

                  <br />

                  <Card fluid style={{ padding: 20 }}>
                    <Card.Header>Vehicle Status</Card.Header>
                    <Card.Content style={{ border: "none" }}>
                      <Grid>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Input
                            type="text"
                            onChange={(e) => {
                              const newStatusData = { ...statusData };
                              newStatusData.vehicle.registration =
                                e.target.value;
                              setStatusData(newStatusData);
                            }}
                            style={{ marginLeft: "-10px" }}
                            label="Vehicle Registration"
                            value={statusData.vehicle.registration}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Input
                            type="text"
                            onChange={(e) => {
                              const newStatusData = { ...statusData };
                              newStatusData.vehicle.mileage = e.target.value;
                              setStatusData(newStatusData);
                            }}
                            style={{ marginLeft: "-10px" }}
                            label="Vehicle Mileage"
                            value={statusData.vehicle.mileage}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Input
                            onChange={(e) => {
                              const newStatusData = { ...statusData };
                              newStatusData.vehicle.items[0].condition =
                                e.target.value;
                              setStatusData(newStatusData);
                            }}
                            type="text"
                            style={{ marginLeft: "-10px" }}
                            label="Oil Condition"
                            value={statusData.vehicle.items[0].condition}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Input
                            onChange={(e) => {
                              const newStatusData = { ...statusData };
                              newStatusData.vehicle.items[1].condition =
                                e.target.value;
                              setStatusData(newStatusData);
                            }}
                            type="text"
                            style={{ marginLeft: "-10px" }}
                            label="Water Condition"
                            value={statusData.vehicle.items[1].condition}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Input
                            onChange={(e) => {
                              const newStatusData = { ...statusData };
                              newStatusData.vehicle.items[2].condition =
                                e.target.value;
                              setStatusData(newStatusData);
                            }}
                            type="text"
                            style={{ marginLeft: "-10px" }}
                            label="Tyre Condition"
                            value={statusData.vehicle.items[2].condition}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <label>Damage to Company Tools</label>
                          <TextArea
                            type="text"
                            onChange={(e) => {
                              const newStatusData = { ...statusData };
                              newStatusData.damageToTools = e.target.value;
                              setStatusData(newStatusData);
                            }}
                            style={{ marginLeft: "-10px" }}
                            label="Damage to Company Tools"
                            value={statusData.damageToTools}
                          />
                        </Grid.Column>
                      </Grid>
                    </Card.Content>
                  </Card>

                  <br />

                  <Card fluid style={{ padding: 20 }}>
                    <Card.Header>Further Status / PPE Checks</Card.Header>
                    <Card.Content style={{ border: "none" }}>
                      <Grid>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Select
                            onChange={(e, { value }) => {
                              const newStatusData = { ...statusData };
                              newStatusData.ppeChecks.items[0].status = value;
                              newStatusData.ppeChecks.items[0].color =
                                value == "Yes" ? "green" : "red";
                              setStatusData(newStatusData);
                            }}
                            value={statusData.ppeChecks.items[0].status}
                            style={{ marginLeft: "-10px" }}
                            label="Fire Extinguisher"
                            options={[
                              {
                                id: 1,
                                text: "Yes",
                                value: "Yes",
                              },
                              {
                                id: 2,
                                text: "No",
                                value: "No",
                              },
                            ]}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Select
                            onChange={(e, { value }) => {
                              const newStatusData = { ...statusData };
                              newStatusData.ppeChecks.items[1].status = value;
                              newStatusData.ppeChecks.items[1].color =
                                value == "Yes" ? "green" : "red";
                              setStatusData(newStatusData);
                            }}
                            value={statusData.ppeChecks.items[1].status}
                            type="text"
                            style={{ marginLeft: "-10px" }}
                            label="Safety Footwear"
                            options={[
                              {
                                id: 1,
                                text: "Yes",
                                value: "Yes",
                              },
                              {
                                id: 2,
                                text: "No",
                                value: "No",
                              },
                            ]}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Select
                            onChange={(e, { value }) => {
                              const newStatusData = { ...statusData };
                              newStatusData.ppeChecks.items[2].status = value;
                              newStatusData.ppeChecks.items[2].color =
                                value == "Yes" ? "green" : "red";
                              setStatusData(newStatusData);
                            }}
                            value={statusData.ppeChecks.items[2].status}
                            type="text"
                            style={{ marginLeft: "-10px" }}
                            label="Hi-Vis Vest"
                            options={[
                              {
                                id: 1,
                                text: "Yes",
                                value: "Yes",
                              },
                              {
                                id: 2,
                                text: "No",
                                value: "No",
                              },
                            ]}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Select
                            type="text"
                            onChange={(e, { value }) => {
                              const newStatusData = { ...statusData };
                              newStatusData.ppeChecks.items[3].status = value;
                              newStatusData.ppeChecks.items[3].color =
                                value == "Yes" ? "green" : "red";
                              setStatusData(newStatusData);
                            }}
                            value={statusData.ppeChecks.items[3].status}
                            style={{ marginLeft: "-10px" }}
                            label="Goggles"
                            options={[
                              {
                                id: 1,
                                text: "Yes",
                                value: "Yes",
                              },
                              {
                                id: 2,
                                text: "No",
                                value: "No",
                              },
                            ]}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Select
                            type="text"
                            onChange={(e, { value }) => {
                              const newStatusData = { ...statusData };
                              newStatusData.ppeChecks.items[4].status = value;
                              newStatusData.ppeChecks.items[4].color =
                                value == "Yes" ? "green" : "red";
                              setStatusData(newStatusData);
                            }}
                            value={statusData.ppeChecks.items[4].status}
                            style={{ marginLeft: "-10px" }}
                            label="Gloves"
                            options={[
                              {
                                id: 1,
                                text: "Yes",
                                value: "Yes",
                              },
                              {
                                id: 2,
                                text: "No",
                                value: "No",
                              },
                            ]}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Select
                            type="text"
                            onChange={(e, { value }) => {
                              const newStatusData = { ...statusData };
                              newStatusData.ppeChecks.items[5].status = value;
                              newStatusData.ppeChecks.items[5].color =
                                value == "Yes" ? "green" : "red";
                              setStatusData(newStatusData);
                            }}
                            value={statusData.ppeChecks.items[5].status}
                            style={{ marginLeft: "-10px" }}
                            label="Hard Hat"
                            options={[
                              {
                                id: 1,
                                text: "Yes",
                                value: "Yes",
                              },
                              {
                                id: 2,
                                text: "No",
                                value: "No",
                              },
                            ]}
                          />
                        </Grid.Column>
                        <Grid.Column fluid width={8} floated="left">
                          <Form.Select
                            type="text"
                            onChange={(e, { value }) => {
                              const newStatusData = { ...statusData };
                              newStatusData.ppeChecks.items[6].status = value;
                              newStatusData.ppeChecks.items[6].color =
                                value == "Yes" ? "green" : "red";
                              setStatusData(newStatusData);
                            }}
                            value={statusData.ppeChecks.items[6]?.status}
                            style={{ marginLeft: "-10px" }}
                            label="Ear Defenders"
                            options={[
                              {
                                id: 1,
                                text: "Yes",
                                value: "Yes",
                              },
                              {
                                id: 2,
                                text: "No",
                                value: "No",
                              },
                            ]}
                          />
                        </Grid.Column>
                      </Grid>
                      <h5>
                        If you answer "NO" to any of the options above, please
                        promptly notify the Office for a replacement.
                      </h5>
                    </Card.Content>
                  </Card>

                  <br />
                  <label style={{ color: "#044f66", fontSize: "20px" }}>Staff Signature</label>
                  {statusData.signature !== "" && (
                    <Grid.Column width={9}>
                      <img src={statusData.signature} alt="staff signature"/>
                    </Grid.Column>
                  )}

                  {statusData.signature === "" && (
                      <Grid.Column width={9}>
                        <SignatureCanvas
                            ref={sigPadStaff}
                            penColor='black'
                            canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                            style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
                        />

                        <Grid style={{marginTop: "10px"}}>
                          <Button size="mini" color="red" onClick={() => sigPadStaff.current.clear()}>
                            Clear
                          </Button>
    
                          {!hideSaveButton && (
                            <>
                              <Button color="primary" onClick={handleSubmitFromParent}>
                                Save Signature
                              </Button>
                            </>
                          )}
                        </Grid>
                    </Grid.Column>
                  )}

                  <Grid>
                    <Grid.Column width={9}></Grid.Column>
                    <Grid.Column width={4} floated={"right"}>
                      <h5>Signed: [{user.firstname}]</h5>
                    </Grid.Column>
                  </Grid>
                  <br />

                  <Button
                    size="midi"
                    color="primary"
                    content="Save Changes"
                    icon="cloud"
                    labelPosition="right"
                    onClick={handleSubmit}
                  />
                </Form>
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </AdminDashLayout>
    </>
  );
}

export default UpdateTimesheet;
