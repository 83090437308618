import { Form, Grid, Checkbox, Button, TextArea, Dropdown } from "semantic-ui-react";
import React, { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import sendApiRequest from "../api";
import { toast } from "react-toastify";
import { formatToYYYYMMDD , formatToBritishFormat} from "../utils"
import SignatureCanvas from 'react-signature-canvas';

const ViewProjectLandlordCertLogs = ({ project, defaultData, onSubmitFromParent }) => {

  console.log('d', defaultData)

  const [staffOptions, setStaffs] = useState([]);

  useEffect(() => {
    // sendApiRequest("get", "/clients", localStorage.getItem("token")).then(
    //   (res) => {
    //     // Extract the "data" array from the API response
    //     const responseData = res.data;

    //     // Transform the data to the desired format
    //     const transformedData = responseData.map((item) => ({
    //       key: item.id,
    //       text: item.clientName,
    //       value: item.id,
    //     }));

    //     setClients(transformedData);
    //   }
    // );

    sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
      (res) => {
        const staffData = res.data
          .filter((worker) => worker.role === 2 || worker.role === 1)
          .map((workers) => ({
            key: workers.id,
            text: workers.firstname,
            value: workers.firstname,
          }))

        setStaffs(staffData);
        console.log('staffs', staffData)
      }
    );
  }, []);
  const approveLog = async () => {
    try {
      const id = project.id; // Assuming the first log is the one to be approved
      const approvalStatus = "approved"; // Assuming the approval status to be set to "approved"

      const data = defaultData[0];

      defaultData[0].status = approvalStatus

      const d = {
        "projectLandlordCertLogs": defaultData
      }

      console.log(d)

      const response = await sendApiRequest('post', '/projects/edit/' + id, d);

      toast.success("Log approved successfully.");
      onSubmitFromParent(); // Assuming this function updates the parent state or triggers a refresh

    } catch (error) {
      console.error("Error approving log:", error);
      toast.error("An error occurred while approving the log.");
    }
  };

  return (
    <div style={{ overflow: "scroll", height: "160px" }}>
      <div
        className={"grp-view-page-further-checks-card"}
        style={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>Date Reported</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>Staff</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>Status</th>
          <th style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>Approval</th>
        </tr>
      </thead>
      <tbody>
        {defaultData && defaultData.slice(0, 1).map((log) => (
          <tr key={log.id}>
            <td style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>{formatToBritishFormat(log.date)}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>{log.staff}</td>
            <td style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>
              <div style={{ textAlign: 'center' }}>
                {log.status === 'review' ? 'Report submitted, pending approval.' : log.status}
              </div>
              
              <div style={{ textAlign: 'center' }}>
                <Button color="primary" as='a' href={`${log.pdf_link}`} target="_blank" size="mini">Download PDF</Button>
              </div>
            </td>
            <td style={{ border: '1px solid #ddd', padding: '8px', color: '#000' }}>
              <Button color="green" onClick={() => approveLog(log.id)}>Approve</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    
    </div>
    </div>
  );
};

export default ViewProjectLandlordCertLogs;
