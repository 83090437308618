import AuthService from "../auth";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Grid } from "semantic-ui-react";
import ProjectCards from "../components/ProjectCards";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import sendApiRequest from "../api";
import { fetchProjectsByClient } from "../apiCalls";

function ClientsProjects() {
  const user = AuthService.getCurrentUser();
  const user_role = user.role;

  const { id } = useParams()

  const [projects, setProjects] = useState([])
  const [client, setClient] = useState([])
  const [projectsCount, setProjectsCount] = useState(0)
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProjectsByClient(id, page, pageSize, localStorage.getItem('token')).then(data => {

      setLoading(false)
      if (data) {
        setProjectsCount(data.data.projects.length);
        setProjects(data.data.projects);
        setClient(data.data.client)
      }
    })
  }, [page, pageSize]);

  const handlePrevPageChange = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPageChange = () => {
    const maxPage = Math.ceil(projectsCount / pageSize);
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const canPaginate = () => {
    if (projectsCount > 20) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <div className="projects-container">
            <Grid>
              <Grid.Column mobile={16} computer={11} floated="left">
                <h3>Projects - {client.clientName}</h3>
                <h4 className="projects-subtitle">
                  Displaying {projectsCount} {projectsCount === 1 ? 'project' : 'projects'}
                </h4>
              </Grid.Column>
            </Grid>
          </div>
          <br />
          <Form loading={loading ? true : false}>
            <ProjectCards
              count={projectsCount}
              projects={projects}
              onPrevPageChange={handlePrevPageChange}
              onNextPageChange={handleNextPageChange}
              canPaginate={canPaginate}
              defaultStatus=""
            />
          </Form>
        </div>
      </AdminDashLayout>
    </>
  );
}

export default ClientsProjects;
