import { Form, Grid, Checkbox, Button, TextArea } from "semantic-ui-react";
import React, { useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import sendApiRequest from "../api";
import { toast } from "react-toastify";
import { formatToYYYYMMDD } from "../utils"
import SignatureCanvas from 'react-signature-canvas';
import { FaPlay } from "react-icons/fa6";

const SignDisclaimerComponent = ({ defaultData, onSubmitFromParent }) => {
    const [formData, setFormData] = useState({
        // Add more form fields as needed
        description: '',
        signedEngineer: "",
        signedClient: "",
    });

    const [hideSaveButton, setHideSaveButton] = useState(false)
    const [loading, setLoading] = useState(false)

    const id = defaultData.id;

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const sigPadClient = useRef({});
    const sigPadEngineer = useRef({});

    const handleDateChange = (date, section) => {
        setFormData((prevData) => ({
            ...prevData,
            [section]: date.toISOString(),
        }));
    };

    const handleSubmitFromParent = async () => {
        let signedClient, signedEngineer, description;

        setHideSaveButton(true)
        setLoading(true)

        if (sigPadClient.current.isEmpty()) {
            toast.error("Client signature is required.");
            return;
        } else {
            signedClient = sigPadClient.current.getTrimmedCanvas().toDataURL('image/png');
        }

        if (sigPadEngineer.current.isEmpty()) {
            toast.error("Engineer signature is required.");
            return;
        } else {
            signedEngineer = sigPadEngineer.current.getTrimmedCanvas().toDataURL('image/png');
        }

        if (!formData.description.trim()) {
            toast.error("Description is required.");
            return;
        } else {
            description = formData.description;
        }


        toast("Processing..please wait.....")

        const data = {
            "description": description,
            "employeeSignature": signedEngineer,
            "clientSignature": signedClient,
        }

        console.log(data)

        const r = await sendApiRequest('post', '/projects/disclaimer/sign/' + id, data);
        console.log(r)

        toast(r.message || "error, try again")

        setHideSaveButton(false)
        setLoading(false)

        onSubmitFromParent()
    };

    return (
        <Form loading={loading}>
            <div style={{ overflow: "scroll", height: "550px" }}>
                <div
                    className={"grp-view-page-further-checks-card"}
                    style={{
                        borderRadius: "10px",
                        padding: "20px",
                        background: "white"
                    }}
                >
                    <span className={"primary-color"} style={{ fontSize: 18 }}>
                        Brief Description Of Out Of Scope Work
                    </span>
                    <br />
                    <br />
                    <Grid>
                        <Grid.Column width={16}>
                            <TextArea
                                size="medium"
                                name="description"
                                placeholder=""
                                style={{
                                    width: "100%",
                                    height: 150,
                                    border: "1.5px solid #044f66",
                                    borderRadius: "5px",
                                    padding: "10px",
                                }}
                                value={formData.description}
                                onChange={(e) =>
                                    handleChange(
                                        { target: { name: "description", value: e.target.value } },
                                        "projectJobReports"
                                    )
                                }
                            />
                        </Grid.Column>
                        <p color="black" style={{ color: "black" }}><b>Disclaimer:</b> Please be advised that any tasks or services performed beyond the scope of the current job at the client's request are not covered under our standard liability provisions.
                            <br />
                            While we strive to provide high-quality services and ensure client satisfaction, any additional works beyond what has been agreed will be considered out of scope and may carry/inherent risks. It is important to note that engaging in works outside the original scope may involve unforeseen challenges, complexities, and potential liabilities.
                            <br /><br />
                            We strongly recommend that clients/customers carefully consider the implications of requesting additional services beyond what was initially agreed. GD cannot guarantee the outcomes or results of tasks undertaken outside the original scope, nor can GD be held liable for any damages, losses, or disruptions that may arise as a result of such works.
                            <br />
                            Clients/Customers are encouraged to communicate openly and transparently with us regarding their project requirements and any potential changes or expansions to the scope of work.
                            <br /><br />
                            Prior to initiating any works outside the original scope, it is advisable to consult with our team to assess feasibility, risks, and potential impact on project timelines and budgets.
                            <br />
                            By acknowledging this disclaimer, clients understand and accept that any works undertaken beyond the scope are at their own risk, and GD Heating & Gas shall not be held liable for any associated damages or liabilities.
                            <br />
                            Please feel free to reach out to us should you have any questions or concerns regarding this disclaimer.
                            <br /><br />
                            <FaPlay color="#05587f" size={"20px"} /> Watch Video
                            <br /><br />
                            <video src="https://gdheatingandgas.b-cdn.net/disclaimer_1080hd.MP4#t=1" controls style={{ "borderRadius": "10px" }} placeholder="" width={"100%"} />
                            <br /><br />
                        </p>
                        <Grid.Column width={8}>
                            <label className="primary" color="primary" style={{ color: "#044f66", fontSize: "20px" }}>Client Signature</label>
                            <SignatureCanvas
                                ref={sigPadClient}
                                penColor='black'
                                canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                                style={{ border: "1.5px solid #000", borderRadius: "5px" }}
                            />
                            <Button size="mini" color="red" onClick={() => sigPadClient.current.clear()}>
                                Clear
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <label style={{ color: "#044f66", fontSize: "20px" }}>Employee Signature</label>
                            <SignatureCanvas
                                ref={sigPadEngineer}
                                penColor='black'
                                canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }}
                                style={{ border: "1.5px solid #044f66", borderRadius: "5px" }}
                            />
                            <Button size="mini" color="red" onClick={() => sigPadEngineer.current.clear()}>
                                Clear
                            </Button>
                        </Grid.Column>

                    </Grid>
                    <br />
                    {!hideSaveButton && (
                        <>
                            <Button color="primary" onClick={handleSubmitFromParent}>
                                Save Signatures
                            </Button>
                        </>
                    )}
                </div>



            </div>
        </Form>
    );
};

export default SignDisclaimerComponent;
