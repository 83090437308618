import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapsComponent = ({ apiKey, latitude, longitude }) => {
    const mapContainerStyle = {
        width: '660px',
        height: '600px',
    };

    const center = {
        lat: latitude,
        lng: longitude,
    };

    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={10}
            >
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
};

export default MapsComponent;
