import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Grid, Breadcrumb, Message, Checkbox } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sendApiRequest from "../api";
import { formatToYYYYMMDD } from "../utils";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateSmallProject = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [files, setFiles] = React.useState([]);
    const [clientsOptions, setClients] = useState([]);
    const [milestones, setMilestones] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            assignedTo: [],
            visitName: "",
        },
    ]);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [clientName, setClientName] = useState("")
    const [client, setClient] = useState("");
    const [clientAddress, setClientAddress] = useState("");
    const [assignedTo, setAssignedTo] = useState("");
    const [description, setDescription] = useState("");
    const [endDate, setEndDate] = useState(new Date());
    const [clientPhoneNumber, setClientPhoneNumber] = useState("+44")
    const [clientEmailAddress, setClientEmailAddress] = useState("")

    const [startTime, setStartTime] = useState(new Date());
    const [sendClientNotification, setSendClientNotification] = useState(false);

    const [errors, setErrors] = useState({ email: "", password: "" });
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");

    const [staffOptions, setStaffs] = useState([]);

    useEffect(() => {
        sendApiRequest("get", "/clients", localStorage.getItem("token")).then(
            (res) => {
                // Extract the "data" array from the API response
                const responseData = res.data;

                const filteredData = responseData.filter(item => item.client_type === 'small');

                // Transform the filtered data to the desired format
                const transformedData = filteredData.map((item) => ({
                    key: item.id,
                    text: item.clientName,
                    value: item.id,
                })).sort((a, b) => a.text.localeCompare(b.text)); // Sort alphabetically by clientName
  
                setClients(transformedData);
            }
        );

        sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
            (res) => {
                const staffData = res.data
                    .filter((worker) => worker.role === 1 || worker.role === 2)
                    .map((workers) => ({
                        key: workers.id,
                        text: workers.firstname,
                        value: workers.id,
                    }))

                setStaffs(staffData);
            }
        );
    }, []);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: Add smooth scrolling behavior
        });
    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button type="button" className="example-custom-input" style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const handleSubmit = () => {

        scrollToTop()

        const formData = {
            is_small_project: 0,
            send_client_notif: sendClientNotification,
            projectStartTime: startTime,
            projectName: name,
            clientId: client,
            clientName: clientName,
            clientAddress: clientAddress,
            clientPhoneNumber: clientPhoneNumber,
            clientEmailAddress: clientEmailAddress,
            assignedToStaffsId: assignedTo,
            projectDesc: description,
            projectStartDate: formatToYYYYMMDD(startDate || new Date()),
            projectEndDate: formatToYYYYMMDD(endDate || new Date()),
        };

        // console.log(parentMilestones);   

        // Login request
        (async () => {
            try {

                // Construct formData here, including the photoUri if it was set
                const apiData = {
                    ...formData,
                };

                // Send the API request
                const postData = await sendApiRequest(
                    "post",
                    "/projects/create",
                    apiData,
                    localStorage.getItem("token")
                );

                if (postData !== null) {
                    setLoading(false);
                    // Handle API response here
                    if (postData.errors || postData.error) {
                        // Handle errors
                        setApiErrors(true);
                        setApiSuccess(false);
                        const errors = postData.errors || [postData.errors];
                        const errorMsg = errors.map((e) => e.msg);
                        setApiErrorMessage(errorMsg);
                    } else if (postData.message === "success") {
                        // Handle success
                        setApiSuccess(true);
                        setApiSuccessMessage("Project Created Successfully");
                        // Reset your form state here if needed
                        // setName('')
                        // setClient('')
                        // setClientAddress('')
                        // setAssignedTo('')
                        // setDescription('')
                        // setStartDate('')
                        // setEndDate('')
                    }
                } else {
                    // Handle API request error
                    setApiErrorMessage([
                        "Error occurred while submitting data to the API.",
                    ]);
                    setApiErrors(true);
                    setApiSuccess(false);
                }
            } catch (error) {
                console.log('error in submitting')
                // Handle any unexpected errors
                setApiErrorMessage([
                    "Error occurred while submitting data to the API.",
                ]);
                setApiErrors(true);
                setApiSuccess(false);
                console.error(error);
            } finally {
                // Ensure setLoading is set to false
                setLoading(false);
            }
        })();

        // setTimeout(() => {
        //   setLoading(false);
        // }, 1000);
    };

    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 40 }}>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Link to={"/dashboard/projects"}>
                            <Breadcrumb.Section link>Projects</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        <Breadcrumb.Section>Create Small Project</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
                <div style={{ paddingTop: 30, paddingRight: 20 }}>
                    <Grid>
                        <Grid.Column width={8} floated="left">
                            {apiErrors ? (
                                <Message error header="Whoops!" list={apiErrorMessage} />
                            ) : null}

                            {apiSuccess ? (
                                <Message
                                    success
                                    header="Success!"
                                    content={apiSuccessMessage}
                                />
                            ) : null}
                            <Form loading={loading ? true : false}>
                                <Form.Input
                                    size="medium"
                                    label="Project Name"
                                    placeholder="Enter Project Name"
                                    value={name}
                                    required={true}
                                    error={errors.name ? { content: errors.name } : null}
                                    onChange={(e) => {
                                        errors.name = null;
                                        setName(e.target.value);
                                    }}
                                />
                                <label>Client</label>
                             

                                <Dropdown
                                    placeholder="Select Client"
                                    fluid
                                    search
                                    selection
                                    options={clientsOptions}
                                    required={true}
                                    error={errors.clientId ? { content: errors.clientId } : null}
                                    onChange={(e, { value }) => {
                                        errors.clientId = null;
                                        setClient(value);
                                        const selectedClient = clientsOptions.find(option => option.value === value);
                                        setClientName(selectedClient ? selectedClient.text : "");
                                    }}
                                />
                                <br />

                                <Form.Input
                                    size="medium"
                                    label="Client Address / Job Address"
                                    placeholder="Enter Client/Job Address"
                                    value={clientAddress}
                                    required={true}
                                    error={
                                        errors.clientAddress
                                            ? { content: errors.clientAddress }
                                            : null
                                    }
                                    onChange={(e) => {
                                        errors.clientAddress = null;
                                        setClientAddress(e.target.value);
                                    }}
                                />



                                <label>Assigned To</label>
                                <Dropdown
                                    placeholder="Select Staff(s)"
                                    fluid
                                    multiple
                                    search
                                    selection
                                    options={staffOptions}
                                    required={true}
                                    error={
                                        errors.assignedToStaffsId
                                            ? { content: errors.assignedToStaffsId }
                                            : null
                                    }
                                    onChange={(e, { value }) => {
                                        errors.assignedToStaffsId = null;
                                        setAssignedTo(value);
                                    }}
                                />
                                <br />

                                <label>Project Description / Requirement</label>
                                <ReactQuill style={{ height: 300 }} theme="snow" value={description} onChange={setDescription} />
                                <br /> <br /><br />

                                <label style={{ paddingBottom: 20 }}>
                                    Project Start Date
                                </label>
                                <DatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    customInput={<ExampleCustomInput />}
                                />
                                <br />
                                <br />
                                <label style={{ paddingBottom: 20 }}>
                                    Project End Date
                                </label>
                                <DatePicker
                                    dateFormat={"dd/MM/yyyy"}
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    customInput={<ExampleCustomInput />}
                                />
                                <br />
                                <br />
                                <label style={{ paddingBottom: 20 }}>
                                    Project Start Time
                                </label>
                                <Dropdown
                                    placeholder="Select Time"
                                    fluid
                                    selection
                                    options={[
                                        { key: 'all_day', text: 'ALL DAY', value: 'ALL DAY' },

                                        { key: 'early_morning', text: 'Early Morning', value: 'Early Morning' },
                                        { key: 'mid_morning', text: 'Mid Morning', value: 'Mid Morning' },
                                        { key: 'late_morning', text: 'Late Morning', value: 'Late Morning' },
                                        { key: 'early_afternoon', text: 'Early Afternoon', value: 'Early Afternoon' },
                                        { key: 'mid_afternoon', text: 'Mid Afternoon', value: 'Mid Afternoon' },
                                        { key: 'late_afternoon', text: 'Late Afternoon', value: 'Late Afternoon' },

                                        { key: '8am', text: '8am', value: '8:00 AM' },
                                        { key: '9am', text: '9am', value: '9:00 AM' },
                                        { key: '10am', text: '10am', value: '10:00 AM' },
                                        { key: '11am', text: '11am', value: '11:00 AM' },
                                        { key: '12pm', text: '12pm', value: '12:00 PM' },
                                        { key: '1pm', text: '1pm', value: '1:00 PM' },
                                        { key: '2pm', text: '2pm', value: '2:00 PM' },
                                        { key: '3pm', text: '3pm', value: '3:00 PM' },
                                        { key: '4pm', text: '4pm', value: '4:00 PM' },
                                        { key: '5pm', text: '5pm', value: '5:00 PM' },

                                    ]}
                                    value={startTime}
                                    onChange={(e, { value }) => setStartTime(value)}
                                    customInput={<ExampleCustomInput />}
                                />
                                <br />
                                <div className="faint-teal">
                                    <Form.Field inline>
                                        <Checkbox
                                            toggle
                                            checked={sendClientNotification}
                                            onChange={() => setSendClientNotification(!sendClientNotification)}
                                        />
                                        <label style={{ marginLeft: '10px' }}>Send notification to client?</label>
                                    </Form.Field>
                                    {sendClientNotification && (
                                        <Form.Group widths="equal">
                                            <Form.Input
                                                fluid
                                                type="phone"
                                                label="Client Phone Number"
                                                placeholder="Client Phone Number"
                                                value={clientPhoneNumber}
                                                onChange={(e) => setClientPhoneNumber(e.target.value)}
                                            />
                                            <Form.Input
                                                fluid
                                                type="email"
                                                label="Client Email Address"
                                                placeholder="Client Email Address"
                                                value={clientEmailAddress}
                                                onChange={(e) => setClientEmailAddress(e.target.value)}
                                            />
                                        </Form.Group>
                                    )}
                                </div>

                                <br />
                                <Button
                                    size="midi"
                                    color="primary"
                                    content="Create Project"
                                    icon="plus"
                                    labelPosition="right"
                                    onClick={handleSubmit}
                                />
                            </Form>
                        </Grid.Column>
                    </Grid>
                </div>
            </AdminDashLayout>
        </>
    );
};

export default CreateSmallProject;
