import React, { useEffect, useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import {
  Button,
  Form,
  Grid,
  Breadcrumb,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { emailRegex, phoneRegex } from "../utils";
import sendApiRequest from "../api";
import moment from "moment";

const CreateVehicleLog = () => {
  const [loading, setLoading] = useState(false);

  const [numberPlate, setNumberPalte] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [currentDriver, setCurrentDriver] = useState("")
  const [motPass, setMotpass] = useState("")
  const [serviceDate, setServiceDate] = useState("")
  const [nextService, setNextService] = useState("")
  const [insuranceDue, setInsuranceDue] = useState("")

  const [errors, setErrors] = useState({ email: "", password: "" });
  const [apiErrors, setApiErrors] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [staffOptions, setStaffs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    sendApiRequest("get", "/workers", localStorage.getItem("token")).then(
      (res) => {
        const staffData = res.data.map((workers) => ({
          key: workers.firstname,
          text: workers.firstname,
          value: workers.firstname,
        }));

        setStaffs(staffData);
      }
    );
  }, [0]);

  const handleSubmit = () => {
    setLoading(true);
    setApiSuccess(false);
    setApiErrors(false);

    const formData = {
      numberPlate: numberPlate,
      make: make,
      model: model,
      year: year,
      mileage: mileage,
      currentDriver: currentDriver,
      motPass: motPass,
      service: serviceDate,
      nextService: nextService,
      insuranceDue: insuranceDue,
      meta: []
    };

    const motDueDate = moment(motPass).subtract(1, 'months').add(1, 'days').add(1, 'years').format('YYYY-MM-DD');
    formData.motDue = motDueDate;

    let newErrors;
    newErrors = {};

    if (formData.numberPlate == "") {
      newErrors.numberPlate = "Please enter the number plate";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    if (formData.make == "") {
      newErrors.make = "Please enter the make";
      setErrors(newErrors);
      setApiSuccess(false);
      setLoading(false);
      return;
    }

    // Login request
    (async () => {
      const postData = await sendApiRequest(
        "post",
        "/vehicle-logs/create",
        formData,
        localStorage.getItem("token")
      );
      if (postData !== null) {
        // console.log(postData);
        // any errors?
        if (postData.errors || postData.error) {
          setApiErrors(true);
          setApiSuccess(false);
          const errors = postData.errors;
          const errorMsg = [];

          if (postData.errors) {
            errors.forEach((e) => {
              errorMsg.push(`${e.path}: ${e.msg}`);
            });
          } else {
            errorMsg.push(postData.error);
          }

          setApiErrorMessage(errorMsg);
        }

        // if we got here, no errors.
        if (postData.message == 'success') {
          setApiSuccess(true);
          setApiSuccessMessage("Vehicle Record Created Successfully");

        }
      } else {
        setApiErrorMessage([
          "Error occurred while submitting data to the API.",
        ]);
        setApiErrors(true);
        setApiSuccess(false);
        setLoading(false);

        // console.log("Error occurred while submitting data to the API.");
      }
    })();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <AdminDashLayout>
        <div style={{ paddingTop: 40 }}>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/vehicle-log"}>
              <Breadcrumb.Section link>Vehicle Log</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section>Create A Vehicle</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          {apiErrors ? (
            <Message error header="Whoops!" list={apiErrorMessage} />
          ) : null}

          {apiSuccess ? (
            <Message success header="Success!" content={apiSuccessMessage} />
          ) : null}
          <Form loading={loading ? true : false}>
            <Grid>
              <Grid.Column
                width={5}
                className="grp-view-page-further-checks-card"
              >
                <h4>Part I: Vehicle Details</h4>
                <br />
                <Grid.Column width={4} floated="left">
                  <Form.Input
                    size="medium"
                    label="Number Plate"
                    placeholder="Enter Number Plate"
                    value={numberPlate}
                    error={errors.numberPlate ? { content: errors.numberPlate } : null}
                    onChange={(e) => {
                      errors.numberPlate = null;
                      setNumberPalte(e.target.value);
                    }}
                  />
                  <Form.Input
                    size="medium"
                    label="Make"
                    placeholder="Enter Make"
                    value={make}
                    error={errors.make ? { content: errors.make } : null}
                    onChange={(e) => {
                      errors.make = null;
                      setMake(e.target.value);
                    }}
                  />
                  <Form.Input
                    size="medium"
                    label="Model"
                    placeholder="Enter Model"
                    value={model}
                    error={errors.model ? { content: errors.model } : null}
                    onChange={(e) => {
                      errors.model = null;
                      setModel(e.target.value);
                    }}
                  />

                  <Form.Input
                    size="medium"
                    label="Year"
                    placeholder="Enter Year"
                    value={year}
                    error={errors.year ? { content: errors.year } : null}
                    onChange={(e) => {
                      errors.year = null;
                      setYear(e.target.value);
                    }}
                  />

                  <Form.Input
                    size="medium"
                    label="Mileage"
                    placeholder="Enter Mileage"
                    value={mileage}
                    error={errors.mileage ? { content: errors.mileage } : null}
                    onChange={(e) => {
                      errors.mileage = null;
                      setMileage(e.target.value);
                    }}
                  />

                  <label>Current Driver</label>
                  <br />
                  <Dropdown
                    labeled
                    placeholder="Current Driver"
                    fluid
                    search
                    selection
                    options={staffOptions}
                    error={
                      errors.currentDriver
                        ? { content: errors.currentDriver }
                        : null
                    }
                    onChange={(e, { value }) => {
                      errors.currentDriver = null;
                      setCurrentDriver(value);
                    }}
                  />
                </Grid.Column>
              </Grid.Column>
              <Grid.Column width={5}>
                <Grid.Column
                  width={4}
                  floated="left"
                  className="grp-view-page-further-checks-card"
                >
                  <h4>Part II: MOT & Insurance Details</h4>
                  <br />
                  <Form.Input
                    size="medium"
                    label="MOT Pass"
                    placeholder="Enter MOT Pass Date"
                    type="date"
                    value={motPass}
                    error={errors.motPass ? { content: errors.motPass } : null}
                    onChange={(e) => {
                      errors.motPass = null;
                      setMotpass(e.target.value);
                    }}
                  />
                  <Form.Input
                    size="medium"
                    label="Service"
                    placeholder="Enter Vehicle Service Date"
                    value={serviceDate}
                    type="date"
                    error={errors.serviceDate ? { content: errors.serviceDate } : null}
                    onChange={(e) => {
                      errors.serviceDate = null;
                      setServiceDate(e.target.value);
                    }}
                  />
                  <Form.Input
                    size="medium"
                    label="Next Service"
                    placeholder="Enter Next Service Date"
                    type="date"
                    value={nextService}
                    error={errors.nextService ? { content: errors.nextService } : null}
                    onChange={(e) => {
                      errors.nextService = null;
                      setNextService(e.target.value);
                    }}
                  />

                  <Form.Input
                    size="medium"
                    label="Insurance Due"
                    placeholder="Enter Insurance Due Date"
                    value={insuranceDue}
                    type="date"
                    error={errors.insuranceDue ? { content: errors.insuranceDue } : null}
                    onChange={(e) => {
                      errors.insuranceDue = null;
                      setInsuranceDue (e.target.value);
                    }}
                  />
                </Grid.Column>
              </Grid.Column>
            </Grid>
            <br />
            <br />
            <Button
              size="midi"
              color="primary"
              content="Create Vehicle"
              icon="plus"
              labelPosition="right"
              onClick={handleSubmit}
            />
          </Form>
        </div>
      </AdminDashLayout>
    </>
  );
};

export default CreateVehicleLog;
