import AuthService from "../auth";
import {Button, Grid} from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import GrpCards from "../components/GrpCards";
import AdminDashLayout from "../components/AdminDashLayout";
import {Link} from "react-router-dom";
import sendApiRequest from "../api";
import PipekitCards from "../components/PipekitCards";

function Pipekits() {
    const user = AuthService.getCurrentUser();

    const [data, setData] = useState([])
    const [page, setPage] = useState(1); // Current page number
    const [pageSize, setPageSize] = useState(20);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sendApiRequest("get", "/pipekits").then(r => {
            console.log(r.data)
            setData(r.data)
        })
    }, [page, pageSize])

    const handlePrevPageChange = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPageChange = () => {
        const maxPage = Math.ceil(data.length / pageSize);
        if (page < maxPage) {
            setPage(page + 1);
        }
    };

    const canPaginate = () => {
        if (data.length > 20) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <AdminDashLayout>
            <>
                <div style={{paddingTop: 40, paddingRight: 10}}>
                    <Grid>
                        <Grid.Column floated="left" width={7} mobile={12}>
                            <h3 className={"primary-color"}>PIPEKIT LOG SYSTEM</h3>
                            <h4 style={{color: "rgb(145, 142, 142)", marginTop: 10}}>
                                Showing 50 records per page
                            </h4>
                        </Grid.Column>
                        <Grid.Column floated="right" width={6} mobile={12}>
                            <div style={{paddingTop: 20, float: 'right'}}>
                                {(user.role === 1 || user.role === 1) && (
                                    <>
                                        <Link to={'/dashboard/pipekits/create'}>
                                            <Button
                                                color="primary"
                                                content="Add New Record"
                                                icon="plus"
                                                labelPosition="right"
                                            />
                                        </Link>
                                    </>
                                )}


                            </div>
                        </Grid.Column>
                    </Grid>
                    <br /> <br />
                    <PipekitCards projectsData={data}
                              onPrevPageChange={handlePrevPageChange}
                              onNextPageChange={handleNextPageChange}
                              canPaginate={canPaginate}
                    />
                </div>
            </>
        </AdminDashLayout>
    );
}

export default Pipekits;
