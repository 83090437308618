import React from "react";
import { List, Label } from "semantic-ui-react";
import { FaClock } from "react-icons/fa";
import moment from "moment";
import { FaRegClock } from "react-icons/fa6";

const ProjectNotificationLogs = ({ projectNotifications }) => {
    if (!projectNotifications || projectNotifications.length === 0) {
        return <div>No notifications available</div>;
    }

    const activitiyColor = (type) => {
        switch (type) {
            case "draft":
                return "default"
            break;

            case "on-going":
                return "orange";
                break;

            case "completed":
                return "green";
                break;

            default:
                return "default"
        }
    }

    return (
        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
            <List divided relaxed>
                {projectNotifications.map((activity, index) => (
                    <List.Item key={index}>
                        <List.Content>
                            <List.Header style={{ paddingBottom: 5 }}>
                                <><FaRegClock size={20} color="teal" /> <span style={{ marginLeft: 5 }}>{moment(activity.date).format("Do MMM, YYYY, h:mmA")}</span></>
                            </List.Header>
                            <List.Description>
                                <span className="primary-color" style={{ color: activity.color }}>{activity.content}</span>
                            </List.Description>
                        
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default ProjectNotificationLogs;
