import AuthService from "../auth";
import {Button, Grid} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import GrpCards from "./GrpCards";
import sendApiRequest from "../api";

function NonStaffDashboardComponent() {
    const user = AuthService.getCurrentUser();

    const [data, setData] = useState([])
    const [page, setPage] = useState(1); // Current page number
    const [pageSize, setPageSize] = useState(20);


    useEffect(() => {
        sendApiRequest("get", "/grp").then(r => {
            console.log(r.data)
            setData(r.data)
        })
    }, [page, pageSize])

    const handlePrevPageChange = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleNextPageChange = () => {
        const maxPage = Math.ceil(data.length / pageSize);
        if (page < maxPage) {
            setPage(page + 1);
        }
    };

    const canPaginate = () => {
        if (data.length > 20) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div style={{paddingTop: 40, paddingRight: 10}}>
                <Grid>
                    <Grid.Column floated="left" width={7}>
                        <h3 className={"primary-color"}>GRP HOUSING, DELIVERY & COLLECTION SYSTEM</h3>
                        <h4 style={{color: "rgb(145, 142, 142)", marginTop: 10}}>
                            Showing 50 records per page
                        </h4>
                    </Grid.Column>
                    <Grid.Column floated="right" width={6}>
                        <div style={{paddingTop: 20, float: 'right'}}>
                            {(user.role === 1 || user.role === 2) && (
                                <>
                                    <Button
                                        color="primary"
                                        content="Add New Record"
                                        icon="plus"
                                        labelPosition="right"
                                    />
                                </>
                            )}

                            <Button
                                content="Date"
                                icon="calendar"
                                labelPosition="right"
                            />

                        </div>
                    </Grid.Column>
                </Grid>
                <br /> <br />
                <GrpCards projectsData={data}
                          onPrevPageChange={handlePrevPageChange}
                          onNextPageChange={handleNextPageChange}
                          canPaginate={canPaginate} />
            </div>
        </>
    );
}

export default NonStaffDashboardComponent;
