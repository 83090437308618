import React, { useState } from 'react';
import AdminDashLayout from '../components/AdminDashLayout';
import { Grid } from 'semantic-ui-react';
import CalendarComponent from '../components/Calendar';

function Calendars() {
  // Get the current date
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const currentYear = currentDate.getFullYear();

  // Create state variables to manage the current month and year
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // Function to update the current month and year
  const updateMonthAndYear = (newMonth, newYear) => {
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };


  return (
      <AdminDashLayout>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <Grid>
            <Grid.Column floated="left" width={9}>
              <h1>
                <span style={{ color: '#ddd' }}>Calendar</span> 
              </h1>
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column floated="left" width={7}></Grid.Column>
            <Grid.Column floated="right" width={5}>
              <div style={{ paddingTop: 0 }}></div>
            </Grid.Column>
          </Grid>
          <br />
          {/* Pass current month and year as props to CalendarComponent */}
          <CalendarComponent
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              updateMonthAndYear={updateMonthAndYear}
              
          />
        </div>
      </AdminDashLayout>
  );
}

export default Calendars;
