import React from "react";
import { Card, Grid, Label } from "semantic-ui-react";
import { FaMapMarkerAlt } from "react-icons/fa";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import ProjectAssignedPeople from "./ProjectAssignedPeople";
import {formatToBritishFormat} from "../utils";
import ProjectStatusLabel from "./ProjectStatusLabel";

const WorkerProjectCards = ({ projects }) => {
    if (!projects || projects.length === 0) {
        return <div>No projects available</div>;
    }

    return (
        <Card.Group stackable itemsPerRow={1}>
            {projects.map((project, index) => (
                <Card
                    key={index}
                    style={{backgroundColor: "#fff", padding: 25}}
                    raised
                >
                    <Link
                        to={`/dashboard/projects/view/${project.id}`}
                        style={{textDecoration: "none", textAlign: "justify", color: "inherit"}}
                    >
                        <Card.Content>
                            <Grid>
                                <Grid.Column width={16} mobile={16} tablet={15} computer={10}>
                                    <Card.Header>
                                        <h4>{project.projectName}</h4>
                                    </Card.Header>
                                </Grid.Column>
                                <Grid.Column width={16} mobile={16} tablet={19} computer={10}>
                                    <b>{formatToBritishFormat(project.projectStartDate || project.projectMilestones[0].startDate)}</b>
                                </Grid.Column>
                            </Grid>
                            <Card.Description>
                                <div style={{paddingTop: 20, paddingBottom: 10}}>
                                    <ProjectStatusLabel status={project.projectStatus} />
                                </div>
                                <div style={{paddingTop: 20, paddingBottom: 10}}>
                                    <FaMapMarkerAlt/> {project.clientAddress}
                                </div>
                            </Card.Description>
                        </Card.Content>
                    </Link>
                </Card>
            ))}
        </Card.Group>
    );
};

export default WorkerProjectCards;
