import React from "react";
import { MdHome } from "react-icons/md";
import { Link, matchPath, useLocation } from "react-router-dom";
import pipekitsvg from '../assets/pipe.svg'

const NonStaffSideNav = () => {
    const location = useLocation();
    const isActiveRoute = (pathPattern) => {
        const match = matchPath({ path: pathPattern }, location.pathname);

        return match !== null;
    };

    return (
        <>
            <li
                className={
                    isActiveRoute("/dashboard") ||
                        isActiveRoute("/dashboard/grp") ||
                        isActiveRoute("/dashboard/grp/view/:id") ||
                        isActiveRoute("/dashboard/grp/edit/:id")
                        ? "active"
                        : ""
                }>
                <Link to={"/dashboard"}>
                    <MdHome size={30} style={{ marginBottom: "-10px" }} />
                    <span className="nav-link" style={{ marginBottom: "10px" }}>
                        GRP Housing
                    </span>
                </Link>
            </li>
            <br />
            <li

                className={
                    isActiveRoute("/dashboard/pipekits") ||
                    isActiveRoute("/dashboard/pipekits/view/:id") ||
                        isActiveRoute("/dashboard/pipekits/create") ||
                        isActiveRoute("/dashboard/pipekits/edit/:id") || 
                        isActiveRoute("/dashboard/pipekits/edit/logs/:id")
                        ? "active"
                        : ""
                }
            >
                <Link to={"/dashboard/pipekits"}>
                    <img style={{width: '30px'}} src={pipekitsvg} />
                    <span className="nav-link" style={{marginBottom: "10px"}}>
                    Pipe Kits
                  </span>
                </Link>
            </li>
        </>
    );
};

export default NonStaffSideNav;
