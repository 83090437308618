const convertTime = (isoTime) => {
    const dt = new Date(isoTime);

    const year = dt.getFullYear();
    const month = dt.toLocaleString('default', { month: 'long' });
    const day = dt.getDate();
    const hour = dt.getHours() % 12 || 12;
    const minute = dt.getMinutes();
    const period = dt.getHours() >= 12 ? 'PM' : 'AM';

    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';
    } else if (day === 2 || day === 22) {
        suffix = 'nd';
    } else if (day === 3 || day === 23) {
        suffix = 'rd';
    }

    const formattedTime = `${day}${suffix} ${month} ${year} ${hour}:${minute.toString().padStart(2, '0')} ${period}`;
    return formattedTime;
};

export { convertTime }