import { Form, Grid, Checkbox, Button, TextArea } from "semantic-ui-react";
import React, { useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import sendApiRequest from "../api";
import { toast } from "react-toastify";
import { formatToYYYYMMDD } from "../utils"
import SignatureCanvas from 'react-signature-canvas';
import { FaPlay } from "react-icons/fa6";

const ViewSignedDisclaimerComponent = ({ defaultData, onSubmitFromParent }) => {
    // Remove unused state
    // const [formData, setFormData] = useState({
    //     // Add more form fields as needed
    //     description: '',
    //     signedEngineer: "",
    //     signedClient: "",
    // });

    const id = defaultData.id;

    // Remove unused handleChange function
    // const handleChange = (e) => {
    //     const { name, value, type, checked } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: type === "checkbox" ? checked : value,
    //     }));
    // };

    const sigPadClient = useRef({});
    const sigPadEngineer = useRef({});

    // Remove unused handleDateChange function
    // const handleDateChange = (date, section) => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [section]: date.toISOString(),
    //     }));
    // };

    const handleSubmitFromParent = async () => {
        let signedClient, signedEngineer, description;

        if (sigPadClient.current.isEmpty()) {
            toast.error("Client signature is required.");
            return;
        } else {
            signedClient = sigPadClient.current.getTrimmedCanvas().toDataURL('image/png');
        }

        if (sigPadEngineer.current.isEmpty()) {
            toast.error("Engineer signature is required.");
            return;
        } else {
            signedEngineer = sigPadEngineer.current.getTrimmedCanvas().toDataURL('image/png');
        }

        // Remove unused description variable
        // if (!formData.description.trim()) {
        //     toast.error("Description is required.");
        //     return;
        // } else {
        //     description = formData.description;
        // }


        const data = {
            // "description": description,
            "employeeSignature": signedEngineer,
            "clientSignature": signedClient,
        }

        console.log(data)

        const r = await sendApiRequest('post', '/projects/disclaimer/sign/' + id, data);
        console.log(r)

        toast("Project Disclaimer Signed")

        onSubmitFromParent()
    };

    return (
        <div style={{ overflow: "scroll", height: "550px" }}>
            <div
                className={"grp-view-page-further-checks-card"}
                style={{
                    borderRadius: "10px",
                    padding: "20px",
                    background: "white",

                }}
            >
                <center><img src="https://gdheatingandgas.b-cdn.net/gdheatingpdflogo.png" style={{ width: "350px" }} /></center>

          
                <center><b><small style={{color: "black"}}>{defaultData.projectDisclaimerMeta.date}</small></b></center>
                <br />
                <center><b><h3 style={{color: "red"}}>DISCLAIMER SIGNED: {defaultData.projectDisclaimerMeta.title}</h3></b></center>
                <br />
                <p color="black" style={{ color: "black", textAlign: 'center' }}><span className={"primary-color"} style={{ fontSize: 18, fontWeight: "bold" }}>
                    Brief Description Of Out Of Scope Work:
                </span>
                    <p style={{ fontSize: "17px" }}>
                        {defaultData.projectDisclaimerMeta.description}
                    </p>
                    <hr />
                    <br />
                    <p style={{ fontSize: "18px" }}>   I, {defaultData.clientName}, acknowledge and agree that any tasks or services
                        beyond the agreed scope are not covered under GD Heating & Gas's
                        standard liability provisions. Additional work may carry risks and
                        unforeseen challenges, and GD Heating & Gas cannot guarantee
                        outcomes or be held liable for any resulting damages or disruptions.
                        Communication regarding project changes is encouraged.
                        Any work beyond the scope is at my own risk.
                        I certify that I have read and fully understand these terms.</p>


                </p>

                <Grid>
                    <Grid.Column width={8}>
                        <label className="primary" color="primary" style={{ color: "#044f66", fontSize: "18px" }}>Signed:</label>
                        <br /><br />
                        <img src={defaultData.projectDisclaimerMeta.clientSignature} style={{width: "100px"}}/>
                        <br /><br />
                        <b style={{ color: "black" }}>{defaultData.clientName}</b>
                        <br />
                        <b style={{ color: "black" }}>{defaultData.projectDisclaimerMeta?.signDate}</b>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <label style={{ color: "#044f66", fontSize: "20px", float: "left" }}>In the presence of:</label>
                        <br /><br />
                        <span><img src={defaultData.projectDisclaimerMeta.employeeSignature} style={{ width: "100px" }}/>
                            <br /><br />
                            <b style={{ color: "black" }}>{defaultData.projectDisclaimerMeta?.employeeName}</b>
                            <br />
                            <b style={{ color: "black" }}>{defaultData.projectDisclaimerMeta?.signDate}</b></span>


                    </Grid.Column>

                </Grid>

            </div>



        </div>
    );
};

export default ViewSignedDisclaimerComponent;
